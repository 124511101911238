import {
  GET_LEADCOMMENTS_STATED,
  GET_LEADCOMMENTS,
  GET_LEADCOMMENTS_ENDED,
  ADD_LEADCOMMENT_STATED,
  ADD_LEADCOMMENT,
  ADD_LEADCOMMENT_ENDED,
  EDIT_LEADCOMMENT_STATED,
  EDIT_LEADCOMMENT,
  EDIT_LEADCOMMENT_ENDED,
  GET_LEADCOMMENT_STATED,
  GET_LEADCOMMENT,
  GET_LEADCOMMENT_ENDED,
  GET_ALL_LEADCOMMENTS_STATED,
  GET_ALL_LEADCOMMENTS,
  GET_ALL_LEADCOMMENTS_ENDED
} from "../types/leadcomment_type";

const initialState = {
  leadcomments_loading: true,
  leadcomments: null,
  page: null,
  pages: null,
  total_leadcomments: 0,

  leadcomment: null,
  leadcomment_loading: null,

  loading: true,

  leadcomment_message: null,
  all_leadcomments: null,
  all_leadcomments_loading: null,
  add_leadcomment_loading: true,
  edit_leadcomment_loading: true
};

export const leadcomment_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADCOMMENTS_STATED:
      return {
        ...state,
        leadcomments: null,
        pages: null,
        page: null,
        total_leadcomments: 0,
        leadcomments_loading: true
      };
    case GET_LEADCOMMENTS:
      return {
        ...state,
        leadcomments: payload.leadcomments,
        pages: payload.pages,
        page: payload.page,
        total_leadcomments: payload.count
      };
    case GET_LEADCOMMENTS_ENDED:
      return {
        ...state,
        leadcomments_loading: false
      };
    case GET_ALL_LEADCOMMENTS_STATED:
      return {
        ...state,
        all_leadcomments_loading: true,
        all_leadcomments: null
      };
    case GET_ALL_LEADCOMMENTS:
      return {
        ...state,
        all_leadcomments: payload
      };
    case GET_ALL_LEADCOMMENTS_ENDED:
      return {
        ...state,
        all_leadcomments_loading: false
      };

    case ADD_LEADCOMMENT_STATED:
      return {
        ...state,
        leadcomment_message: null,
        add_leadcomment_loading: true
      };
    case ADD_LEADCOMMENT:
      return {
        ...state,
        leadcomment_message: payload
      };
    case ADD_LEADCOMMENT_ENDED:
      return {
        ...state,
        add_leadcomment_loading: false
      };
    case GET_LEADCOMMENT_STATED:
      return {
        ...state,
        leadcomment: null,
        leadcomment_loading: true
      };
    case GET_LEADCOMMENT:
      return {
        ...state,
        leadcomment: payload
      };
    case GET_LEADCOMMENT_ENDED:
      return {
        ...state,
        leadcomment_loading: false
      };
    case EDIT_LEADCOMMENT_STATED:
      return {
        ...state,
        leadcomment_message: null,
        edit_leadcomment_loading: true
      };
    case EDIT_LEADCOMMENT:
      return {
        ...state,
        leadcomment_message: payload
      };
    case EDIT_LEADCOMMENT_ENDED:
      return {
        ...state,
        edit_leadcomment_loading: false
      };

    default:
      return state;
  }
};
