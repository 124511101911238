import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/clients_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleClient, useSingleClientDashboard } from "../../shared/hooks/UseClient";
import { Link } from "react-router-dom/cjs/react-router-dom";


const ViewClient = ({ match }) => {
  const [data] = useSingleClient(match.params.id);
  const [client_data_dashboard] = useSingleClientDashboard(match.params.id);
  const { client_loading, client, client_dashboard } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {
          <section className="client-view-dashboard">
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <div className="client-view-dashboard-heading">
                    <h4 className="card-title">Dashboard: {client && client.name}</h4>
                  </div>
                </div>
                {client_dashboard && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="dashboard-stat closed text-center">
                          <div className="report-title">
                            <Link
                              to={`/`}
                            >
                              Total Activities
                            </Link>
                          </div>
                          <div className="report-stat">
                            <Link
                              to={`/`}
                            >
                              {client_dashboard.total_activities}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dashboard-stat closed text-center">
                          <div className="report-title">
                            <Link
                              to={`/`}
                            >
                              Completed Activities
                            </Link>
                          </div>
                          <div className="report-stat">
                            <Link
                              to={`/`}
                            >
                              {client_dashboard.completed_activities}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dashboard-stat closed text-center">
                          <div className="report-title">
                            <Link
                              to={`/`}
                            >
                              Pending Activities
                            </Link>
                          </div>
                          <div className="report-stat">
                            <Link
                              to={`/`}
                            >
                              {client_dashboard.pending_activities}
                            </Link>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                )}

              </div>
            </div>
          </section>
        }
        {!client_loading ? (
          client && (
            <SingleView
              data={client}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={client._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}


      </div>
    </div>
  );
};

export default ViewClient;
