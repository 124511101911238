export const PAGE_TITLE = "Activities";
export const PAGE_SINGLE_TITLE = "Activity";
export const LINK_URL = "activitys";
export const inputFields = {
  task_name: {
    type: "string",
    required: true,
    title: "Task name",
    inputType: "text",
    field: "name",
  },

  assigned_to: {
    type: "related",
    required: true,
    title: "Assigned To",
    inputType: "text",
    field: "name",
  },
  activity_cycle: {
    type: "related",
    required: false,
    title: "Activity Cycle",
    inputType: "text",
    field: "name",
    // hideOnEntry: false,
  },
  due_time: {
    type: "string",
    required: true,
    title: "Due Time",
    inputType: "datetime-local",
  },
  client: {
    type: "related",
    required: true,
    title: "Client",
    inputType: "text",
    field: "name",
  },
  process: {
    type: "related",
    required: true,
    title: "Process",
    inputType: "text",
    field: "name",
  },
  // sop: {
  //   type: "related",
  //   required: false,
  //   title: "SOP Checklist",
  //   inputType: "text",
  //   field: "name",
  // },
  estimated_time: {
    type: "string",
    required: false,
    title: "Estimated Time(in mins)",
    inputType: "number",
    // placeholder:"in mins",
  },
  actual_time: {
    type: "string",
    required: false,
    title: "Actual Time(in mins)",
    inputType: "number",
    // placeholder:"in mins",
  },
  task_description: {
    type: "html",
    required: false,
    title: "Task Description",
    // inputType: "text",
    // field: "name"
  },
  attachments: {
    type: "gallery",
    required: false,
    title: "Attachments",
    inputType: "text",
    multiple: true,
  },
  review_status: {
    type: "related",
    required: true,
    title: "Review Status",
    inputType: "text",
    field: "name",
  },
  activity_status: {
    type: "related",
    required: true,
    title: "Activity Status",
    inputType: "text",
    field: "name",
  },


  comments: {
    type: "text",
    required: false,
    title: "Comment",
    // inputType: "text",
  },
};
export const initialValues = {
  task_name: "",
  assigned_to: "",
  activity_cycle: "",
  due_time: "",
  client: "",
  process: "",
  task_description: "",
  attachments: "",
  activity_status: "",
  comments: "",
};

export const view_all_table = [
  {
    name: "Task name",
    value: "task_name",
  },
  {
    name: "Assigned To",
    value: "assigned_to",
    related: true,
    field: "name",
  },
  {
    name: "Activity Cycle",
    value: "activity_cycle",
    related: true,
    field: "name",
  },
  {
    name: "Due Time",
    value: "due_time",
    date: true,
  },
  {
    name: "Estimate Time",
    value: "estimated_time",
  },
  {
    name: "Client",
    value: "client",
    related: true,
    field: "name",
  },
  {
    name: "Process",
    value: "process",
    related: true,
    field: "name",
  },
  // {
  //   name: "Task Description",
  //   value: "task_description",
  // },
  {
    name: "Review Status",
    value: "review_status",
    related: true,
    field: "name",
  },
  {
    name: "Activity Status",
    value: "activity_status",
    related: true,
    field: "name",
  },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "task_name",
    field: "task_name",
    label: "Task Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "assigned_to",
    field: "assigned_to",
    label: "Assigned To",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "due_time",
    field: "due_time",
    label: "Due Time",
    type: "string",
    search_type: "conditional",
    inputType: "datetime-local",
    condition: "$gte",
  },
  {
    id: "client",
    field: "client",
    label: "Client",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "process",
    field: "process",
    label: "Process",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "activity_status",
    field: "activity_status",
    label: "Activity Status",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "review_status",
    field: "review_status",
    label: "Review Status",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "date_from",
    field: "createdAt",
    label: "Date From",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "date_to",
    field: "createdAt",
    label: "Date To",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
];

export const inputFieldsBulk = {
  task_name: {
    type: "string",
    required: true,
    title: "Task name",
    inputType: "text",
    field: "name",
  },

  assigned_to: {
    type: "related",
    required: true,
    title: "Assigned To",
    inputType: "text",
    field: "name",
  },
  due_time: {
    type: "string",
    required: true,
    title: "Due Time",
    inputType: "datetime-local",
  },
  clients: {
    type: "related",
    required: false,
    title: "Clients",
    inputType: "text",
    field: "name",
    multiple: true,
  },

  process: {
    type: "related",
    required: true,
    title: "Process",
    inputType: "text",
    field: "name",
  },
  estimated_time: {
    type: "string",
    required: false,
    title: "Estimated Time",
    inputType: "text",
  },
  task_description: {
    type: "html",
    required: false,
    title: "Task Description",
    // inputType: "text",
    // field: "name"
  },
  attachments: {
    type: "gallery",
    required: false,
    title: "Attachments",
    inputType: "text",
    multiple: true,
  },
  review_status: {
    type: "related",
    required: true,
    title: "Review Status",
    inputType: "text",
    field: "name",
  },
  activity_status: {
    type: "related",
    required: true,
    title: "Activity Status",
    inputType: "text",
    field: "name",
  },
  comments: {
    type: "text",
    required: false,
    title: "Comment",
    // inputType: "text",
  },
};

export const initialValuesBulk = {
  task_name: "",
  assigned_to: "",
  due_time: "",
  clients: "",
  process: "",
  task_description: "",
  attachments: "",
  activity_status: "",
  comments: "",
};
