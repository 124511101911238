export const GET_COMMENTS_STATED = "GET_COMMENTS_STATED";
export const GET_COMMENTS = "GET_COMMENTS";
export const GET_COMMENTS_ENDED = "GET_COMMENTS_ENDED";
export const ADD_COMMENT_STATED = "ADD_COMMENT_STARTED";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_ENDED = "ADD_COMMENT_ENDED";
export const EDIT_COMMENT_STATED = "EDIT_COMMENT_STATED";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const EDIT_COMMENT_ENDED = "EDIT_COMMENT_ENDED";
export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_STATED = "GET_COMMENT_STATED";
export const GET_COMMENT_ENDED = "GET_COMMENT_ENDED";
export const RESET_COMMENT = "RESET_COMMENT";
export const ERROR_COMMENT = "ERROR_COMMENT";
export const GET_ALL_COMMENTS_STATED = "GET_ALL_COMMENTS_STATED";
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_ALL_COMMENTS_ENDED = "GET_ALL_COMMENTS_ENDED";
