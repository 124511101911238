export const PAGE_TITLE = "Processs";
export const PAGE_SINGLE_TITLE = "Process";
export const LINK_URL = "processs";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  description: {
    type: "html",
    required: false,
    title: "Description",
    inputType: "text",
  },
  checklist: {
    type: "array",
    required: false,
    title: "Checklist",
    inputType: "text",
    fields: {
      name: {
        type: "string",
        required: true,
        title: "",
        inputType: "text",
      },
    }
  },
  sop: {
    type: "array",
    required: false,
    title: "SOP's",
    inputType: "text",
    fields: {
      name: {
        type: "html",
        required: true,
        title: "",
        inputType: "text",
      },
    }
  }
};
export const initialValues = {
  name: "",
  description: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
