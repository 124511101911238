export const PAGE_TITLE = "Clients";
export const PAGE_SINGLE_TITLE = "Client";
export const LINK_URL = "clients";
export const inputFields = {
  name: {
    type: "string",
    required: false,
    title: "Name",
    inputType: "",
    options: "",
    field: "",
  },
  industry: {
    type: "string",
    required: false,
    title: "Industry",
    inputType: "",
    options: "",
    field: "",
  },
  location: {
    type: "string",
    required: false,
    title: "Location",
    inputType: "",
    options: "",
    field: "",
  },
  source: {
    type: "select",
    required: false,
    title: "Source",
    inputType: "",
    options: ["Fb", "Google", "Reference", "Personal"],
    field: "",
  },
  owner_person_details_divider: {
    type: "divider",
    title: "Owner Person Details",
  },
  owner_person_name: {
    type: "string",
    required: false,
    title: "Owner  Name",
    inputType: "",
    options: "",
    field: "",
  },
  owner_person_phone: {
    type: "string",
    required: false,
    title: "Owner  Phone",
    inputType: "",
    options: "",
    field: "",
  },
  owner_person_email: {
    type: "string",
    required: false,
    title: "Owner  Email",
    inputType: "",
    options: "",
    field: "",
  },
  poc_details_divider: {
    type: "divider",
    title: "Point Of Contact Details",
  },
  poc_designation: {
    type: "string",
    required: false,
    title: " Designaiton",
    inputType: "",
    options: "",
    field: "",
  },
  poc_name: {
    type: "string",
    required: false,
    title: " Name",
    inputType: "",
    options: "",
    field: "",
  },
  poc_phone: {
    type: "string",
    required: false,
    title: " Phone",
    inputType: "",
    options: "",
    field: "",
  },
  poc_email: {
    type: "string",
    required: false,
    title: " Email",
    inputType: "",
    options: "",
    field: "",
  },

  // data_checklist_divider: {
  //   type: "divider",
  //   // required: true,
  //   title: "Data CheckList",
  // },
  // contract_available: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Contract",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // fb_page_access: {
  //   type: "checkbox",
  //   required: false,
  //   title: "FB Page Access",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // gmb_access: {
  //   type: "checkbox",
  //   required: false,
  //   title: "GMB Access",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // link_own_card_for_marketing_budget: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Link own card for marketing budget?",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // fb_ad_account_access: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Self Spend]FB Ad Account Access",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // google_ad_account_access: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Self Spend]Google Ad Account Access",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // developer_access: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Domain] Developer Access",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // domain_credentials: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Domain] Domain Credentials",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // luhaif_to_decide: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Domain] Luhaif To Decide",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // clininc_template_offer: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Information] Clinic Template/Offer (Salons)",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // rate_card: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Rate card (Salons)",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // will_use_luhaif_billing: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Attribution] Will Use Luhaif Billing",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // billing_integration: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Attribution] Billing Integration",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // manual_visits: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[Attribution] Manual Visits ",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // logo_from_website: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[LOGO] From Website",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // logo_file_shared: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[LOGO] File Shared",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // no_logo: {
  //   type: "checkbox",
  //   required: false,
  //   title: "[LOGO] No Logo",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },

  other_details_divider: {
    type: "divider",
    title: "Other Details",
  },

  contract: {
    type: "file",
    required: false,
    title: "Upload Contract",
    inputType: "",
    options: "",
    field: "",
  },
  media: {
    type: "gallery",
    required: false,
    title: "Media",
    inputType: "text",
    multiple: true,
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
    inputType: "",
    options: "",
    field: "",
  },
  // poc: {
  //   type: "related",
  //   required: false,
  //   title: "POC",
  //   inputType: "",
  //   options: "",
  //   field: "name",
  // },
  work_starting_date: {
    type: "string",
    required: false,
    title: "Work Starting Date",
    inputType: "date",
    options: "",
    field: "",
  },
  package_details: {
    type: "text",
    required: false,
    title: "Package Details",
    inputType: "",
    options: "",
    field: "",
  },
  goals: {
    type: "text",
    required: false,
    title: "Goals",
    inputType: "",
    options: "",
    field: "",
  },
  ads_budget_details: {
    type: "text",
    required: false,
    title: "Ads Budget Details",
    inputType: "",
    options: "",
    field: "",
  },
  tracker_link: {
    type: "string",
    required: false,
    title: "Tracker Link",
    inputType: "",
    options: "",
    field: "",
  },
  facebook_link: {
    type: "string",
    required: false,
    title: "Facebook Link",
    inputType: "",
    options: "",
    field: "",
  },
  instagram_link: {
    type: "string",
    required: false,
    title: "Instagram Link",
    inputType: "",
    options: "",
    field: "",
  },
  other_social_media_link: {
    type: "text",
    required: false,
    title: "Other Social Media Link",
    inputType: "",
    options: "",
    field: "",
  },
  account_manager: {
    type: "related",
    required: false,
    title: "Account Manager",
    inputType: "",
    options: "",
    field: "name",
  },
  ads_manager: {
    type: "related",
    required: false,
    title: "Ads Manager",
    inputType: "",
    options: "",
    field: "name",
  },

  billing_date: {
    type: "string",
    required: false,
    title: "Billing Date",
    inputType: "date",
    options: "",
    field: "",
  },
  billing_type: {
    type: "select",
    required: true,
    title: "Billing Type",
    inputType: "",
    options: ["Annual", "Monthly", "Quarterly", "One Time"],
    field: "",
  },
  status: {
    type: "related",
    required: false,
    title: "Status",
    inputType: "",
    options: "",
    field: "name",
  },
  review_status: {
    type: "select",
    required: true,
    title: "Review Status",
    options: ["Live", "Lost", "In-House", "Delivered"],
  },
  address: {
    type: "string",
    required: false,
    title: "Address",
    inputType: "",
    options: "",
    field: "name",
  },
  city: {
    type: "string",
    required: false,
    title: "City",
    inputType: "",
    options: "",
    field: "name",
  },
  state: {
    type: "string",
    required: false,
    title: "State",
    inputType: "",
    options: "",
    field: "name",
  },
  pincode: {
    type: "string",
    required: false,
    title: "Pin Code",
    inputType: "",
    options: "",
    field: "name",
  },
  country: {
    type: "string",
    required: false,
    title: "Country",
    inputType: "",
    options: "",
    field: "name",
  },
  gst_no: {
    type: "string",
    required: false,
    title: "GST No.",
    inputType: "",
    options: "",
    field: "name",
  },

  total_package_cost: {
    type: "string",
    required: false,
    title: "Total Package Cost",
    inputType: "number",
    options: "",
  },
  package: {
    type: "related",
    required: false,
    title: "Package",
    inputType: "",
    options: "",
    field: "name",
  },
  // paid_amount: {
  //   type: "string",
  //   required: false,
  //   title: "Paid Amount",
  //   inputType: "",
  //   options: "",
  //   field: "name",
  // },
  // due_amount: {
  //   type: "string",
  //   required: false,
  //   title: "Due Amount",
  //   inputType: "",
  //   options: "",
  //   field: "name",
  // },
  // package_checklist_divider: {
  //   type: "divider",
  //   // required: true,
  //   title: "Package CheckList -",
  // },
  // website_checklist_divider: {
  //   type: "divider",
  //   // required: true,
  //   title: "Website CheckList",
  // },
  // website: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Website",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // android_app: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Android App",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // ios_app: {
  //   type: "checkbox",
  //   required: false,
  //   title: "IOS App",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // landing_pages: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Landing Pages",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // software: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Software",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // crm: {
  //   type: "checkbox",
  //   required: false,
  //   title: "CRM",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // logo: {
  //   type: "checkbox",
  //   required: false,
  //   title: "LOGO",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // website_content: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Website Content",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // website_more_details: {
  //   type: "text",
  //   required: false,
  //   title: "Add More Details",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // ads_checklist_divider: {
  //   type: "divider",
  //   title: "Ads CheckList",
  // },
  // fb_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Facebook Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // google_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Google Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // instagram_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Instagram Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // youtube_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Youtube Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // linkedin_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "LinkedIn Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // whatsapp_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Whatsapp Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // email_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Email Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // native_ads: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Native Ads",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // ads_more_details: {
  //   type: "text",
  //   required: false,
  //   title: "Add More Details",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },

  // social_media_postind_divider: {
  //   type: "divider",
  //   title: "Social Media Posting",
  // },
  // fb_posting: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Facebook Posting",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // insta_posting: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Instagram Posting",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // linkedin_posting: {
  //   type: "checkbox",
  //   required: false,
  //   title: "LinkedIn Posting",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // youtube_posting: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Youtube Posting",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // twitter_posting: {
  //   type: "checkbox",
  //   required: false,
  //   title: "twitter Posting",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // quora_posting: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Quora Posting",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // social_media_more_details: {
  //   type: "text",
  //   required: false,
  //   title: "Add More Details",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },

  // video_production: {
  //   type: "divider",
  //   title: "Video Production",
  // },
  // photoshoot: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Photoshoot",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // photo_edit: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Photo Edit",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // video_shoot: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Videoshoot",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // video_edit: {
  //   type: "checkbox",
  //   required: false,
  //   title: "Video Edit",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  // video_more_details: {
  //   type: "text",
  //   required: false,
  //   title: "Add More Details",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  accounts_details: {
    type: "divider",
    title: "Accounts Details",
  },

  fb_account_id: {
    type: "string",
    required: false,
    title: "FB Account ID",
    inputType: "text",
    options: "",
    field: "",
  },
  fb_benchmark: {
    type: "string",
    required: false,
    title: "FB Benchmark",
    inputType: "text",
    options: "",
    field: "",
  },
  google_account_id: {
    type: "string",
    required: false,
    title: "Google Account ID",
    inputType: "text",
    options: "",
    field: "",
  },
  google_benchmark: {
    type: "string",
    required: false,
    title: "Google Benchmark",
    inputType: "text",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
  industry: "",
  location: "",
  source: "",
  owner_person_name: "",
  contact_person_phone: "",
  email: "",
  poc: "",
  work_starting_date: "",
  owner_person_phone: "",
  owner_person_email: "",
  poc_designation: "",
  poc_name: "",
  poc_phone: "",
  poc_email: "",
  contract_available: "",
  fb_page_access: "",
  gmb_access: "",
  link_own_card_for_marketing_budget: "",
  fb_ad_account_access: "",
  google_ad_account_access: "",
  developer_access: "",
  domain_credentials: "",
  luhaif_to_decide: "",
  clininc_template_offer: "",
  rate_card: "",
  will_use_luhaif_billing: "",
  billing_integration: "",
  manual_visits: "",
  logo_from_website: "",
  logo_file_shared: "",
  no_logo: "",
  contract: "",
  media: "",
  email: "",
  work_starting_date: "",
  package_details: "",
  goal: "",
  ads_budget_details: "",
  tracker_link: "",
  facebook_link: "",
  instagram_link: "",
  other_social_media_link: "",
  account_manager: "",
  ads_manager: "",
  billing_date: "",
  billing_type: "",
  status: "",
  package: "",
  address: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
  gst_no: "",
  total_package_cost: "",
  website: "",
  android_app: "",
  ios_app: "",
  landing_pages: "",
  software: "",
  crm: "",
  logo: "",
  website_content: "",
  website_more_details: "",
  fb_ads: "",
  google_ads: "",
  instagram_ads: "",
  youtube_ads: "",
  linkedin_ads: "",
  whatsapp_ads: "",
  email_ads: "",
  native_ads: "",
  ads_more_details: "",
  fb_posting: "",
  insta_posting: "",
  linkedin_posting: "",
  youtube_posting: "",
  twitter_posting: "",
  quora_posting: "",
  social_media_more_details: "",
  photoshoot: "",
  photo_edit: "",
  video_shoot: "",
  video_edit: "",
  video_more_details: "",
  goals: "",
  ads_budget: "",
  tracker_link: "",
  facebook_link: "",
  instagram_link: "",
  account_manager: "",
  ads_manager: "",
  billing_date: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Billing Date", value: "billing_date", date: true },
  {
    name: "Account Manager",
    value: "account_manager",
    related: true,
    field: "name",
  },
  { name: "Work Starting Date", value: "work_starting_date", date: true },
  { name: "Status", value: "status", related: true, field: "name" },
  { name: "Package", value: "package", related: true, field: "name" },
  { name: "Review Status", value: "review_status" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "location",
    field: "location",
    label: "Location",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "review_status",
    field: "review_status",
    label: "Review Status",
    type: "select",
    search_type: "exact",
    inputType: "text",
    condition: "",
    options: ["Live", "Lost", "In-House", "Delivered"],
  },
  {
    id: "account_manager",
    field: "account_manager",
    label: "Account Manager",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "ads_manager",
    field: "ads_manager",
    label: "Ads Manager",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "status",
    field: "status",
    label: "Status",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "billing_type",
    field: "billing_type",
    label: "Billing Type",
    type: "select",
    search_type: "exact",
    inputType: "text",
    options: ["Annual", "Monthly", "Quarterly", "One Time"],
  },
];
