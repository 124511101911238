import {
  GET_ACTIVITYSTATUSS_STATED,
  GET_ACTIVITYSTATUSS,
  GET_ACTIVITYSTATUSS_ENDED,
  ADD_ACTIVITYSTATUS_STATED,
  ADD_ACTIVITYSTATUS,
  ADD_ACTIVITYSTATUS_ENDED,
  EDIT_ACTIVITYSTATUS_STATED,
  EDIT_ACTIVITYSTATUS,
  EDIT_ACTIVITYSTATUS_ENDED,
  GET_ACTIVITYSTATUS_STATED,
  GET_ACTIVITYSTATUS,
  GET_ACTIVITYSTATUS_ENDED,
  GET_ALL_ACTIVITYSTATUSS_STATED,
  GET_ALL_ACTIVITYSTATUSS,
  GET_ALL_ACTIVITYSTATUSS_ENDED
} from "../types/activitystatus_type";

const initialState = {
  activitystatuss_loading: true,
  activitystatuss: null,
  page: null,
  pages: null,
  total_activitystatuss: 0,

  activitystatus: null,
  activitystatus_loading: null,

  loading: true,

  activitystatus_message: null,
  all_activitystatuss: null,
  all_activitystatuss_loading: null,
  add_activitystatus_loading: true,
  edit_activitystatus_loading: true
};

export const activitystatus_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ACTIVITYSTATUSS_STATED:
      return {
        ...state,
        activitystatuss: null,
        pages: null,
        page: null,
        total_activitystatuss: 0,
        activitystatuss_loading: true
      };
    case GET_ACTIVITYSTATUSS:
      return {
        ...state,
        activitystatuss: payload.activitystatuss,
        pages: payload.pages,
        page: payload.page,
        total_activitystatuss: payload.count
      };
    case GET_ACTIVITYSTATUSS_ENDED:
      return {
        ...state,
        activitystatuss_loading: false
      };
    case GET_ALL_ACTIVITYSTATUSS_STATED:
      return {
        ...state,
        all_activitystatuss_loading: true,
        all_activitystatuss: null
      };
    case GET_ALL_ACTIVITYSTATUSS:
      return {
        ...state,
        all_activitystatuss: payload
      };
    case GET_ALL_ACTIVITYSTATUSS_ENDED:
      return {
        ...state,
        all_activitystatuss_loading: false
      };

    case ADD_ACTIVITYSTATUS_STATED:
      return {
        ...state,
        activitystatus_message: null,
        add_activitystatus_loading: true
      };
    case ADD_ACTIVITYSTATUS:
      return {
        ...state,
        activitystatus_message: payload
      };
    case ADD_ACTIVITYSTATUS_ENDED:
      return {
        ...state,
        add_activitystatus_loading: false
      };
    case GET_ACTIVITYSTATUS_STATED:
      return {
        ...state,
        activitystatus: null,
        activitystatus_loading: true
      };
    case GET_ACTIVITYSTATUS:
      return {
        ...state,
        activitystatus: payload
      };
    case GET_ACTIVITYSTATUS_ENDED:
      return {
        ...state,
        activitystatus_loading: false
      };
    case EDIT_ACTIVITYSTATUS_STATED:
      return {
        ...state,
        activitystatus_message: null,
        edit_activitystatus_loading: true
      };
    case EDIT_ACTIVITYSTATUS:
      return {
        ...state,
        activitystatus_message: payload
      };
    case EDIT_ACTIVITYSTATUS_ENDED:
      return {
        ...state,
        edit_activitystatus_loading: false
      };

    default:
      return state;
  }
};
