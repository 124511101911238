import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { URI } from '../../domain/constant';
import Pagination from "../../components/common/Pagination";
import AddBtn from "../../components/common/AddBtn";
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/employeereports_enum";
import DataTable from "../../components/common/DataTable";
// import {
//   useAllEmployeereports,
//   useGetDropdownOptions,
// } from "../../shared/hooks/UseEmployeereport";
import {
  useAllEmployees,
  useGetDropdownOptions,
} from "../../shared/hooks/UseEmployee";
import SidebarFilter from "../../components/common/SidebarFilter";
import { Link } from "react-router-dom";
import Snow from "../../components/snow/Snow";

const AllEmployeereports = ({ }) => {

  const [data, setPageNumber] = useAllEmployees();
  const { employees, employees_loading } = data;
  const [main_user, setMain_user] = useState(null);
  const [workingEmployees, setWorkingEmployees] = useState(null)
  useEffect(() => {
    if (employees) {
      const main_managerFilter = employees.filter((item) => !item.reporting_manager && item.employee_status === "Working")
      setMain_user(main_managerFilter)
    }
  }, [employees])
  useEffect(() => {
    if (employees) {
      const main_managerFilter = employees.filter((item) => item.employee_status === "Working")
      setWorkingEmployees(main_managerFilter)
    }
  }, [employees])


  return (
    <div className="pace-done">
      <div>
        <Header />
        <div className="header">
          {/*Content before waves*/}
          <div className="inner-header flex">
            {/*Just the logo.. Don't mind this*/}
            <svg
              version="1.1"
              baseProfile="tiny"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 500 500"
              xmlSpace="preserve"
            >
              <path
                fill="#FFFFFF"
                stroke="#000000"
                strokeWidth={10}
                strokeMiterlimit={10}
                d="M57,283"
              />
              <g></g>
            </svg>
          </div>

        </div>
        <article className="flow">
          <div className="employee-report-text ptb-20 ml-16px">
            <h1>
              Meet Our <span>Team</span>
            </h1>
            <p>
              Meet Our diverse team of world class creators, designers, developers and problem Solvers.
            </p>
          </div>
          <div className="team">
            <div className="container-fluid">
              {main_user && main_user.map((employee) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-md-4 mx-auto">
                        <div className="team-member-card">
                          <Link to={`/employees/${employee._id}/view`}>
                            <a
                              href="#"
                              target='_blank"'
                              className="profile employee-profile"
                            >
                              <h2 className="profile__name">{employee.name}</h2>
                              <p>{employee.designation}</p>
                              <img
                                alt="Profile shot for Celina Harris"
                                src={`${URI}${employee.image}`}
                              />
                            </a>
                          </Link>
                          <div className="team-member-designation-ceo">
                            <p>Our Honorable Founder & CEO</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mx-auto">
                        <div className="team-member-card-content">
                          <div className="team-member-card-heading">
                            <h4>
                              Meet Our CEO <span>Mr. Saif Ahmad Khan</span>
                            </h4>
                          </div>
                          <div className="team-member-card-para">
                            <p>
                              <strong>Saif Ahmad Khan,</strong> a Alumnus of{" "}
                              <strong>IIM Nagpur</strong>, is an entrepreneur,
                              philanthropist, and the{" "}
                              <strong>Founder of Luhaif Digitech</strong>. Best
                              described by his friends as “secure in his uniqueness”.
                            </p>
                            <p>
                              He has never been one to turn down the more challenging
                              and risky options in life. He has received numerous
                              awards for his philanthropic work in providing quality
                              out-of-school time programs for underprovided youth.
                              Through dynamic marketing of the health and wellness,
                              non-profit organization industry and distribution of
                              uplifting content, he now continues his quest with
                              several brands.
                            </p>
                            <p>
                              He brings in more than eight years of industry
                              leadership, comprehensive synergistic blend of expertise
                              in business process management, and understanding of
                              sophisticated international markets.
                            </p>
                            <p>
                              During his occupation at different marketing agencies
                              and non-profit organizations for 8 years he has managed
                              numerous strategic and organizational projects and
                              participated as project manager, business development
                              manager in the development of the organizations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ptb-20">
                      {
                        workingEmployees && workingEmployees.map((manager) => {
                          if (manager.reporting_manager && manager.reporting_manager._id === employee._id) {
                            return (
                              <div className="col-md-3 mx-auto">
                                <div className="team-member-card">
                                  <Link to={`/employees/${manager._id}/view`}>
                                    <a href="#" target='_blank"' className="profile">
                                      <h2 className="profile__name">{manager.name}</h2>
                                      <p>{manager.designation}</p>
                                      <img
                                        alt="Profile shot for Chad"
                                        src={`${URI}${manager.image}`}
                                      />
                                    </a>
                                  </Link>
                                  <div className="team-member-designation">
                                    <p>{manager.designation}</p>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        })
                      }
                    </div>

                    <div className="row ptb-20">
                      {workingEmployees && workingEmployees.map((manager) => {
                        if (manager.reporting_manager && manager.reporting_manager._id === employee._id) {
                          return (
                            <>
                              {
                                workingEmployees && workingEmployees.map((item) => {
                                  if (item.reporting_manager && item.reporting_manager._id === manager._id) {
                                    return (
                                      <div className="col-md-2">
                                        <div className="team-member-card">
                                          <Link to={`/employees/${item._id}/view`}>
                                            <a
                                              href="#"
                                              target='_blank"'
                                              className="profile employee-profile"
                                            >
                                              <h2 className="profile__name">{item.name}</h2>
                                              <p>{item.designation}</p>
                                              <img
                                                alt="Profile shot for Celina Harris"
                                                src={`${URI}${item.image}`}
                                              />
                                            </a>
                                          </Link>
                                          <div className="team-member-designation-ceo">
                                            <p>{item.designation}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </>
                          )
                        }
                      })}
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </article>

        <section className="our-website-gateway">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="our-website-gateway-img">
                  <img src="/images/undraw_progressive_app_m-9-ms.svg" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="our-website-gateway-content">
                  <h4>Also Visit Our Website</h4>
                  <hr />
                  <button class="button-85" role="button">
                    <a
                      href="https://luhaifdigitech.com/"
                      target="_blank"
                    >
                      Luhaif Digitech
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AllEmployeereports;
