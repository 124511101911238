export const GET_LEADCOMMENTS_STATED = "GET_LEADCOMMENTS_STATED";
export const GET_LEADCOMMENTS = "GET_LEADCOMMENTS";
export const GET_LEADCOMMENTS_ENDED = "GET_LEADCOMMENTS_ENDED";
export const ADD_LEADCOMMENT_STATED = "ADD_LEADCOMMENT_STARTED";
export const ADD_LEADCOMMENT = "ADD_LEADCOMMENT";
export const ADD_LEADCOMMENT_ENDED = "ADD_LEADCOMMENT_ENDED";
export const EDIT_LEADCOMMENT_STATED = "EDIT_LEADCOMMENT_STATED";
export const EDIT_LEADCOMMENT = "EDIT_LEADCOMMENT";
export const EDIT_LEADCOMMENT_ENDED = "EDIT_LEADCOMMENT_ENDED";
export const GET_LEADCOMMENT = "GET_LEADCOMMENT";
export const GET_LEADCOMMENT_STATED = "GET_LEADCOMMENT_STATED";
export const GET_LEADCOMMENT_ENDED = "GET_LEADCOMMENT_ENDED";
export const RESET_LEADCOMMENT = "RESET_LEADCOMMENT";
export const ERROR_LEADCOMMENT = "ERROR_LEADCOMMENT";
export const GET_ALL_LEADCOMMENTS_STATED = "GET_ALL_LEADCOMMENTS_STATED";
export const GET_ALL_LEADCOMMENTS = "GET_ALL_LEADCOMMENTS";
export const GET_ALL_LEADCOMMENTS_ENDED = "GET_ALL_LEADCOMMENTS_ENDED";
