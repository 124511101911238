import {
  GET_STATUSS_STATED,
  GET_STATUSS,
  GET_STATUSS_ENDED,
  ADD_STATUS_STATED,
  ADD_STATUS,
  ADD_STATUS_ENDED,
  EDIT_STATUS_STATED,
  EDIT_STATUS,
  EDIT_STATUS_ENDED,
  GET_STATUS_STATED,
  GET_STATUS,
  GET_STATUS_ENDED,
  GET_ALL_STATUSS_STATED,
  GET_ALL_STATUSS,
  GET_ALL_STATUSS_ENDED
} from "../types/status_type";

const initialState = {
  statuss_loading: true,
  statuss: null,
  page: null,
  pages: null,
  total_statuss: 0,

  status: null,
  status_loading: null,

  loading: true,

  status_message: null,
  all_statuss: null,
  all_statuss_loading: null,
  add_status_loading: true,
  edit_status_loading: true
};

export const status_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STATUSS_STATED:
      return {
        ...state,
        statuss: null,
        pages: null,
        page: null,
        total_statuss: 0,
        statuss_loading: true
      };
    case GET_STATUSS:
      return {
        ...state,
        statuss: payload.statuss,
        pages: payload.pages,
        page: payload.page,
        total_statuss: payload.count
      };
    case GET_STATUSS_ENDED:
      return {
        ...state,
        statuss_loading: false
      };
    case GET_ALL_STATUSS_STATED:
      return {
        ...state,
        all_statuss_loading: true,
        all_statuss: null
      };
    case GET_ALL_STATUSS:
      return {
        ...state,
        all_statuss: payload
      };
    case GET_ALL_STATUSS_ENDED:
      return {
        ...state,
        all_statuss_loading: false
      };

    case ADD_STATUS_STATED:
      return {
        ...state,
        status_message: null,
        add_status_loading: true
      };
    case ADD_STATUS:
      return {
        ...state,
        status_message: payload
      };
    case ADD_STATUS_ENDED:
      return {
        ...state,
        add_status_loading: false
      };
    case GET_STATUS_STATED:
      return {
        ...state,
        status: null,
        status_loading: true
      };
    case GET_STATUS:
      return {
        ...state,
        status: payload
      };
    case GET_STATUS_ENDED:
      return {
        ...state,
        status_loading: false
      };
    case EDIT_STATUS_STATED:
      return {
        ...state,
        status_message: null,
        edit_status_loading: true
      };
    case EDIT_STATUS:
      return {
        ...state,
        status_message: payload
      };
    case EDIT_STATUS_ENDED:
      return {
        ...state,
        edit_status_loading: false
      };

    default:
      return state;
  }
};
