export const PAGE_TITLE = "Bills";
export const PAGE_SINGLE_TITLE = "Bill";
export const LINK_URL = "bills";
export const inputFields = {
  client: {
    type: "related",
    required: true,
    title: "Client",
    inputType: "",
    options: "",
    field: "name",
  },
  billing_date: {
    type: "string",
    required: true,
    title: "Billing Date",
    inputType: "datetime-local",
    // options: "",
    // field: "",
    // date: true,
  },
  collection_date: {
    type: "string",
    required: false,
    title: "Collection Date",
    inputType: "date",
    options: "",
    field: "",
    date: true,
  },
  amount: {
    type: "string",
    required: true,
    title: "Amount",
    inputType: "number",
    options: "",
    field: "",
  },
  description: {
    type: "text",
    required: false,
    title: "Description",
    inputType: "",
    options: "",
    field: "",
  },
  status: {
    type: "select",
    required: true,
    title: "Bill Status",
    inputType: "",
    options: ["PAID", "NOT PAID", "CANCELLED"],
    field: "",
  },
  review_status: {
    type: "related",
    required: false,
    title: "Review Status",
    inputType: "",
    options: "",
    field: "name",
  },
  package: {
    type: "related",
    required: true,
    title: "Package",
    inputType: "",
    options: "",
    field: "name",
  },
  collected_by: {
    type: "related",
    required: false,
    title: "Collected By",
    inputType: "",
    options: "",
    field: "name",
  },
  bank_account: {
    type: "related",
    required: false,
    title: "Bank Account",
    inputType: "",
    options: "",
    field: "name",
  },
  remarks: {
    type: "string",
    required: false,
    title: "Remarks",
    inputType: "",
    options: "",
    field: "name",
  },
  invoice_no: {
    type: "string",
    required: false,
    title: "Invoice No.",
    inputType: "",
    options: "",
    field: "name",
  },
  suppliers_ref: {
    type: "string",
    required: false,
    title: "Suppliers Ref",
    inputType: "",
    options: "",
    field: "name",
  },
};
export const initialValues = {
  client: "",
  billing_date: "",
  collection_date: "",
  amount: "",
  description: "",
  status: "",
  review_status: "",
  package: "",
  collected_by: "",
  bank_account: "",
  remarks: "",
  invoice_no: "",
  suppliers_ref: "",
};

export const view_all_table = [
  { name: "Client", value: "client", related: true, field: "name" },
  {
    name: "Billing Date",
    value: "billing_date",
    date: true
  },
  { name: "Collection Date", value: "collection_date", date: true },
  { name: "Amount", value: "amount" },
  { name: "Status", value: "status" },
  { name: "Review", value: "review_status", related: true, field: "name" },
  { name: "Package", value: "package", related: true, field: "name" },
  { name: "Collected By", value: "collected_by", related: true, field: "name" },
  { name: "Bank Account", value: "bank_account", related: true, field: "name" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "client",
    field: "client",
    label: "Client",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "billing_date_from",
    field: "billing_date",
    label: "Billing Date From",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "billing_date_to",
    field: "billing_date",
    label: "Billing Date To",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
  {
    id: "collection_date_from",
    field: "collection_date",
    label: "Collection Date From",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "collection_date_to",
    field: "collection_date",
    label: "Collection Date To",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
  {
    id: "amount",
    field: "amount",
    label: "Amount",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },

  {
    id: "status",
    field: "status",
    label: "Status",
    type: "select",
    search_type: "exact",
    inputType: "text",
    options: ["PAID", "NOT PAID", "CANCELLED"],
    condition: "",
  },
  {
    id: "review_status",
    field: "review_status",
    label: "Review Status",
    type: "related",
    search_type: "search",
    inputType: "text",
    // options: ["PAID", "NOT PAID", "CANCELLED"],
    condition: "",
  },
  {
    id: "package",
    field: "package",
    label: "Package",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "collected_by",
    field: "collected_by",
    label: "Collected By",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "bank_account",
    field: "bank_account",
    label: "Bank Account",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
