import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import AddBtn from "../../components/common/AddBtn";
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/activitycycles_enum";
import DataTable from "../../components/common/DataTable";
import {
  useAllActivitycycles,
  useGetDropdownOptions,
  useUpdateActivitycycle,
} from "../../shared/hooks/UseActivitycycle";
import {
  useAllActivitys,
  useAllActivitysByCycle,
} from "../../shared/hooks/UseActivity";

import {
  useAllComments,
  useCreateComment,
} from "../../shared/hooks/UseComment";

import SidebarFilter from "../../components/common/SidebarFilter";
import { LIMIT } from "../../domain/constant";
import moment from "moment";
import ReactModal from "react-modal";
import SingleView from "../../components/common/SingleView";
import Spinner from "../../components/layout/Spinner";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextArea, TextInput } from "../../components/Form/Form";
import { Link } from "react-router-dom";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";
import ExportComponent from "../../components/common/ExportComponent";
import { useSelector } from "react-redux";

const AllActivitycycles = ({ }) => {
  const [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData] =
    useAllActivitycycles();

  const [] = useAllActivitys();
  const [updateData] = useUpdateActivitycycle();

  const {
    activitycycles_loading,
    activitycycles,
    total_activitycycles,
    page,
    pages,
  } = data;

  const [comment_data, setActivity, setReloadComments] = useAllComments();
  const [activity_data, setAcvtivityCycle, setReloadActivity] =
    useAllActivitysByCycle();
  const { comments, comments_loading } = comment_data;
  const { activitys, activitys_loading } = activity_data;
  const [commentData, addData] = useCreateComment();
  const submitFormClicked = async (values) => {
    // const data = await convertToFormData({ values, featuredImage });
    await addData(values);
    setReloadComments(true);

    // history.push(`/${LINK_URL}`);
  };

  const [dropdownOptions, loadOptions] = useGetDropdownOptions();


  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editField, setEditField] = useState(null);

  const [newStatus, setNewStatus] = useState(null);
  const [newValues, setNewValues] = useState(null);
  const [loadingActivityStatus, setLoadingActivityStatus] = useState(false);

  const [isOverdue, setIsOverdue] = useState(false);

  const [showtotalEstimateTime, setShowtotalEstimateTime] = useState(0)



  useEffect(() => {
    if (activitys && activitys.length > 0) {
      let totalEstimateActivitycycleTime = activitys && activitys.map((item) => Number(item.estimated_time)).reduce((accum, curr) => { return (accum + curr) })
      setShowtotalEstimateTime(totalEstimateActivitycycleTime)
    } else {
      setShowtotalEstimateTime(0)
    }
  }, [activitys])


  const updateStatus = async (id, status) => {
    // console.log("ID", id, value);
    if (id && status) {
      setLoadingActivityStatus(true);
      const newForm = new FormData();
      newForm.append("values", JSON.stringify(status));
      await updateData(id, newForm);
      await setReloadData(true);
      setEditId(null);
      setLoadingActivityStatus(false);
    } else {
      setEditId(null);
    }
  };

  console.log("comments", comments);

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (activitycycles) {
      convertToReadable(activitycycles, inputFields);
    }
  }, [activitycycles]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Activity Cycles", "activity-cycle");
  };
  const userData = useSelector((state) => state.auth);
  const { user } = userData;

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />
        <ExportComponent handleOnExport={handleOnExport} />
        {user && (
          <FilterDateComponent
            link="/activity-cycles"
          // showOwn={true}
          // searchParams={{ term: "assigned_to", value: user._id }}
          />
        )}

        <div className="container-fluid">
          <div className="row">
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
                col={2}
              />
            )}
            <div className="col-lg-10">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />

                  <div className="d-flex-2">
                    <div style={{ paddingBottom: "10px" }}>
                      <Link className="btn btn-danger" to={`/activity-cycles?conditional[due_time][$lt]=${moment().utc().format("YYYY-MM-DD")}&exact[status]=PENDING`} >
                        Overdue Activity Cycles
                      </Link>
                    </div>
                    <div className="add-calendar-btn">
                      <Link className="btn btn-warning" to={`/activity-cycles/add-calendar`}>
                        Add Calendar
                      </Link>
                    </div>
                  </div>



                  {total_activitycycles} records found
                  <div>
                    <table className="table table-new align-middle table-striped  table-bordered">
                      {!activitycycles_loading && (
                        <thead>
                          <tr className="bg-transparent" role="row">
                            <th style={{ width: "50px" }}>#</th>
                            <th> Name </th>
                            <th> Client </th>
                            <th> Due Time </th>
                            <th> Process </th>
                            <th> Activity Status </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      )}

                      <tbody>
                        {!activitycycles_loading ? (
                          activitycycles &&
                          activitycycles.map((single, index) => {
                            if (editId && editId == single._id) {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT}</td>
                                  <td>
                                    {editField == "name" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input className="form-control"
                                              value={newValues && newValues.name}
                                              onChange={(e) =>
                                                setNewValues({
                                                  name:
                                                    e.target.value,
                                                })
                                              } />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.name
                                    )}

                                  </td>

                                  <td>


                                    {editField == "client" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  client:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.client &&
                                                dropdownOptions.client.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.client &&
                                                          single.client
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.client &&
                                      single.client.name
                                    )}


                                  </td>
                                  <td>

                                    {single.due_time &&
                                      moment(single.due_time)
                                        .utc()
                                        .format(
                                          "DD-MM-YYYY hh:mm A")}

                                    {editField == "due_time" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input className="form-control"
                                              value={newValues && newValues.due_time}
                                              type="datetime-local"
                                              onChange={(e) =>
                                                setNewValues({
                                                  due_time:
                                                    e.target.value,

                                                })
                                              } />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.due_time
                                    )}
                                  </td>
                                  <td>
                                    {editField == "process" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  process: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.process &&
                                                dropdownOptions.process.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.process &&
                                                          single.process
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.process &&
                                      single.process.name
                                    )}
                                  </td>

                                  <td>
                                    {editField == "status" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  status: e.target.value,
                                                })
                                              }
                                              defaultValue={single.status}
                                            >
                                              <option value="PENDING">

                                                PENDING
                                              </option>
                                              <option value="COMPLETED">

                                                COMPLETED
                                              </option>
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.status
                                    )}
                                  </td>

                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        setActivity(single._id);
                                        setAcvtivityCycle(single._id);

                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }

                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT}</td>
                                  <td> {single.name}
                                    <a
                                      onClick={() => {
                                        setEditField("name");
                                        setEditId(single._id);
                                        setNewValues({ name: single.name })
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>

                                  <td>
                                    {" "}
                                    {single.client && single.client.name}{" "}

                                    <a
                                      onClick={() => {
                                        setEditField("client");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>

                                    {single.due_time && moment(single.due_time).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )}


                                    {single.status && single.status !== "COMPLETED" && moment().diff(moment(single.due_time)
                                      .utc(), "hours") > 0 ? (
                                      <span class="badge bg-danger p-1">
                                        Over Due Activity Cycle
                                      </span>
                                    ) : ("")}

                                    <a
                                      onClick={() => {
                                        setEditField("due_time");
                                        setEditId(single._id);
                                        setNewValues({ due_time: single.due_time })
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>


                                  </td>
                                  <td>
                                    {single.process && single.process.name}

                                    <a
                                      onClick={() => {
                                        setEditField("process");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>

                                  <td>

                                    {single.status}
                                    <a
                                      onClick={() => {
                                        setEditField("status");
                                        setEditId(single._id);
                                      }}
                                    >

                                      <i className="fa fa-edit"></i>
                                    </a>
                                  </td>

                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        setActivity(single._id);
                                        setAcvtivityCycle(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={20} className="text-center">
                              <Spinner />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <Pagination
                      data={activitycycles}
                      page={page}
                      pages={pages}
                      count={total_activitycycles}
                      setPage={setPageNumber}
                      loading={activitycycles_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        contentLabel="Modal"
        className="Modal2"
        overlayClassName="Overlay"
        onRequestClose={() => {
          setModal(false);
        }}
      >
        <div className="quick-view">
          <div className="qv-header">
            <div className="title"> {PAGE_SINGLE_TITLE} </div>
            <div>
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
          {modalData && (
            <>

              <div className="qv-body">
                <SingleView
                  hideGallery={true}
                  data={modalData}
                  inputFields={inputFields}
                  label={PAGE_SINGLE_TITLE}
                  link={LINK_URL}
                  id={modalData._id}
                  hideAllBtn={true}
                  col={12}

                >
                  <div className="col-md-6">
                    <div style={{ borderBottom: '1px solid rgb(241, 241, 241)', paddingBottom: 5, paddingTop: 5 }}>
                      <div className="title">
                        Total Estimate Time
                      </div>
                      <div style={{ marginBottom: 10, fontWeight: 500, fontSize: 16 }}>
                        {showtotalEstimateTime} mins
                      </div>
                    </div>
                  </div>
                </SingleView>

                <div className="card">
                  <div className="card-body">
                    <table className="table table-stripped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Task Name </th>
                          <th> Assigned To </th>
                          <th> Due Time </th>
                          <th> Estimate Time </th>
                          <th> Client </th>
                          <th> Process </th>
                          <th> Activity Status </th>
                          <th> Review Status </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!activitys_loading ? (
                          <>
                            {activitys &&
                              activitys.map((single, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1 + (page - 1) * LIMIT} </td>
                                    <td> {single.task_name}

                                      <a
                                        onClick={() => {
                                          setEditField("task_name");
                                          setEditId(single._id);
                                          setNewValues({ task_name: single.task_name })
                                        }}
                                      >
                                        {" "}
                                        <i className="fa fa-edit"></i>{" "}
                                      </a>
                                    </td>
                                    <td>
                                      {single.assigned_to &&
                                        single.assigned_to.name}
                                    </td>
                                    <td>
                                      {single.due_time &&
                                        moment(single.due_time)
                                          .utc()
                                          .format("DD-MM-YYYY hh:mm A")}
                                    </td>
                                    <td>{single.estimated_time}</td>
                                    <td>
                                      {" "}
                                      {single.client && single.client.name}{" "}
                                    </td>
                                    <td>
                                      {single.process && single.process.name}
                                    </td>
                                    <td>
                                      {single.activity_status &&
                                        single.activity_status.name}
                                    </td>
                                    <td>
                                      {single.review_status &&
                                        single.review_status.name}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/activitys/${single._id}/view`}
                                        className="btn btn-sm btn-success"
                                      >
                                        {" "}
                                        <i className="fa fa-eye"></i>{" "}
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <p> Loading... </p>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        comment: "",
                      }}
                      validationSchema={Yup.object({
                        comment: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        values.activity = modalData._id;
                        if (modalData.activity_cycle) {
                          values.activity_cycle = modalData.activity_cycle;
                        }
                        await submitFormClicked(values);
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row align-items-center">
                              <div className="col-md-9">
                                <TextArea
                                  label="Comment"
                                  name="comment"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  {" "}
                                  {formik.isSubmitting
                                    ? "SAVING..."
                                    : "SAVE"}{" "}
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <table className="table table-stripped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Comment</th>
                          <th>Comment By</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!comments_loading ? (
                          <>
                            {comments &&
                              comments.map((item, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1} </td>
                                    <td> {item.comment} </td>
                                    <td>
                                      {" "}
                                      {item.created_by &&
                                        item.created_by.name}{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      {item.createdAt &&
                                        moment(item.createdAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <p> Loading... </p>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AllActivitycycles;
