import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addActivity,
  getActivitys,
  getActivity,
  editActivity,
  deleteActivity,
  getAllActivitys,
  addBulkActivity,
  getActivitysByCycle,
  getActivitysByEmployee,
  quickAddActivity,
  getAdsActivities,
  addAdsActivity,
  updateAdsActivity,
} from '../../store/actions/activity_action';
import _debounce from 'lodash/debounce';
// import { useSelectAllIndustry } from "./UseIndustry";
import { useSelectAllEmployee } from '../hooks/UseEmployee';
import { useSelectAllClient } from '../hooks/UseClient';
import { useSelectAllProcess } from '../hooks/UseProcess';
import { useSelectAllActivitystatus } from '../hooks/UseActivitystatus';
import { useSelectAllReviewstatus } from '../hooks/UseReviewstatus';
import { useSelectAllActivitycycle } from '../hooks/UseActivitycycle';

// Get All Data
export const useAllActivitys = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  // console.log("PAGE NO", pageNumber);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteActivity(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getActivitys({
          pageNumber,
        })
      );
    }, 1000),
    []
  );
  useEffect(() => {
    if (pageNumber) {
      dispatch(
        getActivitys({
          pageNumber,
        })
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (reloadData) {
      allQuery();
      setReloadData(false);
    }
  }, [reloadData]);
  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData];
};

export const useAllActivitysByEmployee = (employee) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  console.log('PAGE NO', pageNumber);
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteActivity(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getActivitysByEmployee({
          pageNumber,
          employee,
        })
      );
    }, 1000),
    []
  );
  useEffect(() => {
    if (pageNumber) {
      dispatch(
        getActivitysByEmployee({
          pageNumber,
          employee,
        })
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (reloadData) {
      allQuery();
      setReloadData(false);
    }
  }, [reloadData]);
  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData];
};

export const useAllActivitysByCycle = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  const [reloadData, setReloadData] = useState(false);
  const [activitycycle, setActivityCycle] = useState(null);
  useEffect(async () => {
    if (activitycycle) {
      await dispatch(getActivitysByCycle(activitycycle));
    }
  }, [activitycycle]);
  useEffect(async () => {
    if (reloadData) {
      await dispatch(getActivitysByCycle(activitycycle));
      setReloadData(false);
    }
  }, [reloadData]);
  // console.log("ACTT")
  return [data, setActivityCycle, setReloadData];
};
// Get Ads Activities according to Client
export const useGetAdsActivities = (client) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  const {
    campaigns_loading,
    total_campaigns,
    campaigns,
    campaign_page,
    campaigns_pages,

    landing_pages_loading,
    total_landing_pages,
    landing_pages,
    landing_page_page,
    landing_pages_pages,

    creatives_loading,
    total_creatives,
    creatives,
    creative_page,
    creatives_pages,

    add_ads_client_activity_loading,
    add_ads_client_activity,
  } = data;
  useEffect(() => {
    dispatch(getAdsActivities(client));
  }, []);
  return {
    campaigns_loading,
    total_campaigns,
    campaigns,
    campaign_page,
    campaigns_pages,

    landing_pages_loading,
    total_landing_pages,
    landing_pages,
    landing_page_page,
    landing_pages_pages,

    creatives_loading,
    total_creatives,
    creatives,
    creative_page,
    creatives_pages,
    add_ads_client_activity_loading,
    add_ads_client_activity,
  };
};

// Add Ads Activity
export const useAddAdsActivity = (client) => {
  const dispatch = useDispatch();
  const addAdActivityHandler = (formData, activity_type) => {
    dispatch(
      addAdsActivity({ client, formData, activity_type: activity_type })
    );
  };
  return {
    addAdActivityHandler,
  };
};

// Update Ads Activity
export const useUpdateAdsActivity = () => {
  const dispatch = useDispatch();
  const updateAdActivityHandler = (activity, formData, activity_type) => {
    dispatch(
      updateAdsActivity({ activity, formData, activity_type: activity_type })
    );
  };
  return {
    updateAdActivityHandler,
  };
};

// Get Single Data
export const useSingleActivity = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  useEffect(() => {
    dispatch(getActivity(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateActivity = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  const addData = async (data) => {
    await dispatch(addActivity(data));
  };
  return [data, addData];
};
export const useCreateQuickActivity = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  const addData = async (data) => {
    await dispatch(quickAddActivity(data));
  };
  return [data, addData];
};
// Bulk Add Data
export const useBulkCreateActivity = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activity);
  const addData = async (data) => {
    await dispatch(addBulkActivity(data));
  };
  return [data, addData];
};
// Update Data
export const useUpdateActivity = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.activity);
  const updateData = async (id, data) => {
    await dispatch(editActivity(id, data));
  };
  return [updateData];
};

export const useSelectAllActivity = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const data = useSelector((state) => state.activity);
  useEffect(() => {
    dispatch(getAllActivitys({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [employee, setEmployeeSearchField, setEmployeeSearchValue] =
    useSelectAllEmployee();
  const [
    activitycycle,
    setActivitycycleSearchField,
    setActivitycycleSearchValue,
  ] = useSelectAllActivitycycle();
  const [client, setClientSearchField, setClientSearchValue] =
    useSelectAllClient();
  const [process, setProcessSearchField, setProcessSearchValue] =
    useSelectAllProcess();
  const [
    activitystatus,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
  ] = useSelectAllActivitystatus();
  const [reviewstatus, setReviewstatusSearchField, setReviewstatusSearchValue] =
    useSelectAllReviewstatus();
  // const [activitycycle, setActivitycycleSearchField, setActivitycycleSearchValue] =
  //   useSelectAllActivitycycle();
  // const [employee] = useSelectAllEmployee();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, assigned_to: newData });
    }
  }, [employee]);
  useEffect(() => {
    if (activitycycle && activitycycle.all_activitycycles) {
      const newData = activitycycle.all_activitycycles.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, activity_cycle: newData });
    }
  }, [activitycycle]);
  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, assigned_to: newData });
    }
  }, [employee]);

  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        client: newData,
        clients: newData,
      });
    }
  }, [client]);

  useEffect(() => {
    if (process && process.all_processs) {
      const newData = process.all_processs.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, process: newData });
    }
  }, [process]);

  useEffect(() => {
    if (activitystatus && activitystatus.all_activitystatuss) {
      const newData = activitystatus.all_activitystatuss.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, activity_status: newData });
    }
  }, [activitystatus]);
  useEffect(() => {
    if (reviewstatus && reviewstatus.all_reviewstatuss) {
      const newData = reviewstatus.all_reviewstatuss.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, review_status: newData });
    }
  }, [reviewstatus]);

  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);

  return [
    dropdownOptions,
    setClientSearchField,
    setClientSearchValue,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
  ];
};
