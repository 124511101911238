export const GET_BILLREVIEWS_STATED = "GET_BILLREVIEWS_STATED";
export const GET_BILLREVIEWS = "GET_BILLREVIEWS";
export const GET_BILLREVIEWS_ENDED = "GET_BILLREVIEWS_ENDED";
export const ADD_BILLREVIEW_STATED = "ADD_BILLREVIEW_STARTED";
export const ADD_BILLREVIEW = "ADD_BILLREVIEW";
export const ADD_BILLREVIEW_ENDED = "ADD_BILLREVIEW_ENDED";
export const EDIT_BILLREVIEW_STATED = "EDIT_BILLREVIEW_STATED";
export const EDIT_BILLREVIEW = "EDIT_BILLREVIEW";
export const EDIT_BILLREVIEW_ENDED = "EDIT_BILLREVIEW_ENDED";
export const GET_BILLREVIEW = "GET_BILLREVIEW";
export const GET_BILLREVIEW_STATED = "GET_BILLREVIEW_STATED";
export const GET_BILLREVIEW_ENDED = "GET_BILLREVIEW_ENDED";
export const RESET_BILLREVIEW = "RESET_BILLREVIEW";
export const ERROR_BILLREVIEW = "ERROR_BILLREVIEW";
export const GET_ALL_BILLREVIEWS_STATED = "GET_ALL_BILLREVIEWS_STATED";
export const GET_ALL_BILLREVIEWS = "GET_ALL_BILLREVIEWS";
export const GET_ALL_BILLREVIEWS_ENDED = "GET_ALL_BILLREVIEWS_ENDED";
