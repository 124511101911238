import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  HTMLInput,
  SelectBox,
  TextArea,
  TextInput,
} from '../../components/Form/Form';
import { useGetDropdownOptions } from '../../shared/hooks/UseActivity';
import { ADS_ACTIVITY_TYPES, ADS_PLATFORM_TYPES } from '../../domain/constant';
const ClientAdsModel = ({
  toggleModal,
  handleFormSubmit,
  setToggleModal,
  title,
  showPlatform,
  showAdsId,
  showBenchmark,
}) => {
  const [dropdownOptions] = useGetDropdownOptions();
  return (
    <>
      {toggleModal && (
        <section
          className='client-ads-model '
          style={{ overflow: 'scroll' }}
          id='client-model'
        >
          <div className='client-modal-form'>
            <div className='client-modal-form-top d-flex justify-content-between'>
              <h4 className='mb-4 '>Create {title}</h4>
              <div className='client-modal-close-btn'>
                <i
                  class='fa fa-times'
                  aria-hidden='true'
                  onClick={() => setToggleModal(false)}
                ></i>
              </div>
            </div>

            <Formik
              initialValues={{
                task_name: '',
                assigned_to: '',
                activity_type: '',
                due_time: '',
                process: '',
                activity_status: '',
                review_status: '',
                task_description: '',
                ads_id: '',
                benchmark: '',
                ads_platform_type: '',
              }}
              validationSchema={Yup.object({
                task_name: Yup.string().required('Required'),
                activity_type: Yup.string().required('Required'),
                assigned_to: Yup.string().required('Required'),
                due_time: Yup.string().required('Required'),
                process: Yup.string().required('Required'),
                activity_status: Yup.string().required('Required'),
                ads_platform_type:
                  showPlatform && Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                handleFormSubmit(values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                console.log(formik);
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-6'>
                        <TextInput
                          label='Task Name'
                          name='task_name'
                          type='text'
                        />
                      </div>

                      {showPlatform && (
                        <div className='col-md-6'>
                          <SelectBox label='Platform' name='ads_platform_type'>
                            <option value=''>Select </option>
                            {ADS_PLATFORM_TYPES &&
                              ADS_PLATFORM_TYPES.map((item) => {
                                return (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </SelectBox>
                        </div>
                      )}
                      {showAdsId && (
                        <div className='col-md-6'>
                          <TextInput label='Ads ID' name='ads_id' type='text' />
                        </div>
                      )}

                      {showBenchmark && (
                        <div className='col-md-6'>
                          <TextInput
                            label='Benchmark'
                            name='benchmark'
                            type='number'
                          />
                        </div>
                      )}

                      <div className='col-md-6'>
                        <SelectBox label='Assigned To' name='assigned_to'>
                          <option value=''> Select Assigned to </option>
                          {dropdownOptions.assigned_to &&
                            dropdownOptions.assigned_to.map((item) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            })}
                        </SelectBox>
                      </div>
                      <div className='col-md-6'>
                        <TextInput
                          label='Due Time'
                          name='due_time'
                          type='datetime-local'
                        />
                      </div>
                      <div className='col-md-6'>
                        <SelectBox label='Process' name='process'>
                          <option value=''> Select Process </option>
                          {dropdownOptions.process &&
                            dropdownOptions.process.map((item) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            })}
                        </SelectBox>
                      </div>

                      <div className='col-md-6'>
                        <SelectBox label='Review Status' name='review_status'>
                          <option value=''> Select Review Status </option>
                          {dropdownOptions.review_status &&
                            dropdownOptions.review_status.map((item) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            })}
                        </SelectBox>
                      </div>
                      <div className='col-md-6'>
                        <SelectBox
                          label='Activity Status'
                          name='activity_status'
                        >
                          <option value=''> Select Activity Status </option>
                          {dropdownOptions.activity_status &&
                            dropdownOptions.activity_status.map((item) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            })}
                        </SelectBox>
                      </div>
                      <div className='col-md-12'>
                        <TextArea
                          label={'Description'}
                          name='task_description'
                          value={formik.values.task_description}
                        />
                      </div>
                      <div className='client-modal-form-btn text-center'>
                        <button className='btn btn-success m-1' type='submit'>
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </section>
      )}
    </>
  );
};

export default ClientAdsModel;
