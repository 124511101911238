import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addLeadcomment,
  getLeadcomments,
  getLeadcomment,
  editLeadcomment,
  deleteLeadcomment,
  getAllLeadcomments,
} from "../../store/actions/leadcomment_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllLeadComments = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.leadcomment);
  const [reloadData, setReloadData] = useState(false);
  const [lead, setLead] = useState(null);
  useEffect(async () => {
    if (lead) {
      await dispatch(getLeadcomments(lead));
    }
  }, [lead]);
  useEffect(async () => {
    if (reloadData) {
      await dispatch(getLeadcomments(lead));
      setReloadData(false);
    }
  }, [reloadData]);
  // console.log("ACTT")
  return [data, setLead, setReloadData];
};

// Get Single Data
export const useSingleLeadcomment = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.leadcomment);
  useEffect(() => {
    dispatch(getLeadcomment(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateLeadcomment = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.leadcomment);
  const addData = async (data) => {
    await dispatch(addLeadcomment(data));
  };
  return [data, addData];
};
export const useUpdateLeadcomment = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.leadcomment);
  const updateData = async (id, data) => {
    await dispatch(editLeadcomment(id, data));
  };
  return [updateData];
};

export const useSelectAllLeadcomment = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.leadcomment);
  useEffect(() => {
    dispatch(getAllLeadcomments({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "parent_category") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };

  return [dropdownOptions, loadOptions];
};
