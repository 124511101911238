import api from "../../domain/api";
import {
  GET_DASHBOARDS_STATED,
  GET_DASHBOARDS,
  GET_DASHBOARDS_ENDED,
} from "../types/dashboard_type";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";
import * as qs from "qs";
export const getDashboards = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARDS_STATED,
    });
    const queryParams = qs.parse(window.location.search.replace("?", ""));

    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    const { data } = await api.get(
      `/dashboards?${query}`
    );

    dispatch({
      type: GET_DASHBOARDS,
      payload: data,
    });
    dispatch({
      type: GET_DASHBOARDS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_DASHBOARDS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
