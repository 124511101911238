
import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import ReactModal from 'react-modal';
import * as Yup from 'yup';
import { TextArea, TextInput, CheckBox, SelectBox } from '../../components/Form/Form';
import {
    useAllActivitys,
    useCreateActivity,
    useGetDropdownOptions,
    useUpdateActivity,
    useCreateQuickActivity
} from '../../shared/hooks/UseActivity';
import moment from 'moment';
import { convertToFormData } from "../../shared/upload";
import { DEFAULT_REVIEW_STATUS, COMPLETED_STATUS } from "../../domain/constant"
import { useSelector } from 'react-redux';


export const QuickActivityForm = ({
    modal,
    setModal,

}) => {
    const [
        dropdownOptions,
        setEmployeeSearchField,
        setEmployeeSearchValue,
        setClientSearchField,
        setClientSearchValue,
        setProcessSearchField,
        setProcessSearchValue,
        setActivitystatusSearchField,
        setActivitystatusSearchValue,
        setReviewstatusSearchField,
        setReviewstatusSearchValue,
        setActivitycycleSearchField,
        setActivitycycleSearchValue,
    ] = useGetDropdownOptions();
    const data = useSelector((state) => state.auth);
    const { user } = data;

    const [activity, addData] = useCreateQuickActivity();
    const submitFormClicked = async (values) => {
        const data = await convertToFormData({ values });
        await addData(data);
        setModal(false);
        // history.push(`/${LINK_URL}`);
    };

    return (
        <div>
            <ReactModal
                isOpen={modal}
                contentLabel='Modal'
                className='Modal'
                overlayClassName='Overlay'
                onRequestClose={() => {
                    setModal(false);
                }}
            >
                <div className='quick-view'>
                    <div className='qv-header'>
                        <div className='title'> Add Quick Activity </div>
                        <div>
                            <button
                                onClick={() => {
                                    setModal(false);
                                }}
                                className='btn btn-primary'
                            >
                                <i className='fa fa-times'></i>
                            </button>
                        </div>
                    </div>
                    <div className='qv-body p-1rem'>
                        {user && (
                            <Formik
                                initialValues={{
                                    task_name: "",
                                    assigned_to: user && user._id,
                                    due_time: "",
                                    client: "",
                                    process: "",
                                    review_status: DEFAULT_REVIEW_STATUS,
                                    activity_status: COMPLETED_STATUS,
                                }}
                                validationSchema={Yup.object({
                                    task_name: Yup.string().required("Required"),
                                    assigned_to: Yup.string().required("Required"),
                                    due_time: Yup.string().required("Required"),
                                    client: Yup.string().required("Required"),
                                    process: Yup.string().required("Required"),
                                    review_status: Yup.string().required("Required"),
                                    activity_status: Yup.string().required("Required"),
                                })}
                                onSubmit={async (
                                    values,
                                    { setSubmitting, resetForm }
                                ) => {
                                    setSubmitting(true);
                                    submitFormClicked(values)
                                    resetForm();
                                    setSubmitting(false);
                                }}
                            >
                                {(formik) => {
                                    console.log(formik);
                                    return (
                                        <Form>
                                            <div className="add-activity-form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <TextInput
                                                            label="Task Name"
                                                            name="task_name"
                                                            type="text"
                                                            placeholder="Task Name"
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <SelectBox
                                                            label="Assigned to"
                                                            name="assigned_to"
                                                            placeholder="Enter Assigned to">
                                                            <option value=""> Select Assigned to </option>
                                                            {dropdownOptions.assigned_to && dropdownOptions.assigned_to.map((item) => {
                                                                return (
                                                                    <option value={item.value}> {item.label} </option>
                                                                )
                                                            })}
                                                        </SelectBox>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <TextInput
                                                            label="Due Time"
                                                            name="due_time"
                                                            type="datetime-local"
                                                            placeholder="Enter Due Time"
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <SelectBox
                                                            label="Client"
                                                            name="client"
                                                            placeholder="Enter Process">
                                                            <option value=""> Select Client </option>
                                                            {dropdownOptions.client && dropdownOptions.client.map((item) => {
                                                                return (
                                                                    <option value={item.value}> {item.label} </option>
                                                                )
                                                            })}
                                                        </SelectBox>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <SelectBox
                                                            label="Process"
                                                            name="process"
                                                            placeholder="Enter Process">
                                                            <option value=""> Select Process </option>
                                                            {dropdownOptions.process && dropdownOptions.process.map((item) => {
                                                                return (
                                                                    <option value={item.value}> {item.label} </option>
                                                                )
                                                            })}
                                                        </SelectBox>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <SelectBox
                                                            label="Review Status"
                                                            name="review_status"
                                                            placeholder="Enter Review Status">

                                                            <option value=""> Select Review Status </option>
                                                            {dropdownOptions.review_status && dropdownOptions.review_status.map((item) => {
                                                                return (
                                                                    <option value={item.value}>{item.label}</option>
                                                                )
                                                            })}
                                                        </SelectBox>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <SelectBox
                                                            label="Activity Status"
                                                            name="activity_status"
                                                            placeholder="Enter Activity Status"
                                                            value={formik.values.activity_status}
                                                        >
                                                            <option value=""> Select Activity Status </option>
                                                            {dropdownOptions.activity_status && dropdownOptions.activity_status.map((item) => {
                                                                return (
                                                                    <option value={item.value}>{item.label}</option>
                                                                )
                                                            })}
                                                        </SelectBox>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 text-center m-3">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success"
                                                        >
                                                            {formik.isSubmitting ? "Processing..." : "Save"}
                                                        </button>
                                                        <a
                                                            className="btn btn-secondary m-3"
                                                            // onClick={history.goBack}
                                                            href="#goback"
                                                        >
                                                            <i className="fa fa-angle-left"></i> Go Back
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}

                    </div>
                </div>
            </ReactModal>
        </div>
    );
};