import React from 'react';
import Header from '../../components/template/Header';
import BreadCrumb from '../../components/template/BreadCrumb';
import { useSelectAllAdsClient } from '../../shared/hooks/UseClient';
import FilterAdsDateComponent from '../../components/common/FilterAdsDateComponent';
import { Link } from 'react-router-dom';

const ClientsAds = () => {
  const { clients_data } = useSelectAllAdsClient();
  const { ads_clients_loading, ads_clients } = clients_data;
  return (
    <>
      <Header />
      <BreadCrumb title={'Ads'} />
      {/* <FilterAdsDateComponent link={'clients-ads'} /> */}

      <section>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='ads-clients-table'>
                <table class='table table-sm border '>
                  <thead>
                    <tr>
                      <th scope='col'>Sr. No.</th>
                      <th scope='col'>Client Name</th>
                      <th scope='col'>Total Landing pages</th>
                      <th scope='col'> Total Ads Creatives </th>
                      <th scope='col'> Total Ads Campaigns </th>

                      <th scope='col'> Total Conversions </th>
                      <th scope='col'> Total Spent </th>
                      <th scope='col'> Status </th>
                      <th scope='col'>All Info.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ads_clients &&
                      ads_clients.map((item, i) => {
                        return (
                          <tr key={item._id}>
                            <th scope='row'> {i + 1} </th>
                            <td> {item.name} </td>
                            <td> {item.total_landing_pages} </td>
                            <td> {item.total_creatives} </td>
                            <td> {item.total_campaigns} </td>
                            <td> {item.total_conversions} </td>
                            <td> {item.total_cost} </td>
                            <td>{item.status && item.status.name}</td>
                            <td>
                              <Link
                                class='btn btn-success client-view-btn'
                                to={`/clients-ads/${item._id}`}
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientsAds;
