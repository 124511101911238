import {
  GET_ADSREPORTS_STATED,
  GET_ADSREPORTS,
  GET_ADSREPORTS_ENDED,
  ADD_ADSREPORT_STATED,
  ADD_ADSREPORT,
  ADD_ADSREPORT_ENDED,
  EDIT_ADSREPORT_STATED,
  EDIT_ADSREPORT,
  EDIT_ADSREPORT_ENDED,
  GET_ADSREPORT_STATED,
  GET_ADSREPORT,
  GET_ADSREPORT_ENDED,
  GET_ALL_ADSREPORTS_STATED,
  GET_ALL_ADSREPORTS,
  GET_ALL_ADSREPORTS_ENDED,
  GET_FB_ADS_REPORTS_STARTED,
  GET_FB_ADS_REPORTS,
  GET_FB_ADS_REPORTS_ENDED,
} from '../types/adsreport_type';

const initialState = {
  adsreports_loading: true,
  adsreports: null,
  page: null,
  pages: null,
  total_adsreports: 0,

  adsreport: null,
  adsreport_loading: null,

  loading: true,

  total_clicks: null,
  total_conversions: null,
  total_cost: null,

  adsreport_message: null,
  all_adsreports: null,
  all_adsreports_loading: null,
  add_adsreport_loading: true,
  edit_adsreport_loading: true,

  fb_adsreports_loading: true,
  fb_adsreports: null,
  fb_page: null,
  fb_pages: null,
  fb_total_adsreports: 0,

  fb_total_conversions: null,
  fb_total_cost: null,
};

export const adsreport_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ADSREPORTS_STATED:
      return {
        ...state,
        adsreports: null,
        pages: null,
        page: null,
        total_adsreports: 0,
        total_clicks: 0,
        total_conversions: 0,
        total_cost: 0,

        adsreports_loading: true,
      };
    case GET_ADSREPORTS:
      return {
        ...state,
        adsreports: payload.adsreports,
        pages: payload.pages,
        page: payload.page,
        total_clicks:
          payload.total_clicks && payload.total_clicks.length > 0
            ? payload.total_clicks[0] && payload.total_clicks[0].total
              ? payload.total_clicks[0].total
              : 0
            : 0,
        total_conversions:
          payload.total_conversions && payload.total_conversions.length > 0
            ? payload.total_conversions[0] &&
              payload.total_conversions[0].total &&
              payload.total_conversions[0].total
              ? payload.total_conversions[0].total
              : 0
            : 0,
        total_cost:
          payload.total_cost && payload.total_cost.length > 0
            ? payload.total_cost[0] &&
              payload.total_cost[0].total &&
              payload.total_cost[0].total
              ? payload.total_cost[0].total
              : 0
            : 0,
        total_adsreports: payload.count,
      };
    case GET_ADSREPORTS_ENDED:
      return {
        ...state,
        adsreports_loading: false,
      };
    // FB Stated
    case GET_FB_ADS_REPORTS_STARTED:
      return {
        ...state,
        fb_adsreports_loading: true,
        fb_adsreports: null,
        fb_page: null,
        fb_pages: null,
        fb_total_adsreports: 0,
        fb_total_conversions: 0,
        fb_total_cost: 0,
      };
    case GET_FB_ADS_REPORTS:
      return {
        ...state,
        fb_adsreports: payload.adsreports,
        fb_page: payload.page,
        fb_pages: payload.pages,
        fb_total_adsreports: payload.count,
        fb_total_conversions:
          payload.total_conversions && payload.total_conversions.length > 0
            ? payload.total_conversions[0] &&
              payload.total_conversions[0].total &&
              payload.total_conversions[0].total
              ? payload.total_conversions[0].total
              : 0
            : 0,
        fb_total_cost:
          payload.total_cost && payload.total_cost.length > 0
            ? payload.total_cost[0] &&
              payload.total_cost[0].total &&
              payload.total_cost[0].total
              ? payload.total_cost[0].total
              : 0
            : 0,
      };
    case GET_FB_ADS_REPORTS_ENDED:
      return {
        ...state,
        fb_adsreports_loading: false,
      };
    // FB Ended

    case GET_ALL_ADSREPORTS_STATED:
      return {
        ...state,
        all_adsreports_loading: true,
        all_adsreports: null,
      };
    case GET_ALL_ADSREPORTS:
      return {
        ...state,
        all_adsreports: payload,
      };
    case GET_ALL_ADSREPORTS_ENDED:
      return {
        ...state,
        all_adsreports_loading: false,
      };

    case ADD_ADSREPORT_STATED:
      return {
        ...state,
        adsreport_message: null,
        add_adsreport_loading: true,
      };
    case ADD_ADSREPORT:
      return {
        ...state,
        adsreport_message: payload,
      };
    case ADD_ADSREPORT_ENDED:
      return {
        ...state,
        add_adsreport_loading: false,
      };
    case GET_ADSREPORT_STATED:
      return {
        ...state,
        adsreport: null,
        adsreport_loading: true,
      };
    case GET_ADSREPORT:
      return {
        ...state,
        adsreport: payload,
      };
    case GET_ADSREPORT_ENDED:
      return {
        ...state,
        adsreport_loading: false,
      };
    case EDIT_ADSREPORT_STATED:
      return {
        ...state,
        adsreport_message: null,
        edit_adsreport_loading: true,
      };
    case EDIT_ADSREPORT:
      return {
        ...state,
        adsreport_message: payload,
      };
    case EDIT_ADSREPORT_ENDED:
      return {
        ...state,
        edit_adsreport_loading: false,
      };

    default:
      return state;
  }
};
