import React, { useState } from 'react';
import ClientAdsModel from '../../containers/clientsads/ClientAdsModel';
import { useAddAdsActivity } from '../../shared/hooks/UseActivity';
import { ADS_ACTIVITY_ENUM, INPUT_TYPE_ENUM } from '../../domain/constant';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Pagination from '../common/Pagination';
import { EditFieldComponent } from './EditFieldComponent';
import QuickViewAdsModel from '../../containers/clientsads/QuickViewAdsModel';

export const LandingPageComponent = ({
  landing_pages_loading,
  total_landing_pages,
  landing_pages,
  landing_page_page,
  landing_pages_pages,
  add_ads_client_activity_loading,
  add_ads_client_activity,
  client,
  dropdownOptions,
}) => {
  const [toggleModal, setToggleModal] = useState(false);
  const { addAdActivityHandler } = useAddAdsActivity(client);
  const handleFormSubmit = (values) => {
    values.activity_type = ADS_ACTIVITY_ENUM.LANDING_PAGE;
    addAdActivityHandler(values, ADS_ACTIVITY_ENUM.LANDING_PAGE);
    setToggleModal(false);
  };

  const [showQVModal, setShowQVModal] = useState(false);
  const [activeCampaign, setActiveCampaign] = useState(null);

  return (
    <div className='col-md-12'>
      <div className='single-client-ads-table'>
        <div className='single-client-ads-table-top d-flex justify-content-between align-items-center mb-4'>
          <h4>Landing Pages</h4>
          <a
            type='button'
            className='btn btn-light waves-effect waves-light'
            onClick={() => setToggleModal(true)}
          >
            <i className='fa fa-plus me-1'></i> Create Landing Page
          </a>
        </div>

        <table className='table table-sm border '>
          <thead>
            <tr>
              <th scope='col'>Sr. No.</th>
              <th scope='col'>Ad Name</th>
              <th scope='col'>Assigned To </th>
              <th scope='col'>Due Time </th>
              <th scope='col'>Activity Status </th>
              <th scope='col'>Review Status </th>
              <th scope='col'> Action </th>
            </tr>
          </thead>
          <tbody>
            {(add_ads_client_activity_loading || landing_pages_loading) &&
              add_ads_client_activity === ADS_ACTIVITY_ENUM.LANDING_PAGE && (
                <tr>
                  <th scope='row'>
                    <Skeleton />
                  </th>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              )}

            {landing_pages &&
              landing_pages.map((campaign, k) => {
                return (
                  <tr key={campaign._id}>
                    <th scope='row'>{k + 1}</th>
                    <td>
                      {/* {campaign.task_name} */}
                      <EditFieldComponent
                        field={'task_name'}
                        value={campaign.task_name}
                        element_type={INPUT_TYPE_ENUM.INPUT}
                        activity={campaign}
                        activity_type={ADS_ACTIVITY_ENUM.LANDING_PAGE}
                      />
                    </td>
                    <td>
                      {/* {campaign.assigned_to?.name} */}
                      <EditFieldComponent
                        field={'assigned_to'}
                        value={campaign.assigned_to?.name}
                        element_type={INPUT_TYPE_ENUM.SELECT}
                        activity={campaign}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                        options={dropdownOptions && dropdownOptions.assigned_to}
                        selected_value={campaign.assigned_to?._id}
                      />
                    </td>
                    <td>
                      {/* {campaign.due_time &&
                        moment(campaign.due_time).format(
                          'DD-MM-YYYY h:mm:ss a'
                        )} */}
                      <EditFieldComponent
                        field={'due_time'}
                        value={
                          campaign.due_time &&
                          moment(campaign.due_time).format(
                            'DD-MM-YYYY h:mm:ss a'
                          )
                        }
                        element_type={INPUT_TYPE_ENUM.INPUT}
                        input_type='datetime-local'
                        activity={campaign}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                      />
                    </td>
                    <td>
                      <EditFieldComponent
                        field={'activity_status'}
                        value={campaign.activity_status?.name}
                        element_type={INPUT_TYPE_ENUM.SELECT}
                        activity={campaign}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                        options={
                          dropdownOptions && dropdownOptions.activity_status
                        }
                        selected_value={campaign.activity_status?._id}
                        styles={{
                          backgroundColor: campaign.activity_status?.color
                            ? campaign.activity_status?.color
                            : '#fff',
                          color: campaign.activity_status?.color
                            ? '#fff'
                            : '#000',
                        }}
                      />
                    </td>
                    <td>
                      {/* <span
                        style={{
                          backgroundColor: campaign.review_status?.color
                            ? campaign.review_status?.color
                            : '#fff',
                          color: campaign.review_status?.color
                            ? '#fff'
                            : '#000',
                        }}
                      >
                        {campaign.review_status?.name}
                      </span> */}
                      <EditFieldComponent
                        field={'review_status'}
                        value={campaign.review_status?.name}
                        element_type={INPUT_TYPE_ENUM.SELECT}
                        activity={campaign}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                        options={
                          dropdownOptions && dropdownOptions.review_status
                        }
                        selected_value={campaign.review_status?._id}
                        styles={{
                          backgroundColor: campaign.review_status?.color
                            ? campaign.review_status?.color
                            : '#fff',
                          color: campaign.review_status?.color
                            ? '#fff'
                            : '#000',
                        }}
                      />
                    </td>
                    <td>
                      <div>
                        <button
                          className='btn btn-sm btn-success'
                          onClick={() => {
                            setActiveCampaign(campaign);
                            setShowQVModal(true);
                          }}
                        >
                          <i className='fa fa-eye'></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {landing_pages && landing_pages.length === 0 && (
              <tr>
                <td colSpan={7}> No data available </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          data={landing_pages}
          page={landing_page_page}
          pages={landing_pages_pages}
          count={total_landing_pages}
          // setPage={setPageNumber}
          loading={landing_pages_loading}
        />
      </div>
      {toggleModal && (
        <ClientAdsModel
          handleFormSubmit={handleFormSubmit}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          title={'Landing Page'}
        />
      )}
      {showQVModal && (
        <QuickViewAdsModel
          handleFormSubmit={handleFormSubmit}
          toggleModal={showQVModal}
          setToggleModal={setShowQVModal}
          title='Landing Page'
          campaign={activeCampaign}
          dropdownOptions={dropdownOptions}
        />
      )}
    </div>
  );
};
