import api from "../../domain/api";
import {
  GET_BANKACCOUNTS_STATED,
  GET_BANKACCOUNTS,
  GET_BANKACCOUNTS_ENDED,
  ADD_BANKACCOUNT_STATED,
  ADD_BANKACCOUNT,
  ADD_BANKACCOUNT_ENDED,
  EDIT_BANKACCOUNT_STATED,
  EDIT_BANKACCOUNT,
  EDIT_BANKACCOUNT_ENDED,
  GET_BANKACCOUNT_STATED,
  GET_BANKACCOUNT,
  GET_BANKACCOUNT_ENDED,
  GET_ALL_BANKACCOUNTS_STATED,
  GET_ALL_BANKACCOUNTS,
  GET_ALL_BANKACCOUNTS_ENDED,
} from "../types/bankaccount_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addBankaccount = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_BANKACCOUNT_STATED,
    });
    const { data } = await api.post(`/bankaccounts`, formData);
    dispatch({
      type: ADD_BANKACCOUNT,
      payload: data,
    });
    dispatch({
      type: ADD_BANKACCOUNT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_BANKACCOUNT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getBankaccounts =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_BANKACCOUNTS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/bankaccounts?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_BANKACCOUNTS,
        payload: data,
      });
      dispatch({
        type: GET_BANKACCOUNTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_BANKACCOUNTS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getBankaccount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BANKACCOUNT_STATED,
    });
    const { data } = await api.get(`/bankaccounts/${id}`);

    dispatch({
      type: GET_BANKACCOUNT,
      payload: data,
    });
    dispatch({
      type: GET_BANKACCOUNT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_BANKACCOUNT_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editBankaccount = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BANKACCOUNT_STATED,
    });
    const { data } = await api.put(`/bankaccounts/${id}`, formData);
    dispatch({
      type: EDIT_BANKACCOUNT,
      payload: data,
    });
    dispatch({
      type: EDIT_BANKACCOUNT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_BANKACCOUNT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteBankaccount = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/bankaccounts/${id}`);
    dispatch(setAlert("Bankaccount Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllBankaccounts = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BANKACCOUNTS_STATED,
    });
    const { data } = await api.get(`/bankaccounts/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_BANKACCOUNTS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_BANKACCOUNTS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BANKACCOUNTS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
