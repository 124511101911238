import React, { useEffect } from 'react';
import './assets/css/bootstrap.min.css';
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/wysiwyg.css';
import './assets/css/responsive.css';
import './assets/mystyle.scss';
import './assets/css/ads.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Login from './containers/login/Login';
import { loadUser } from './store/actions/auth';
import setAuthToken from './domain/setAuthToken';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import Dashboard from './containers/dashboard/Dashboard';
import PageNotFound from './containers/notfound/PageNotFound';
import Profile from './containers/profile/Profile';
// import setAuthToken from "./domain/setAuthToken";

import AddClient from './containers/clients/AddClient';
import AllClients from './containers/clients/AllClients';
import ViewClient from './containers/clients/ViewClient';
import EditClient from './containers/clients/EditClient';

import AddEmployee from './containers/employees/AddEmployee';
import AllEmployees from './containers/employees/AllEmployees';
import ViewEmployee from './containers/employees/ViewEmployee';
import EditEmployee from './containers/employees/EditEmployee';

import AddStatus from './containers/statuss/AddStatus';
import AllStatuss from './containers/statuss/AllStatuss';
import ViewStatus from './containers/statuss/ViewStatus';
import EditStatus from './containers/statuss/EditStatus';

import AddActivity from './containers/activitys/AddActivity';
import AllActivitys from './containers/activitys/AllActivitys';
import ViewActivity from './containers/activitys/ViewActivity';
import EditActivity from './containers/activitys/EditActivity';
import BulkAddActivity from './containers/activitys/BulkAddActivity';

import AddBill from './containers/bills/AddBill';
import AllBills from './containers/bills/AllBills';
import ViewBill from './containers/bills/ViewBill';
import EditBill from './containers/bills/EditBill';

import AddProcess from './containers/processs/AddProcess';
import AllProcesss from './containers/processs/AllProcesss';
import ViewProcess from './containers/processs/ViewProcess';
import EditProcess from './containers/processs/EditProcess';

import AddBankaccount from './containers/bankaccounts/AddBankaccount';
import AllBankaccounts from './containers/bankaccounts/AllBankaccounts';
import ViewBankaccount from './containers/bankaccounts/ViewBankaccount';
import EditBankaccount from './containers/bankaccounts/EditBankaccount';

import AddActivitystatus from './containers/activitystatuss/AddActivitystatus';
import AllActivitystatuss from './containers/activitystatuss/AllActivitystatuss';
import ViewActivitystatus from './containers/activitystatuss/ViewActivitystatus';
import EditActivitystatus from './containers/activitystatuss/EditActivitystatus';

import AddReviewstatus from './containers/reviewstatuss/AddReviewstatus';
import AllReviewstatuss from './containers/reviewstatuss/AllReviewstatuss';
import ViewReviewstatus from './containers/reviewstatuss/ViewReviewstatus';
import EditReviewstatus from './containers/reviewstatuss/EditReviewstatus';

import AddLead from './containers/leads/AddLead';
import AllLeads from './containers/leads/AllLeads';
import ViewLead from './containers/leads/ViewLead';
import EditLead from './containers/leads/EditLead';

import AddGroup from './containers/groups/AddGroup';
import AllGroups from './containers/groups/AllGroups';
import ViewGroup from './containers/groups/ViewGroup';
import EditGroup from './containers/groups/EditGroup';

import AddRemark from './containers/remarks/AddRemark';
import AllRemarks from './containers/remarks/AllRemarks';
import ViewRemark from './containers/remarks/ViewRemark';
import EditRemark from './containers/remarks/EditRemark';

import AddServicepackage from './containers/servicepackages/AddServicepackage';
import AllServicepackages from './containers/servicepackages/AllServicepackages';
import ViewServicepackage from './containers/servicepackages/ViewServicepackage';
import EditServicepackage from './containers/servicepackages/EditServicepackage';

import AddBillreview from './containers/billreviews/AddBillreview';
import AllBillreviews from './containers/billreviews/AllBillreviews';
import ViewBillreview from './containers/billreviews/ViewBillreview';
import EditBillreview from './containers/billreviews/EditBillreview';

import AddAd from './containers/ads/AddAd';
import AllAds from './containers/ads/AllAds';
import ViewAd from './containers/ads/ViewAd';
import EditAd from './containers/ads/EditAd';
import ImoortCSV from './containers/ads/ImoortCSV';

import AddActivitycycle from './containers/activitycycles/AddActivitycycle';
import AllActivitycycles from './containers/activitycycles/AllActivitycycles';
import ViewActivitycycle from './containers/activitycycles/ViewActivitycycle';
import EditActivitycycle from './containers/activitycycles/EditActivitycycle';

import AllAdsreports from './containers/adsreports/AllAdsreports';
import AllFacebookAdsreports from './containers/adsreports/AllFacebookReports';
import UploadGoogleReport from './containers/adsreports/UploadGoogleReport';
import UploadFacebookReport from './containers/adsreports/UploadFacebookReport';

import AddEmployeereport from './containers/employeereports/AddEmployeereport';
import AllEmployeereports from './containers/employeereports/AllEmployeereports';
import ViewEmployeereport from './containers/employeereports/ViewEmployeereport';
import EditEmployeereport from './containers/employeereports/EditEmployeereport';
import AddCalendar from './containers/activitycycles/AddCalendar';
import Event from './containers/events/Event';
import MyProfile from './containers/employeereports/MyProfile';
import { AdsDashboard } from './containers/adsdashboard/AdsDashboard';
import ClientsAds from './containers/clientsads/ClientsAds';
import SingleClientAds from './containers/clientsads/SingleClientAds';
import ClientAdsModel from './containers/clientsads/ClientAdsModel';

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path='/' component={Login} />
          <PrivateRoutes exact path='/dashboard' component={Dashboard} />
          <PrivateRoutes exact path='/ads-dashboard' component={AdsDashboard} />
          <PrivateRoutes exact path='/profile' component={Profile} />

          <PrivateRoutes exact path='/clients' component={AllClients} />
          <PrivateRoutes exact path='/clients/add' component={AddClient} />
          <PrivateRoutes
            exact
            path='/clients/:id/view'
            component={ViewClient}
          />
          <PrivateRoutes
            exact
            path='/clients/:id/edit'
            component={EditClient}
          />

          <PrivateRoutes exact path='/employees' component={AllEmployees} />
          <PrivateRoutes exact path='/employees/add' component={AddEmployee} />
          <PrivateRoutes
            exact
            path='/employees/:id/view'
            component={ViewEmployee}
          />
          <PrivateRoutes
            exact
            path='/employees/:id/edit'
            component={EditEmployee}
          />

          <PrivateRoutes exact path='/statuss' component={AllStatuss} />
          <PrivateRoutes exact path='/statuss/add' component={AddStatus} />
          <PrivateRoutes
            exact
            path='/statuss/:id/view'
            component={ViewStatus}
          />
          <PrivateRoutes
            exact
            path='/statuss/:id/edit'
            component={EditStatus}
          />

          <PrivateRoutes exact path='/activitys' component={AllActivitys} />
          <PrivateRoutes
            exact
            path='/activitys/bulk-add'
            component={BulkAddActivity}
          />
          <PrivateRoutes exact path='/activitys/add' component={AddActivity} />
          <PrivateRoutes
            exact
            path='/activitys/:id/view'
            component={ViewActivity}
          />
          <PrivateRoutes
            exact
            path='/activitys/:id/edit'
            component={EditActivity}
          />

          <PrivateRoutes exact path='/bills' component={AllBills} />
          <PrivateRoutes exact path='/bills/add' component={AddBill} />
          <PrivateRoutes exact path='/bills/:id/view' component={ViewBill} />
          <PrivateRoutes exact path='/bills/:id/edit' component={EditBill} />

          <PrivateRoutes exact path='/processs' component={AllProcesss} />
          <PrivateRoutes exact path='/processs/add' component={AddProcess} />
          <PrivateRoutes
            exact
            path='/processs/:id/view'
            component={ViewProcess}
          />
          <PrivateRoutes
            exact
            path='/processs/:id/edit'
            component={EditProcess}
          />
          <PrivateRoutes
            exact
            path='/bankaccounts'
            component={AllBankaccounts}
          />
          <PrivateRoutes
            exact
            path='/bankaccounts/add'
            component={AddBankaccount}
          />
          <PrivateRoutes
            exact
            path='/bankaccounts/:id/view'
            component={ViewBankaccount}
          />
          <PrivateRoutes
            exact
            path='/bankaccounts/:id/edit'
            component={EditBankaccount}
          />
          <PrivateRoutes
            exact
            path='/activitystatuss'
            component={AllActivitystatuss}
          />
          <PrivateRoutes
            exact
            path='/activitystatuss/add'
            component={AddActivitystatus}
          />
          <PrivateRoutes
            exact
            path='/activitystatuss/:id/view'
            component={ViewActivitystatus}
          />
          <PrivateRoutes
            exact
            path='/activitystatuss/:id/edit'
            component={EditActivitystatus}
          />

          <PrivateRoutes
            exact
            path='/reviewstatuss'
            component={AllReviewstatuss}
          />
          <PrivateRoutes
            exact
            path='/reviewstatuss/add'
            component={AddReviewstatus}
          />
          <PrivateRoutes
            exact
            path='/reviewstatuss/:id/view'
            component={ViewReviewstatus}
          />
          <PrivateRoutes
            exact
            path='/reviewstatuss/:id/edit'
            component={EditReviewstatus}
          />

          <PrivateRoutes exact path='/leads' component={AllLeads} />
          <PrivateRoutes exact path='/leads/add' component={AddLead} />
          <PrivateRoutes exact path='/leads/:id/view' component={ViewLead} />
          <PrivateRoutes exact path='/leads/:id/edit' component={EditLead} />

          <PrivateRoutes exact path='/groups' component={AllGroups} />
          <PrivateRoutes exact path='/groups/add' component={AddGroup} />
          <PrivateRoutes exact path='/groups/:id/view' component={ViewGroup} />
          <PrivateRoutes exact path='/groups/:id/edit' component={EditGroup} />

          <PrivateRoutes exact path='/remarks' component={AllRemarks} />
          <PrivateRoutes exact path='/remarks/add' component={AddRemark} />
          <PrivateRoutes
            exact
            path='/remarks/:id/view'
            component={ViewRemark}
          />
          <PrivateRoutes
            exact
            path='/remarks/:id/edit'
            component={EditRemark}
          />
          <PrivateRoutes
            exact
            path='/servicepackages'
            component={AllServicepackages}
          />
          <PrivateRoutes
            exact
            path='/servicepackages/add'
            component={AddServicepackage}
          />
          <PrivateRoutes
            exact
            path='/servicepackages/:id/view'
            component={ViewServicepackage}
          />
          <PrivateRoutes
            exact
            path='/servicepackages/:id/edit'
            component={EditServicepackage}
          />

          <PrivateRoutes exact path='/billreviews' component={AllBillreviews} />
          <PrivateRoutes
            exact
            path='/billreviews/add'
            component={AddBillreview}
          />
          <PrivateRoutes
            exact
            path='/billreviews/:id/view'
            component={ViewBillreview}
          />
          <PrivateRoutes
            exact
            path='/billreviews/:id/edit'
            component={EditBillreview}
          />

          <PrivateRoutes exact path='/ads' component={AllAds} />
          <PrivateRoutes exact path='/import-csv' component={ImoortCSV} />
          <PrivateRoutes exact path='/ads/add' component={AddAd} />
          <PrivateRoutes exact path='/ads/:id/view' component={ViewAd} />
          <PrivateRoutes exact path='/ads/:id/edit' component={EditAd} />

          <PrivateRoutes
            exact
            path='/activity-cycles'
            component={AllActivitycycles}
          />
          <PrivateRoutes
            exact
            path='/activity-cycles/add-calendar'
            component={AddCalendar}
          />
          <PrivateRoutes
            exact
            path='/activity-cycles/add'
            component={AddActivitycycle}
          />
          <PrivateRoutes
            exact
            path='/activity-cycles/:id/view'
            component={ViewActivitycycle}
          />
          <PrivateRoutes
            exact
            path='/activity-cycles/:id/edit'
            component={EditActivitycycle}
          />
          <PrivateRoutes exact path='/adsreports' component={AllAdsreports} />
          <PrivateRoutes
            exact
            path='/adsreports-facebook'
            component={AllFacebookAdsreports}
          />

          <PrivateRoutes
            exact
            path='/adsreports/upload-google-report'
            component={UploadGoogleReport}
          />
          <PrivateRoutes
            exact
            path='/adsreports/upload-facebook-report'
            component={UploadFacebookReport}
          />

          <PrivateRoutes
            exact
            path='/employeereports'
            component={AllEmployeereports}
          />
          <Route exact path='/my-profile' component={MyProfile} />
          <PrivateRoutes
            exact
            path='/employeereports/add'
            component={AddEmployeereport}
          />
          <PrivateRoutes
            exact
            path='/employeereports/:id/view'
            component={ViewEmployeereport}
          />
          <PrivateRoutes
            exact
            path='/employeereports/:id/edit'
            component={EditEmployeereport}
          />
          <PrivateRoutes exact path='/events' component={Event} />
          <PrivateRoutes exact path='/clients-ads' component={ClientsAds} />
          <PrivateRoutes
            exact
            path='/clients-ads/:id'
            component={SingleClientAds}
          />
          <PrivateRoutes
            exact
            path='/client-ads-model'
            component={ClientAdsModel}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
