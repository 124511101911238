import React from 'react'

function EmployeeOfMonth({ image, image_2, name, short_desc }) {
    return (
        <div>
            <div className="employee-section">
                <img src={image} alt="" />
                <hr />
                <div className="employee-section-title">
                    <h6>Employee Of the Month: {name}</h6>
                    <span>
                        <p></p>
                    </span>

                    <h6>
                        {short_desc}
                    </h6>
                </div>
            </div>
        </div>
    )
}

export default EmployeeOfMonth