import React, { useState } from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { SelectBox, TextInput, CheckBox } from '../../components/Form/Form';
import ClientAdsModel from './ClientAdsModel';
import { useCreateAdClient } from '../../shared/hooks/UseClient';
import moment from 'moment';
import { useGetAdsActivities } from '../../shared/hooks/UseActivity';
import { CampaignComponent } from '../../components/ads/CampaignComponent';
import { LandingPageComponent } from '../../components/ads/LandingPageComponent';
import { CreativeComponent } from '../../components/ads/CreativeComponent';
import Skeleton from 'react-loading-skeleton';
import { ReviewComponent } from '../../components/ads/ReviewComponent';
import AddReviewModel from './AddReviewModel';
import { set } from 'lodash';
import { useAddAdReview } from '../../shared/hooks/UseAdsReview';
import { useGetDropdownOptions } from '../../shared/hooks/UseActivity';
import { AddAdsActivityComponent } from '../../components/ads/AddAdsActivityComponent';

const SingleClientAds = ({ match }) => {
  const { clients_data } = useCreateAdClient(match.params.id);
  const { addAdReviewHandler } = useAddAdReview();
  const {
    campaigns_loading,
    total_campaigns,
    campaigns,
    campaign_page,
    campaigns_pages,

    landing_pages_loading,
    total_landing_pages,
    landing_pages,
    landing_page_page,
    landing_pages_pages,

    creatives_loading,
    total_creatives,
    creatives,
    creative_page,
    creatives_pages,

    add_ads_client_activity_loading,
    add_ads_client_activity,
  } = useGetAdsActivities(match.params.id);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const { ads_client } = clients_data;
  console.log('Ads  Client', ads_client);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [openAddReviewModel, setOpenAddReviewModal] = useState(false);
  const addReviewModalHandler = (activity) => {
    setSelectedCampaign(activity);
    setOpenAddReviewModal(true);
  };
  console.log('AD Review Model', openAddReviewModel);

  const [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
    setActivitycycleSearchField,
    setActivitycycleSearchValue,
  ] = useGetDropdownOptions();

  console.log('DropDown Options ', dropdownOptions);
  return (
    <>
      <Header />
      <BreadCrumb />
      <FilterDateComponent link={`/clients-ads/${match.params.id}`} />
      <section>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='single-client-card'>
                <div className='single-client-card-content'>
                  <h4 className='mb-4'>Client Information</h4>
                  <div className='single-client-card-info d-flex '>
                    <h6>Client Name:</h6>
                    <span>{ads_client?.name}</span>
                  </div>
                  <div className='single-client-card-info d-flex '>
                    <h6>Facebook Id:</h6>
                    <span> {ads_client?.facebook_account_id} </span>
                  </div>
                  <div className='single-client-card-info d-flex '>
                    <h6>Google Id:</h6>
                    <span>{ads_client?.google_account_id}</span>
                  </div>
                  <div className='single-client-card-info d-flex '>
                    <h6>Created Date:</h6>
                    <span>
                      {ads_client?.createdAt &&
                        moment(ads_client?.createdAt).format('DD-MM-YYYY')}
                    </span>
                  </div>
                  <div className='single-client-card-info d-flex '>
                    <h6>Account Manager:</h6>
                    <span>Test</span>
                  </div>
                  <div className='single-client-card-info d-flex '>
                    <h6>Ads Manager:</h6>
                    <span>Test</span>
                  </div>
                  <div className='single-client-card-info d-flex '>
                    <h6>Client Status:</h6>
                    <span>{ads_client?.status?.name}</span>
                  </div>
                </div>
              </div>
              <div className='col-md-4'></div>
            </div>
            <div className='col-md-4'>
              <AddAdsActivityComponent
                dropdownOptions={dropdownOptions}
                client={match.params.id}
              />
            </div>
            <div className='col-md-4'>
              <div className='d-flex gap-2'>
                <div className='card'>
                  <div className='card-header'>Campaigns</div>
                  <div className='card-body'>
                    <div className='card-title'>
                      {campaigns_loading ? <Skeleton /> : total_campaigns}
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>Landing Pages</div>
                  <div className='card-body'>
                    <div className='card-title'>
                      {landing_pages_loading ? (
                        <Skeleton />
                      ) : (
                        total_landing_pages
                      )}
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>Ads Creatives </div>
                  <div className='card-body'>
                    <div className='card-title'>
                      {creatives_loading ? <Skeleton /> : total_creatives}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='single-client-ads-checklist'>
                <h4 className=' mb-4'>Checklist:</h4>
                <Formik
                  initialValues={{
                    name: '',
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);

                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <Form>
                        <div className='row'>
                          <div className='col-md-12'>
                            <CheckBox name='name' type='checkbox'>
                              Test
                            </CheckBox>
                          </div>
                          <div className='col-md-12'>
                            <CheckBox name='name' type='checkbox'>
                              Test
                            </CheckBox>
                          </div>
                          <div className='col-md-12'>
                            <CheckBox name='name' type='checkbox'>
                              Test
                            </CheckBox>
                          </div>
                          <div className='col-md-12'>
                            <CheckBox name='name' type='checkbox'>
                              Test
                            </CheckBox>
                          </div>
                          <div className='col-md-12'>
                            <CheckBox name='name' type='checkbox'>
                              Test
                            </CheckBox>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div> */}
            </div>
            <CampaignComponent
              campaigns_loading={campaigns_loading}
              total_campaigns={total_campaigns}
              campaigns={campaigns}
              campaign_page={campaign_page}
              campaigns_pages={campaigns_pages}
              add_ads_client_activity_loading={add_ads_client_activity_loading}
              add_ads_client_activity={add_ads_client_activity}
              client={match.params.id}
              addReviewModalHandler={addReviewModalHandler}
              dropdownOptions={dropdownOptions}
            />
            <ReviewComponent
              client={match.params.id}
              selectedCampaign={selectedCampaign}
              setSelectedCampaign={setSelectedCampaign}
              dropdownOptions={dropdownOptions}
            />
            <LandingPageComponent
              landing_pages_loading={landing_pages_loading}
              total_landing_pages={total_landing_pages}
              landing_pages={landing_pages}
              landing_page_page={landing_page_page}
              landing_pages_pages={landing_pages_pages}
              add_ads_client_activity_loading={add_ads_client_activity_loading}
              add_ads_client_activity={add_ads_client_activity}
              client={match.params.id}
              dropdownOptions={dropdownOptions}
            />
            <CreativeComponent
              creatives_loading={creatives_loading}
              total_creatives={total_creatives}
              creatives={creatives}
              creative_page={creative_page}
              creatives_pages={creatives_pages}
              add_ads_client_activity_loading={add_ads_client_activity_loading}
              add_ads_client_activity={add_ads_client_activity}
              client={match.params.id}
              dropdownOptions={dropdownOptions}
            />

            {modal && <ClientAdsModel />}
            {openAddReviewModel && (
              <AddReviewModel
                toggleModal={openAddReviewModel}
                setToggleModal={setOpenAddReviewModal}
                handleFormSubmit={addAdReviewHandler}
                client={match.params.id}
                campaign={selectedCampaign}
                dropdownOptions={dropdownOptions}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleClientAds;
