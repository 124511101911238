export const GET_BILLS_STATED = "GET_BILLS_STATED";
export const GET_BILLS = "GET_BILLS";
export const GET_BILLS_ENDED = "GET_BILLS_ENDED";
export const ADD_BILL_STATED = "ADD_BILL_STARTED";
export const ADD_BILL = "ADD_BILL";
export const ADD_BILL_ENDED = "ADD_BILL_ENDED";
export const EDIT_BILL_STATED = "EDIT_BILL_STATED";
export const EDIT_BILL = "EDIT_BILL";
export const EDIT_BILL_ENDED = "EDIT_BILL_ENDED";
export const GET_BILL = "GET_BILL";
export const GET_BILL_STATED = "GET_BILL_STATED";
export const GET_BILL_ENDED = "GET_BILL_ENDED";
export const RESET_BILL = "RESET_BILL";
export const ERROR_BILL = "ERROR_BILL";
export const GET_ALL_BILLS_STATED = "GET_ALL_BILLS_STATED";
export const GET_ALL_BILLS = "GET_ALL_BILLS";
export const GET_ALL_BILLS_ENDED = "GET_ALL_BILLS_ENDED";
