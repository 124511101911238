import {
  GET_BILLS_STATED,
  GET_BILLS,
  GET_BILLS_ENDED,
  ADD_BILL_STATED,
  ADD_BILL,
  ADD_BILL_ENDED,
  EDIT_BILL_STATED,
  EDIT_BILL,
  EDIT_BILL_ENDED,
  GET_BILL_STATED,
  GET_BILL,
  GET_BILL_ENDED,
  GET_ALL_BILLS_STATED,
  GET_ALL_BILLS,
  GET_ALL_BILLS_ENDED
} from "../types/bill_type";

const initialState = {
  bills_loading: true,
  bills: null,
  page: null,
  pages: null,
  total_bills: 0,

  bill: null,
  bill_loading: null,

  loading: true,

  bill_message: null,
  all_bills: null,
  all_bills_loading: null,
  add_bill_loading: true,
  edit_bill_loading: true
};

export const bill_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BILLS_STATED:
      return {
        ...state,
        bills: null,
        pages: null,
        page: null,
        total_bills: 0,
        bills_loading: true
      };
    case GET_BILLS:
      return {
        ...state,
        bills: payload.bills,
        pages: payload.pages,
        page: payload.page,
        total_bills: payload.count
      };
    case GET_BILLS_ENDED:
      return {
        ...state,
        bills_loading: false
      };
    case GET_ALL_BILLS_STATED:
      return {
        ...state,
        all_bills_loading: true,
        all_bills: null
      };
    case GET_ALL_BILLS:
      return {
        ...state,
        all_bills: payload
      };
    case GET_ALL_BILLS_ENDED:
      return {
        ...state,
        all_bills_loading: false
      };

    case ADD_BILL_STATED:
      return {
        ...state,
        bill_message: null,
        add_bill_loading: true
      };
    case ADD_BILL:
      return {
        ...state,
        bill_message: payload
      };
    case ADD_BILL_ENDED:
      return {
        ...state,
        add_bill_loading: false
      };
    case GET_BILL_STATED:
      return {
        ...state,
        bill: null,
        bill_loading: true
      };
    case GET_BILL:
      return {
        ...state,
        bill: payload
      };
    case GET_BILL_ENDED:
      return {
        ...state,
        bill_loading: false
      };
    case EDIT_BILL_STATED:
      return {
        ...state,
        bill_message: null,
        edit_bill_loading: true
      };
    case EDIT_BILL:
      return {
        ...state,
        bill_message: payload
      };
    case EDIT_BILL_ENDED:
      return {
        ...state,
        edit_bill_loading: false
      };

    default:
      return state;
  }
};
