import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL
} from "../../shared/enums/activitystatuss_enum";
import { useUpdateActivitystatus, useSingleActivitystatus,useGetDropdownOptions } from "../../shared/hooks/UseActivitystatus";
// import { useSelectAllActivitystatus } from "../../shared/hooks/UseActivitystatus";

const EditActivitystatus = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleActivitystatus(match.params.id);
  const [updateData] = useUpdateActivitystatus();
  const { activitystatus_loading, activitystatus, edit_activitystatus_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  
  

  const [dropdownOptions] = useGetDropdownOptions();
  const submitFormClicked = async values => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(activitystatus._id, data);
    history.push(`/${LINK_URL}/${activitystatus._id}/view`);
  };

  

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!activitystatus_loading ? (
              activitystatus && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {activitystatus[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${activitystatus._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={activitystatus}
                    edit={true}
                    featuredImage={featuredImage}
                    setFeaturedImage={setFeaturedImage}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_activitystatus_loading}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditActivitystatus;
