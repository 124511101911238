export const quotes = {
    "quote": [
        {
            "content": "Focus on being productive instead of busy. --Tim Ferriss"
        },
        {
            "content": "The key is not to prioritize what's on your schedule, but to schedule your priorities. --Stephen Covey"
        },
        {
            "content": "Productivity is being able to do things that you were never able to do before. --Franz Kafka"
        },
        {
            "content": "Until we can manage time, we can manage nothing else. --Peter Drucker"
        },
        {
            "content": "Great acts are made up of small deeds. --Lao Tzu"
        },
        {
            "content": "Simplicity boils down to two steps: Identify the essential. Eliminate the rest. --Leo Babauta"
        },
        {
            "content": "Work hard, have fun, and make history. --Jeff Bezos"
        },
    ]
}