import { combineReducers } from 'redux';
import alert from './alert_reducer';
import auth from './auth_reducer';
import { client_reducer } from './client_reducer';
import { employee_reducer } from './employee_reducer';
import { status_reducer } from './status_reducer';
import { activity_reducer } from './activity_reducer';
import { bill_reducer } from './bill_reducer';
import { process_reducer } from './process_reducer';
import { bankaccount_reducer } from './bankaccount_reducer';
import { activitystatus_reducer } from './activitystatus_reducer';
import { dashboard_reducer } from './dashboard_reducer';
import { reviewstatus_reducer } from './reviewstatus_reducer';
import { lead_reducer } from './lead_reducer';
import { group_reducer } from './group_reducer';
import { remark_reducer } from './remark_reducer';
import { servicepackage_reducer } from './servicepackage_reducer';
import { billreview_reducer } from './billreview_reducer';
import { comment_reducer } from './comment_reducer';
import { ad_reducer } from './ad_reducer';
import { activitycycle_reducer } from './activitycycle_reducer';
import { leadcomment_reducer } from './leadcomment_reducer';
import { adsreport_reducer } from './adsreport_reducer';
import { employeereport_reducer } from './employeereport_reducer';
import { notification_reducer } from './notification_reducer';
import { ads_review_reducer } from './ads_review_reducer';

export default combineReducers({
  alert,
  auth,
  client: client_reducer,
  employee: employee_reducer,
  status: status_reducer,
  activity: activity_reducer,
  bill: bill_reducer,
  process: process_reducer,
  bankaccount: bankaccount_reducer,
  activitystatus: activitystatus_reducer,
  dashboard: dashboard_reducer,
  reviewstatus: reviewstatus_reducer,
  lead: lead_reducer,
  group: group_reducer,
  remark: remark_reducer,
  servicepackage: servicepackage_reducer,
  billreview: billreview_reducer,
  comment: comment_reducer,
  ad: ad_reducer,
  activitycycle: activitycycle_reducer,
  leadcomment: leadcomment_reducer,
  adsreport: adsreport_reducer,
  employeereport: employeereport_reducer,
  notification: notification_reducer,
  ads_review: ads_review_reducer,
});
