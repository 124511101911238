import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/clients_enum";
import {
  useCreateClient,
  useGetDropdownOptions,
} from "../../shared/hooks/UseClient";

import AddClientForm from "../../components/clientform/AddClientForm";

// import { useSelectAllClient } from "../../shared/hooks/UseClient";
// import { useSelectAllStatus } from "../../shared/hooks/UseStatus"

const AddClient = ({}) => {
  let history = useHistory();
  const [client, addData] = useCreateClient();
  const { add_client_loading } = client;
  const [featuredImage, setFeaturedImage] = useState(null);
  // const [status] = useSelectAllStatus();

  // const [dropdownOptions2, setDropdownOptions2] = useState({});

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  const [dropdownOptions] = useGetDropdownOptions();
  // const loadOptions = async (inputValue, callback, field) => {
  //   if (field == "poc") {
  //     callback(dropdownOptions.poc);
  //   }
  //   if (field == "account_manager") {
  //     callback(dropdownOptions.account_manager);
  //   }
  //   if (field == "ads_manager") {
  //     callback(dropdownOptions.ads_manager);
  //   }
  // };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <AddClientForm
          edit={false}
          featuredImage={featuredImage}
          setFeaturedImage={setFeaturedImage}
          submitForm={submitFormClicked}
          inputFields={inputFields}
          initialValues={initialValues}
          dropdown_options={dropdownOptions}
          loading={add_client_loading}
          PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
          // loadOptions={loadOptions}
        />
      </div>
    </div>
  );
};

export default AddClient;
