import api from "../../domain/api";
import {
  GET_ACTIVITYSTATUSS_STATED,
  GET_ACTIVITYSTATUSS,
  GET_ACTIVITYSTATUSS_ENDED,
  ADD_ACTIVITYSTATUS_STATED,
  ADD_ACTIVITYSTATUS,
  ADD_ACTIVITYSTATUS_ENDED,
  EDIT_ACTIVITYSTATUS_STATED,
  EDIT_ACTIVITYSTATUS,
  EDIT_ACTIVITYSTATUS_ENDED,
  GET_ACTIVITYSTATUS_STATED,
  GET_ACTIVITYSTATUS,
  GET_ACTIVITYSTATUS_ENDED,
  GET_ALL_ACTIVITYSTATUSS_STATED,
  GET_ALL_ACTIVITYSTATUSS,
  GET_ALL_ACTIVITYSTATUSS_ENDED,
} from "../types/activitystatus_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addActivitystatus = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ACTIVITYSTATUS_STATED,
    });
    const { data } = await api.post(`/activitystatuss`, formData);
    dispatch({
      type: ADD_ACTIVITYSTATUS,
      payload: data,
    });
    dispatch({
      type: ADD_ACTIVITYSTATUS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_ACTIVITYSTATUS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getActivitystatuss =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ACTIVITYSTATUSS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/activitystatuss?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_ACTIVITYSTATUSS,
        payload: data,
      });
      dispatch({
        type: GET_ACTIVITYSTATUSS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ACTIVITYSTATUSS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getActivitystatus = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACTIVITYSTATUS_STATED,
    });
    const { data } = await api.get(`/activitystatuss/${id}`);

    dispatch({
      type: GET_ACTIVITYSTATUS,
      payload: data,
    });
    dispatch({
      type: GET_ACTIVITYSTATUS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ACTIVITYSTATUS_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editActivitystatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_ACTIVITYSTATUS_STATED,
    });
    const { data } = await api.put(`/activitystatuss/${id}`, formData);
    dispatch({
      type: EDIT_ACTIVITYSTATUS,
      payload: data,
    });
    dispatch({
      type: EDIT_ACTIVITYSTATUS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_ACTIVITYSTATUS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteActivitystatus = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/activitystatuss/${id}`);
    dispatch(setAlert("Activitystatus Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllActivitystatuss = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_ACTIVITYSTATUSS_STATED,
    });
    const { data } = await api.get(`/activitystatuss/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_ACTIVITYSTATUSS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_ACTIVITYSTATUSS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ACTIVITYSTATUSS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
