import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/activitys_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleActivity } from "../../shared/hooks/UseActivity";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import renderHTML from "react-render-html";
import moment from "moment";
import { CheckBox, SelectBox, TextArea, TextInput } from "../../components/Form/Form";
import { URI } from "../../domain/constant";
import { useSelectAllProcess } from "../../shared/hooks/UseProcess"



const ViewActivity = ({ match, hideGallery }) => {
  const [data] = useSingleActivity(match.params.id);
  const { activity_loading, activity } = data;
  console.log("ACTIVITY", activity);

  const [process_data] = useSelectAllProcess()
  const { all_processs } = process_data
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {
          !activity_loading ? (
            activity && (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-header">
                        <div>
                          <h4 className="card-title">
                            {activity.task_name}
                            <span>View</span>
                          </h4>
                          <p className="card-title-desc">

                            <Link to={`/activitys`} className="btn btn-soft-light">
                              <i className="fa fa-angle-left"></i> Activity
                            </Link>

                            {
                              <Link
                                to={`/activitys/${activity._id}/edit`}
                                className="btn btn-soft-light"
                              >
                                <i className="fa fa-edit"></i>
                              </Link>
                            }
                          </p>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Name</div>
                              <div className="card-body-received-data-main">
                                {activity.task_name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Assigned To</div>
                              <div className="card-body-received-data-main">
                                {activity.assigned_to && activity.assigned_to.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Activity Cycle</div>
                              <div className="card-body-received-data-main">
                                {activity.activity_cycle && activity.activity_cycle._id}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Due Time</div>
                              <div className="card-body-received-data-main">
                                {moment(activity.due_time).format('MM/DD/YYYY h:mm:ss a')}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Client Name</div>
                              <div className="card-body-received-data-main">
                                {activity.client && activity.client.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Process</div>
                              <div className="card-body-received-data-main">
                                {activity.process && activity.process.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Estimate Time</div>
                              <div className="card-body-received-data-main">
                                {activity.estimated_time && activity.estimated_time}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Actual Time</div>
                              <div className="card-body-received-data-main">
                                {activity.actual_time && activity.actual_time}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Task Description</div>
                              <div className="card-body-received-data-main">
                                {activity.task_description && renderHTML(activity.task_description)}
                              </div>
                            </div>
                          </div>
                          {
                            <div className="col-md-6">
                              <div className="card-body-single-data">
                                <div className="title">Attachment</div>
                                <div className="card-body-received-data-main">
                                  {activity.attachments && activity.attachments.map((item) => {
                                    return (
                                      <>
                                        {!hideGallery &&
                                          (
                                            <a
                                              href={`${URI}${item.image}`}
                                              target={"_blank"}
                                            >
                                              {" "}
                                              View{" "}
                                            </a>
                                          )}
                                      </>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          }
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Review Status</div>
                              <div className="card-body-received-data-main">
                                {activity.review_status && activity.review_status.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Activity Status</div>
                              <div className="card-body-received-data-main">
                                {activity.activity_status && activity.activity_status.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Comment</div>
                              <div className="card-body-received-data-main">
                                {activity.comment && activity.comment}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Created By</div>
                              <div className="card-body-received-data-main">
                                {activity.created_by && activity.created_by.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Created At</div>
                              <div className="card-body-received-data-main">
                                {moment(activity.createdAt).format('MM/DD/YYYY h:mm:ss a')}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Updated At</div>
                              <div className="card-body-received-data-main">
                                {moment(activity.updatedAt).format('MM/DD/YYYY h:mm:ss a')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-side">
                          <h4>Checklist:</h4>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="card-body-single-data">
                          <div className="card-body-received-datalist">
                            {
                              activity.checklist
                              && activity.checklist
                                .map((item, index) => {
                                  return (

                                    <div className="card-body-received-data">
                                      <div className="checklist-data-content">
                                        {
                                          item && item.is_checked ?
                                            < div className="d-flex">
                                              <div className="checklist-data-list-img">
                                                <input type="checkbox" checked disabled />
                                              </div>
                                              <div className="checklist-data-content">
                                                {renderHTML(item.name)}
                                              </div>
                                            </div> : < div className="d-flex">
                                              <div className="checklist-data-list-img">
                                                <input type="checkbox" disabled />
                                              </div>
                                              <div className="checklist-data-content">
                                                {renderHTML(item.name)}
                                              </div>
                                            </div>
                                        }
                                      </div>
                                    </div>
                                  )
                                })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-side">
                          <h4>SOP:</h4>
                          <p>Standard Operating Procedure</p>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="card-body-single-data">
                          <div className="card-body-received-datalist">
                            {
                              activity.process && activity.process.sop && activity.process.sop.map((item) => {
                                return (
                                  <div className="card-body-received-data d-flex pb-3">
                                    <div className="checklist-data-list-img">
                                      <img src="/images/sop_3.png" alt="" />
                                    </div>
                                    <div className="checklist-data-content">
                                      {renderHTML(item.name)}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div>
              <Spinner />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ViewActivity;
