import api from "../../domain/api";
import {
  GET_BILLS_STATED,
  GET_BILLS,
  GET_BILLS_ENDED,
  ADD_BILL_STATED,
  ADD_BILL,
  ADD_BILL_ENDED,
  EDIT_BILL_STATED,
  EDIT_BILL,
  EDIT_BILL_ENDED,
  GET_BILL_STATED,
  GET_BILL,
  GET_BILL_ENDED,
  GET_ALL_BILLS_STATED,
  GET_ALL_BILLS,
  GET_ALL_BILLS_ENDED,
} from "../types/bill_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addBill = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_BILL_STATED,
    });
    const { data } = await api.post(`/bills`, formData);
    dispatch({
      type: ADD_BILL,
      payload: data,
    });
    dispatch({
      type: ADD_BILL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_BILL_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getBills =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_BILLS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/bills?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_BILLS,
        payload: data,
      });
      dispatch({
        type: GET_BILLS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_BILLS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getBill = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BILL_STATED,
    });
    const { data } = await api.get(`/bills/${id}`);

    dispatch({
      type: GET_BILL,
      payload: data,
    });
    dispatch({
      type: GET_BILL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_BILL_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editBill = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BILL_STATED,
    });
    const { data } = await api.put(`/bills/${id}`, formData);
    dispatch({
      type: EDIT_BILL,
      payload: data,
    });
    dispatch({
      type: EDIT_BILL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_BILL_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteBill = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/bills/${id}`);
    dispatch(setAlert("Bill Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllBills = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BILLS_STATED,
    });
    const { data } = await api.get(`/bills/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_BILLS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_BILLS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BILLS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
