import api from "../../domain/api";
import {
  GET_STATUSS_STATED,
  GET_STATUSS,
  GET_STATUSS_ENDED,
  ADD_STATUS_STATED,
  ADD_STATUS,
  ADD_STATUS_ENDED,
  EDIT_STATUS_STATED,
  EDIT_STATUS,
  EDIT_STATUS_ENDED,
  GET_STATUS_STATED,
  GET_STATUS,
  GET_STATUS_ENDED,
  GET_ALL_STATUSS_STATED,
  GET_ALL_STATUSS,
  GET_ALL_STATUSS_ENDED,
} from "../types/status_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addStatus = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_STATUS_STATED,
    });
    const { data } = await api.post(`/statuss`, formData);
    dispatch({
      type: ADD_STATUS,
      payload: data,
    });
    dispatch({
      type: ADD_STATUS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_STATUS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getStatuss =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_STATUSS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/statuss?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_STATUSS,
        payload: data,
      });
      dispatch({
        type: GET_STATUSS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_STATUSS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getStatus = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STATUS_STATED,
    });
    const { data } = await api.get(`/statuss/${id}`);

    dispatch({
      type: GET_STATUS,
      payload: data,
    });
    dispatch({
      type: GET_STATUS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_STATUS_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editStatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_STATUS_STATED,
    });
    const { data } = await api.put(`/statuss/${id}`, formData);
    dispatch({
      type: EDIT_STATUS,
      payload: data,
    });
    dispatch({
      type: EDIT_STATUS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_STATUS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteStatus = (id) => async (dispatch) => {
  try {
    console.log("deleteStatus", id);
    const { data } = await api.delete(`/statuss/${id}`);
    dispatch(setAlert("Status Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllStatuss =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_STATUSS_STATED,
      });
      const { data } = await api.get(
        `/statuss/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_STATUSS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_STATUSS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_STATUSS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
