import api from "../../domain/api";
import {
  GET_ADS_STATED,
  GET_ADS,
  GET_ADS_ENDED,
  ADD_AD_STATED,
  ADD_AD,
  ADD_AD_ENDED,
  EDIT_AD_STATED,
  EDIT_AD,
  EDIT_AD_ENDED,
  GET_AD_STATED,
  GET_AD,
  GET_AD_ENDED,
  GET_ALL_ADS_STATED,
  GET_ALL_ADS,
  GET_ALL_ADS_ENDED,
} from "../types/ad_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addAd = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_AD_STATED,
    });
    const { data } = await api.post(`/ads`, formData);
    dispatch({
      type: ADD_AD,
      payload: data,
    });
    dispatch({
      type: ADD_AD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_AD_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const bulkUpload = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_AD_STATED,
    });
    const { data } = await api.post(`/ads/import-report`, formData);
    dispatch({
      type: ADD_AD,
      payload: data,
    });
    dispatch({
      type: ADD_AD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_AD_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAds =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ADS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/ads?&pageNumber=${pageNumber}&${query}`);

      dispatch({
        type: GET_ADS,
        payload: data,
      });
      dispatch({
        type: GET_ADS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ADS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getAd = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AD_STATED,
    });
    const { data } = await api.get(`/ads/${id}`);

    dispatch({
      type: GET_AD,
      payload: data,
    });
    dispatch({
      type: GET_AD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_AD_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editAd = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_AD_STATED,
    });
    const { data } = await api.put(`/ads/${id}`, formData);
    dispatch({
      type: EDIT_AD,
      payload: data,
    });
    dispatch({
      type: EDIT_AD_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_AD_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteAd = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/ads/${id}`);
    dispatch(setAlert("Ad Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllAds =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_ADS_STATED,
      });
      const { data } = await api.get(`/ads/all?term=${term}&value=${value}`);

      dispatch({
        type: GET_ALL_ADS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_ADS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_ADS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
