import React from 'react'

function Snow() {
    return (
        <div>
            <div>
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
                <div className="snow" />
            </div>

        </div>
    )
}

export default Snow