import React, { useState, useEffect } from 'react'
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header"
import { convertToFormData } from "../../shared/upload";
import { useHistory } from "react-router-dom";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { HTMLInput, SelectBox, TextInput, TextArea } from "../../components/Form/Form";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import AddForm from "../../components/common/AddForm";
import {
    useCreateActivitycycle,
    useGetDropdownOptions,
    useCreateActivitycycleCalendar
} from "../../shared/hooks/UseActivitycycle";
import DatePicker from "react-multi-date-picker";


function AddCalendar() {
    let history = useHistory();

    const [activitycycle, addData] = useCreateActivitycycleCalendar();
    const { add_activitycycle_loading } = activitycycle;
    const [dropdownOptions, loadOptions] = useGetDropdownOptions();
    console.log(dropdownOptions);
    const [value, setValue] = useState([]);
    // console.log("VALUE", value.format());
    const [formatedDate, setFormatedDate] = useState([]);
    const [featuredImage, setFeaturedImage] = useState(null);
    useEffect(() => {
        if (value) {
            const mappedArray = value.map((item) => item.format())
            setFormatedDate(mappedArray)
        }
    }, [value])
    console.log("formatedDate", formatedDate);
    const submitFormClicked = async (values) => {
        const data = await convertToFormData({ values, featuredImage });
        await addData(data);
        history.push(`/activity-cycles`);
    };


    return (
        <div>
            <div>
                <Header />
                <BreadCrumb
                    title={`Add Calendar`}
                    mainLinkTitle={`Calendar`}
                    mainLinkUrl={`Calendar`}
                    activeLink="Add"
                />
            </div>

            <div className="container-fluid">
                <div className="col-lg-9">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title"> {`Calendar`} </h4>
                            <p className="card-title-desc">
                                Enter Details to add {`Calendar`}
                            </p>
                        </div>

                        <div className="card-body">
                            <Formik
                                initialValues={{
                                    name: "",
                                    due_time: "",
                                    client: "",
                                    description: "",
                                    process: "",
                                    status: "",
                                    activities: {
                                        task_name: "",
                                        assigned_to: "",
                                        estimate_time: "",
                                        review_status: "",
                                        due_time_formatted: [],
                                        activity_status: "",
                                    },

                                }}
                                validationSchema={Yup.object({
                                    name: Yup.string().required("Required"),
                                    due_time: Yup.string().required("Required"),
                                    client: Yup.string().required("Required"),
                                    description: Yup.string().required("Required"),
                                    process: Yup.string().required("Required"),
                                    status: Yup.string().required("Required"),

                                })}
                                onSubmit={async (
                                    values,
                                    { setSubmitting, resetForm }
                                ) => {
                                    setSubmitting(true);
                                    const mappedArray = values.activities.due_time_formatted.map((item) => item.format())
                                    values.activities.due_time = mappedArray;
                                    submitFormClicked(values)
                                    resetForm();
                                    setSubmitting(false);
                                }}
                            >
                                {(formik) => {
                                    console.log(formik);
                                    return (
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <TextInput
                                                        label="Name"
                                                        name="name"
                                                        type="text"
                                                        placeholder="Enter Name"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <TextInput
                                                        label="Due Time"
                                                        name="due_time"
                                                        type="datetime-local"
                                                        placeholder="Enter Due Time"
                                                    />
                                                </div>


                                                <div className="col-md-6">
                                                    <SelectBox
                                                        label="Client"
                                                        name="client"
                                                        placeholder="Enter Client">
                                                        <option value=""> Select Client </option>
                                                        {dropdownOptions && dropdownOptions.clients && dropdownOptions.clients.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.label}</option>
                                                            )
                                                        })}

                                                    </SelectBox>
                                                </div>

                                                <div className="col-md-6">
                                                    <TextArea
                                                        label="Description"
                                                        name="description"
                                                        type="text-area"
                                                        placeholder="description"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <SelectBox
                                                        label="Process"
                                                        name="process"
                                                        placeholder="Enter Process">
                                                        <option value=""> Select Process </option>
                                                        {dropdownOptions && dropdownOptions.process && dropdownOptions.process.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.label}</option>
                                                            )
                                                        })}

                                                    </SelectBox>
                                                </div>
                                                <div className="col-md-6">
                                                    <SelectBox
                                                        label="Status"
                                                        name="status"
                                                        placeholder="Enter Status">
                                                        <option value=""> Select  </option>
                                                        <option value="PENDING"> PENDING </option>
                                                        <option value="COMPLETED"> COMPLETED </option>



                                                    </SelectBox>
                                                </div>
                                                <div className="col-md-12" >
                                                    <div className="p-2 mt-2 mb-2 bg-light">
                                                        <p className="font-weight-bold">
                                                            Activities
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <TextInput
                                                        label="Task Name"
                                                        name="activities.task_name"
                                                        type="text"
                                                        placeholder="Enter Task Name"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <SelectBox
                                                        label="Assigned to"
                                                        name="activities.assigned_to"
                                                        placeholder="Enter Assigned to">
                                                        <option value=""> Select Assigned to </option>
                                                        {dropdownOptions && dropdownOptions.assigned_to && dropdownOptions.assigned_to.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.label}</option>
                                                            )
                                                        })}

                                                    </SelectBox>
                                                </div>


                                                <div className="col-md-6">
                                                    <label > Due Time </label> <br />
                                                    <DatePicker value={formik.values.activities.due_time_formatted} onChange={(e) => formik.setFieldValue("activities.due_time_formatted", e)} multiple={true} />
                                                </div>

                                                <div className="col-md-6">
                                                    <TextInput
                                                        label="Estimate Time"
                                                        name="activities.estimate_time"
                                                        type="number"
                                                        placeholder="Enter Estimate Time"
                                                    />
                                                </div>


                                                <div className="col-md-6">
                                                    <SelectBox
                                                        label="Review Status"
                                                        name="activities.review_status"
                                                        placeholder="Enter Review Status">
                                                        <option value=""> Select Review Status </option>
                                                        {dropdownOptions && dropdownOptions.review_status && dropdownOptions.review_status.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.label}</option>
                                                            )
                                                        })}
                                                    </SelectBox>
                                                </div>
                                                <div className="col-md-6">
                                                    <SelectBox
                                                        label="Activity Status"
                                                        name="activities.activity_status"
                                                        placeholder="Enter Activity Status">
                                                        <option value=""> Select Activity Status </option>
                                                        {dropdownOptions && dropdownOptions.activity_status && dropdownOptions.activity_status.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.label}</option>
                                                            )
                                                        })}
                                                    </SelectBox>
                                                </div>




                                                <div className="">
                                                    <button type="submit" className="btn btn-success">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>

                                        </Form>
                                    );
                                }}
                            </Formik></div>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddCalendar