export const GET_ACTIVITYSTATUSS_STATED = "GET_ACTIVITYSTATUSS_STATED";
export const GET_ACTIVITYSTATUSS = "GET_ACTIVITYSTATUSS";
export const GET_ACTIVITYSTATUSS_ENDED = "GET_ACTIVITYSTATUSS_ENDED";
export const ADD_ACTIVITYSTATUS_STATED = "ADD_ACTIVITYSTATUS_STARTED";
export const ADD_ACTIVITYSTATUS = "ADD_ACTIVITYSTATUS";
export const ADD_ACTIVITYSTATUS_ENDED = "ADD_ACTIVITYSTATUS_ENDED";
export const EDIT_ACTIVITYSTATUS_STATED = "EDIT_ACTIVITYSTATUS_STATED";
export const EDIT_ACTIVITYSTATUS = "EDIT_ACTIVITYSTATUS";
export const EDIT_ACTIVITYSTATUS_ENDED = "EDIT_ACTIVITYSTATUS_ENDED";
export const GET_ACTIVITYSTATUS = "GET_ACTIVITYSTATUS";
export const GET_ACTIVITYSTATUS_STATED = "GET_ACTIVITYSTATUS_STATED";
export const GET_ACTIVITYSTATUS_ENDED = "GET_ACTIVITYSTATUS_ENDED";
export const RESET_ACTIVITYSTATUS = "RESET_ACTIVITYSTATUS";
export const ERROR_ACTIVITYSTATUS = "ERROR_ACTIVITYSTATUS";
export const GET_ALL_ACTIVITYSTATUSS_STATED = "GET_ALL_ACTIVITYSTATUSS_STATED";
export const GET_ALL_ACTIVITYSTATUSS = "GET_ALL_ACTIVITYSTATUSS";
export const GET_ALL_ACTIVITYSTATUSS_ENDED = "GET_ALL_ACTIVITYSTATUSS_ENDED";
