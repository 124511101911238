import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/leads_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleLead } from "../../shared/hooks/UseLead";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { SelectBox, TextArea, TextInput } from "../../components/Form/Form";

const ViewLead = ({ match }) => {
  const [data] = useSingleLead(match.params.id);
  const { lead_loading, lead } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!lead_loading ? (
          lead && (
            <SingleView
              data={lead}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={lead._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewLead;
