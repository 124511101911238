export const GET_LEADS_STATED = "GET_LEADS_STATED";
export const GET_LEADS = "GET_LEADS";
export const GET_LEADS_ENDED = "GET_LEADS_ENDED";
export const ADD_LEAD_STATED = "ADD_LEAD_STARTED";
export const ADD_LEAD = "ADD_LEAD";
export const ADD_LEAD_ENDED = "ADD_LEAD_ENDED";
export const EDIT_LEAD_STATED = "EDIT_LEAD_STATED";
export const EDIT_LEAD = "EDIT_LEAD";
export const EDIT_LEAD_ENDED = "EDIT_LEAD_ENDED";
export const GET_LEAD = "GET_LEAD";
export const GET_LEAD_STATED = "GET_LEAD_STATED";
export const GET_LEAD_ENDED = "GET_LEAD_ENDED";
export const RESET_LEAD = "RESET_LEAD";
export const ERROR_LEAD = "ERROR_LEAD";
export const GET_ALL_LEADS_STATED = "GET_ALL_LEADS_STATED";
export const GET_ALL_LEADS = "GET_ALL_LEADS";
export const GET_ALL_LEADS_ENDED = "GET_ALL_LEADS_ENDED";
