export const GET_SERVICEPACKAGES_STATED = "GET_SERVICEPACKAGES_STATED";
export const GET_SERVICEPACKAGES = "GET_SERVICEPACKAGES";
export const GET_SERVICEPACKAGES_ENDED = "GET_SERVICEPACKAGES_ENDED";
export const ADD_SERVICEPACKAGE_STATED = "ADD_SERVICEPACKAGE_STARTED";
export const ADD_SERVICEPACKAGE = "ADD_SERVICEPACKAGE";
export const ADD_SERVICEPACKAGE_ENDED = "ADD_SERVICEPACKAGE_ENDED";
export const EDIT_SERVICEPACKAGE_STATED = "EDIT_SERVICEPACKAGE_STATED";
export const EDIT_SERVICEPACKAGE = "EDIT_SERVICEPACKAGE";
export const EDIT_SERVICEPACKAGE_ENDED = "EDIT_SERVICEPACKAGE_ENDED";
export const GET_SERVICEPACKAGE = "GET_SERVICEPACKAGE";
export const GET_SERVICEPACKAGE_STATED = "GET_SERVICEPACKAGE_STATED";
export const GET_SERVICEPACKAGE_ENDED = "GET_SERVICEPACKAGE_ENDED";
export const RESET_SERVICEPACKAGE = "RESET_SERVICEPACKAGE";
export const ERROR_SERVICEPACKAGE = "ERROR_SERVICEPACKAGE";
export const GET_ALL_SERVICEPACKAGES_STATED = "GET_ALL_SERVICEPACKAGES_STATED";
export const GET_ALL_SERVICEPACKAGES = "GET_ALL_SERVICEPACKAGES";
export const GET_ALL_SERVICEPACKAGES_ENDED = "GET_ALL_SERVICEPACKAGES_ENDED";
