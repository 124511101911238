import React, { useState } from 'react';
import { ADS_ACTIVITY_ENUM, INPUT_TYPE_ENUM } from '../../domain/constant';
import { useUpdateAdReview } from '../../shared/hooks/UseAdsReview';

export const EditReviewFieldComponent = ({
  value,
  field,
  element_type,
  input_type = 'text',
  options,
  activity,
  selected_value,
  styles,
  setToggleModal,
}) => {
  const { updateAdsReviewHandler } = useUpdateAdReview();
  const [term, setTerm] = useState(selected_value ? selected_value : value);
  const [is_editing, setIsEditing] = useState(false);
  const submitHandler = () => {
    // formSubmitHandler({ field, value: term });
    activity[field] = term;
    updateAdsReviewHandler(activity._id, activity);
    setIsEditing(false);
    if (setToggleModal) {
      setToggleModal(false);
    }
  };
  return (
    <>
      {!is_editing ? (
        <div
          style={{
            ...styles,
            cursor: 'pointer',
            padding: '5px',
            borderRadius: '5px',
          }}
          onClick={() => setIsEditing(true)}
        >
          {value}
        </div>
      ) : (
        <div className='d-flex'>
          <div>
            {element_type === INPUT_TYPE_ENUM.INPUT ? (
              <input
                className='form-control'
                value={term}
                type={input_type}
                onChange={(e) => setTerm(e.target.value)}
              />
            ) : element_type === INPUT_TYPE_ENUM.SELECT ? (
              <select
                className='form-control'
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              >
                <option value=''></option>
                {options &&
                  options.map((option) => {
                    return <option value={option.value}>{option.label}</option>;
                  })}
              </select>
            ) : element_type === INPUT_TYPE_ENUM.TEXTAREA ? (
              <textarea
                className='form-control'
                onChange={(e) => setTerm(e.target.value)}
              >
                {term}
              </textarea>
            ) : null}
          </div>
          <div className='d-flex'>
            <div>
              <button className='btn btn-sm' onClick={() => submitHandler()}>
                <i className='fa fa-check'></i>
              </button>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => setIsEditing(false)}
              >
                <i className='fa fa-times'></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
