export const GET_ACTIVITYCYCLES_STATED = "GET_ACTIVITYCYCLES_STATED";
export const GET_ACTIVITYCYCLES = "GET_ACTIVITYCYCLES";
export const GET_ACTIVITYCYCLES_ENDED = "GET_ACTIVITYCYCLES_ENDED";
export const ADD_ACTIVITYCYCLE_STATED = "ADD_ACTIVITYCYCLE_STARTED";
export const ADD_ACTIVITYCYCLE = "ADD_ACTIVITYCYCLE";
export const ADD_ACTIVITYCYCLE_ENDED = "ADD_ACTIVITYCYCLE_ENDED";
export const EDIT_ACTIVITYCYCLE_STATED = "EDIT_ACTIVITYCYCLE_STATED";
export const EDIT_ACTIVITYCYCLE = "EDIT_ACTIVITYCYCLE";
export const EDIT_ACTIVITYCYCLE_ENDED = "EDIT_ACTIVITYCYCLE_ENDED";
export const GET_ACTIVITYCYCLE = "GET_ACTIVITYCYCLE";
export const GET_ACTIVITYCYCLE_STATED = "GET_ACTIVITYCYCLE_STATED";
export const GET_ACTIVITYCYCLE_ENDED = "GET_ACTIVITYCYCLE_ENDED";
export const RESET_ACTIVITYCYCLE = "RESET_ACTIVITYCYCLE";
export const ERROR_ACTIVITYCYCLE = "ERROR_ACTIVITYCYCLE";
export const GET_ALL_ACTIVITYCYCLES_STATED = "GET_ALL_ACTIVITYCYCLES_STATED";
export const GET_ALL_ACTIVITYCYCLES = "GET_ALL_ACTIVITYCYCLES";
export const GET_ALL_ACTIVITYCYCLES_ENDED = "GET_ALL_ACTIVITYCYCLES_ENDED";
