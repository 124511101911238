import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addActivitycycle,
  getActivitycycles,
  getActivitycycle,
  editActivitycycle,
  deleteActivitycycle,
  getAllActivitycycles,
  addActivitycycleCalendar
} from "../../store/actions/activitycycle_action";
import _debounce from "lodash/debounce";

import { useSelectAllEmployee } from "../hooks/UseEmployee";
import { useSelectAllClient } from "../hooks/UseClient";
import { useSelectAllProcess } from "../hooks/UseProcess";
import { useSelectAllActivitystatus } from "../hooks/UseActivitystatus";
import { useSelectAllReviewstatus } from "../hooks/UseReviewstatus";

// Get All Data
export const useAllActivitycycles = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activitycycle);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteActivitycycle(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getActivitycycles({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (reloadData) {
      allQuery();
      setReloadData(false);
    }
  }, [reloadData]);
  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData];
};

// Get Single Data
export const useSingleActivitycycle = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activitycycle);
  useEffect(() => {
    dispatch(getActivitycycle(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateActivitycycle = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activitycycle);
  const addData = async (data) => {
    await dispatch(addActivitycycle(data));
  };
  return [data, addData];
};
export const useUpdateActivitycycle = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.activitycycle);
  const updateData = async (id, data) => {
    await dispatch(editActivitycycle(id, data));
  };
  return [updateData];
};
// Add Data
export const useCreateActivitycycleCalendar = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.activitycycle);
  const addData = async (data) => {
    await dispatch(addActivitycycleCalendar(data));
  };
  return [data, addData];
};


export const useSelectAllActivitycycle = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.activitycycle);
  useEffect(() => {
    dispatch(getAllActivitycycles({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [employee, setEmployeeSearchField, setEmployeeSearchValue] =
    useSelectAllEmployee();
  const [client, setClientSearchField, setClientSearchValue] =
    useSelectAllClient();
  const [process, setProcessSearchField, setProcessSearchValue] =
    useSelectAllProcess();
  const [
    activitystatus,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
  ] = useSelectAllActivitystatus();
  const [reviewstatus, setReviewstatusSearchField, setReviewstatusSearchValue] =
    useSelectAllReviewstatus();
  // const [employee] = useSelectAllEmployee();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        assigned_to: newData,
        activities: { assigned_to: newData },
      });
    }
  }, [employee]);

  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        client: newData,
        clients: newData,
      });
    }
  }, [client]);

  useEffect(() => {
    if (process && process.all_processs) {
      const newData = process.all_processs.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, process: newData });
    }
  }, [process]);

  useEffect(() => {
    if (activitystatus && activitystatus.all_activitystatuss) {
      const newData = activitystatus.all_activitystatuss.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        activity_status: newData,
        activities: { activity_status: newData },
      });
    }
  }, [activitystatus]);
  useEffect(() => {
    if (reviewstatus && reviewstatus.all_reviewstatuss) {
      const newData = reviewstatus.all_reviewstatuss.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        review_status: newData,
        activities: { review_status: newData },
      });
    }
  }, [reviewstatus]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == "client") {
      setClientSearchField("name");
      setClientSearchValue(inputValue);
      callback(dropdownOptions.client);
    }
    if (field == "process") {
      setProcessSearchField("name");
      setProcessSearchValue(inputValue);
      callback(dropdownOptions.process);
    }
  };

  return [dropdownOptions, loadOptions];
};
