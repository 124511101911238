export const GET_ADSREPORTS_STATED = 'GET_ADSREPORTS_STATED';
export const GET_ADSREPORTS = 'GET_ADSREPORTS';
export const GET_ADSREPORTS_ENDED = 'GET_ADSREPORTS_ENDED';
export const ADD_ADSREPORT_STATED = 'ADD_ADSREPORT_STARTED';
export const ADD_ADSREPORT = 'ADD_ADSREPORT';
export const ADD_ADSREPORT_ENDED = 'ADD_ADSREPORT_ENDED';
export const EDIT_ADSREPORT_STATED = 'EDIT_ADSREPORT_STATED';
export const EDIT_ADSREPORT = 'EDIT_ADSREPORT';
export const EDIT_ADSREPORT_ENDED = 'EDIT_ADSREPORT_ENDED';
export const GET_ADSREPORT = 'GET_ADSREPORT';
export const GET_ADSREPORT_STATED = 'GET_ADSREPORT_STATED';
export const GET_ADSREPORT_ENDED = 'GET_ADSREPORT_ENDED';
export const RESET_ADSREPORT = 'RESET_ADSREPORT';
export const ERROR_ADSREPORT = 'ERROR_ADSREPORT';
export const GET_ALL_ADSREPORTS_STATED = 'GET_ALL_ADSREPORTS_STATED';
export const GET_ALL_ADSREPORTS = 'GET_ALL_ADSREPORTS';
export const GET_ALL_ADSREPORTS_ENDED = 'GET_ALL_ADSREPORTS_ENDED';
// Facebook
export const GET_FB_ADS_REPORTS_STARTED = 'GET_FB_ADS_REPORTS_STARTED';
export const GET_FB_ADS_REPORTS = 'GET_FB_ADS_REPORTS';
export const GET_FB_ADS_REPORTS_ENDED = 'GET_FB_ADS_REPORTS_ENDED';
