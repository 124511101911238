import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/activitycycles_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleActivitycycle } from "../../shared/hooks/UseActivitycycle";
import {
  useAllActivitys,
  useAllActivitysByCycle,
} from "../../shared/hooks/UseActivity";
import {
  useAllActivitycycles,
  useGetDropdownOptions,
  useUpdateActivitycycle,
} from "../../shared/hooks/UseActivitycycle";
import { LIMIT } from "../../domain/constant";
import moment from "moment";
import { Link } from "react-router-dom";





const ViewActivitycycle = ({ match }) => {
  const [data] = useSingleActivitycycle(match.params.id);
  const { activitycycle_loading, activitycycle } = data;


  const [activity_data, setAcvtivityCycle, setReloadActivity] =
    useAllActivitysByCycle();

  const { activitys, activitys_loading } = activity_data;

  const [showtotalEstimateTime, setShowtotalEstimateTime] = useState(0)

  useEffect(() => {
    if (activitys && activitys.length > 0) {
      let totalEstimateActivitycycleTime = activitys && activitys.map((item) => Number(item.estimated_time)).reduce((accum, curr) => { return (accum + curr) })
      setShowtotalEstimateTime(totalEstimateActivitycycleTime)
    } else {
      setShowtotalEstimateTime(0)
    }
  }, [activitys])

  const [data_activity_cycle, setPageNumber, deleteBtnClicked, reloadData, setReloadData] =
    useAllActivitycycles();

  const {
    activitycycles_loading,
    activitycycles,
    total_activitycycles,
    page,
    pages,
  } = data_activity_cycle;

  const [editId, setEditId] = useState(null);
  const [editField, setEditField] = useState(null);

  const [newValues, setNewValues] = useState(null);


  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!activitycycle_loading ? (
          activitycycle && (
            <SingleView
              data={activitycycle}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={activitycycle._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
        <div className="col-md-6">
          <div style={{ borderBottom: '1px solid rgb(241, 241, 241)', paddingBottom: 5, paddingTop: 5, paddingLeft: 25 }}>
            <div className="title">
              Total Estimate Time
            </div>
            <div style={{ marginBottom: 10, fontWeight: 500, fontSize: 16 }}>
              {showtotalEstimateTime} mins
            </div>
          </div>
        </div>


        <div className="card">
          <div className="card-body">
            <table className="table table-stripped">
              <thead>
                <tr>
                  <th>#</th>
                  <th> Task Name </th>
                  <th> Assigned To </th>
                  <th> Due Time </th>
                  <th> Estimate Time </th>
                  <th> Client </th>
                  <th> Process </th>
                  <th> Activity Status </th>
                  <th> Review Status </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!activitys_loading ? (
                  <>
                    {activitys &&
                      activitys.map((single, index) => {
                        return (
                          <tr>
                            <td> {index + 1 + (page - 1) * LIMIT} </td>
                            <td> {single.task_name}

                              <a
                                onClick={() => {
                                  setEditField("task_name");
                                  setEditId(single._id);
                                  setNewValues({ task_name: single.task_name })
                                }}
                              >
                                {" "}
                                <i className="fa fa-edit"></i>{" "}
                              </a>
                            </td>
                            <td>
                              {single.assigned_to &&
                                single.assigned_to.name}
                            </td>
                            <td>
                              {single.due_time &&
                                moment(single.due_time)
                                  .utc()
                                  .format("DD-MM-YYYY hh:mm A")}
                            </td>
                            <td>{single.estimated_time}</td>
                            <td>
                              {" "}
                              {single.client && single.client.name}{" "}
                            </td>
                            <td>
                              {single.process && single.process.name}
                            </td>
                            <td>
                              {single.activity_status &&
                                single.activity_status.name}
                            </td>
                            <td>
                              {single.review_status &&
                                single.review_status.name}
                            </td>
                            <td>
                              <Link
                                to={`/activitys/${single._id}/view`}
                                className="btn btn-sm btn-success"
                              >
                                {" "}
                                <i className="fa fa-eye"></i>{" "}
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <p> Loading... </p>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ViewActivitycycle;
