import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  HTMLInput,
  SelectBox,
  TextArea,
  TextInput,
} from '../../components/Form/Form';
import { useGetDropdownOptions } from '../../shared/hooks/UseActivity';
import { ADS_PLATFORM_TYPES, REVIEW_STATUS } from '../../domain/constant';
const AddReviewModel = ({
  toggleModal,
  handleFormSubmit,
  setToggleModal,
  client,
  campaign,
  dropdownOptions,
}) => {
  return (
    <>
      {toggleModal && (
        <section
          className='client-ads-model '
          style={{ overflow: 'scroll' }}
          id='client-model'
        >
          <div className='client-modal-form'>
            <div className='client-modal-form-top d-flex justify-content-between'>
              <h4 className='mb-4 '>Create Review</h4>
              <div className='client-modal-close-btn'>
                <i
                  class='fa fa-times'
                  aria-hidden='true'
                  onClick={() => setToggleModal(false)}
                ></i>
              </div>
            </div>

            <Formik
              initialValues={{
                review_title: '',
                notes: '',
                status: '',
                assigned_to: '',
              }}
              validationSchema={Yup.object({
                review_title: Yup.string().required('Required'),
                notes: Yup.string().required('Required'),
                status: Yup.string().required('Required'),
                assigned_to: Yup.string().required('Required'),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                values.client = client;
                values.campaign = campaign;
                handleFormSubmit(values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                console.log(formik);
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-6'>
                        <TextInput
                          label='Review Title'
                          name='review_title'
                          type='text'
                        />
                      </div>
                      <div className='col-md-6'>
                        <SelectBox label='Status' name='status'>
                          <option value=''>Select </option>
                          {REVIEW_STATUS &&
                            REVIEW_STATUS.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </SelectBox>
                      </div>
                      <div className='col-md-6'>
                        <SelectBox label='Assigned To' name='assigned_to'>
                          <option value=''> Select Assigned to </option>
                          {dropdownOptions.assigned_to &&
                            dropdownOptions.assigned_to.map((item) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            })}
                        </SelectBox>
                      </div>

                      <div className='col-md-12'>
                        <TextArea
                          label={'Notes'}
                          name='notes'
                          value={formik.values.notes}
                        />
                      </div>
                      <div className='client-modal-form-btn text-center'>
                        <button className='btn btn-success m-1' type='submit'>
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </section>
      )}
    </>
  );
};

export default AddReviewModel;
