import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import AddBtn from "../../components/common/AddBtn";
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/activitys_enum";
import DataTable from "../../components/common/DataTable";
import {
  useAllActivitysByEmployee,
  useGetDropdownOptions,
  useUpdateActivity,
} from "../../shared/hooks/UseActivity";

import {
  useAllComments,
  useCreateComment,
} from "../../shared/hooks/UseComment";

import SidebarFilter from "../../components/common/SidebarFilter";
import { LIMIT } from "../../domain/constant";
import moment from "moment";
import ReactModal from "react-modal";
import SingleView from "../../components/common/SingleView";
import Spinner from "../../components/layout/Spinner";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextArea, TextInput } from "../../components/Form/Form";
import { Link } from "react-router-dom";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";
import ExportComponent from "../../components/common/ExportComponent";
import userEvent from "@testing-library/user-event";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import * as qs from "qs";

const AllActivityComponent = ({ employee }) => {
  const [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData] =
    useAllActivitysByEmployee(employee._id);
  const [updateData] = useUpdateActivity();

  const {
    activitys_loading,
    activitys,
    total_activitys,
    activities_status_array,
    page,
    pages,
  } = data;

  const [comment_data, setActivity, setReloadComments] = useAllComments();
  const { comments, comments_loading } = comment_data;
  const [commentData, addData] = useCreateComment();
  const submitFormClicked = async (values) => {
    // const data = await convertToFormData({ values, featuredImage });
    await addData(values);
    setReloadComments(true);

    // history.push(`/${LINK_URL}`);
  };

  const [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
    setActivitycycleSearchField,
    setActivitycycleSearchValue,
  ] = useGetDropdownOptions();

  const loadOptions = async (inputValue, callback, field) => {
    if (field == "assigned_to") {
      setEmployeeSearchField("name");
      setEmployeeSearchValue(inputValue);
      callback(dropdownOptions.assigned_to);
    }
    if (field == "client") {
      setClientSearchField("name");
      setClientSearchValue(inputValue);
      callback(dropdownOptions.client);
    }
    if (field == "process") {
      setProcessSearchField("name");
      setProcessSearchValue(inputValue);
      callback(dropdownOptions.process);
    }
    if (field == "activity_status") {
      setActivitystatusSearchField("name");
      setActivitystatusSearchValue(inputValue);
      callback(dropdownOptions.activity_status);
    }
    if (field == "review_status") {
      setReviewstatusSearchField("name");
      setReviewstatusSearchValue(inputValue);
      callback(dropdownOptions.review_status);
    }
    if (field == "activity_cycle") {
      setActivitycycleSearchField("name");
      setActivitycycleSearchValue(inputValue);
      callback(dropdownOptions.activity_cycle);
    }
  };

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editField, setEditField] = useState(null);

  const [newStatus, setNewStatus] = useState(null);
  const [newValues, setNewValues] = useState(null);
  const [loadingActivityStatus, setLoadingActivityStatus] = useState(false);

  const [isOverdue, setIsOverdue] = useState(false);

  const updateStatus = async (id, status) => {
    // console.log("ID", id, value);
    if (id && status) {
      setLoadingActivityStatus(true);
      const newForm = new FormData();
      newForm.append("values", JSON.stringify(status));
      await updateData(id, newForm);
      await setReloadData(true);
      setEditId(null);
      setLoadingActivityStatus(false);
    } else {
      setEditId(null);
    }
  };

  console.log("comments", comments);

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (activitys) {
      convertToReadable(activitys, inputFields);
    }
  }, [activitys]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Activities", "activities");
  };

  const userData = useSelector((state) => state.auth);
  const { user } = userData;
  var currentDate = moment().format("DD-MM-YYYY hh:mm A");

  console.log("Current Date", currentDate);
  const [total_estimated_time, setTotal_estimated_time] = useState(0);
  const [total_actual_time, setTotal_actual_time] = useState(0);

  useEffect(() => {
    if (activitys) {
      let total_activity_time = 0;
      let total_actual_time = 0;
      activitys.map((item) => {
        if (item.estimated_time) {
          total_activity_time =
            total_activity_time +
            Number(item.estimated_time ? item.estimated_time : 0);
          total_actual_time =
            total_actual_time + Number(item.actual_time ? item.actual_time : 0);
        }
      });
      setTotal_estimated_time(total_activity_time);
      setTotal_actual_time(total_actual_time);
    }
  }, [activitys]);
  console.log("TOTAL ESTIMATED TIME", total_estimated_time);

  const time_convert = (num) => {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return hours + "Hours :" + minutes + " Mins";
  };

  const history = useHistory();
  const location = useLocation();
  const [selectedFields, setSelectedFields] = useState({});

  const handleSidebarChange = ({ name, value, type = "search", condition }) => {
    const queryParams = qs.parse(window.location.search.replace("?", ""));
    if (type == "conditional") {
      if (queryParams[type]) {
        if (queryParams[type][name]) {
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : "$gte"] = value;
          } else {
            delete queryParams[type][name][condition ? condition : "$gte"];
          }
        } else {
          queryParams[type][name] = {};
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : "$gte"] = value;
          } else {
            delete queryParams[type][name][condition ? condition : "$gte"];
          }
        }
      } else {
        queryParams[type] = {};
        queryParams[type][name] = {};
        if (value && value.length > 0) {
          queryParams[type][name][condition ? condition : "$gte"] = value;
        } else {
          delete queryParams[type][name][condition ? condition : "$gte"];
        }
      }
    } else {
      if (queryParams[type]) {
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      } else {
        queryParams[type] = {};
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      }
    }

    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    // console.log("PARAMS", params.toString());
    // console.log("QUERY", `${location.pathname}?${query}`);
    history.push(`${location.pathname}?${query}`);
  };

  console.log(dropdownOptions && dropdownOptions.activity_status);
  return (
    <div className="pace-done">
      <div>
        <ExportComponent handleOnExport={handleOnExport} />
        <FilterDateComponent
          link={`/employees/${employee._id}/view`}
          searchField="due_time"
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                    </div>
                    {dropdownOptions &&
                      dropdownOptions.activity_status &&
                      dropdownOptions.activity_status.map((item) => {
                        return (
                          <div>
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-title">
                                  <a
                                    onClick={() =>
                                      handleSidebarChange({
                                        name: "activity_status",
                                        value: item.value,
                                        type: item.search_type
                                          ? item.search_type
                                          : "exact",
                                      })
                                    }
                                  >
                                    {item.label}
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div>
                      <Link
                        className="btn btn-danger"
                        to={`/activitys?conditional[due_time][$lt]=${moment().format(
                          "YYYY-MM-DD"
                        )}&exact[activity_status]=62b2a7abdb1f21d46398f0b8`}
                      >
                        Overdue Tasks
                      </Link>
                    </div>
                    <div>
                      {" "}
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Total Estimated Time </h4>
                          {time_convert(total_estimated_time)}
                        </div>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Total Actual Time </h4>
                          {time_convert(total_actual_time)}
                        </div>
                      </div>
                    </div>

                    <div style={{ textAlign: "right" }}>
                      <Link
                        to={"/activitys/bulk-add"}
                        className="btn btn-secondary"
                      >
                        {" "}
                        Bulk Add{" "}
                      </Link>
                    </div>
                  </div>
                  {total_activitys} records found
                  <div>
                    <table className="table table-new align-middle table-striped  table-bordered">
                      {!activitys_loading && (
                        <thead>
                          <tr className="bg-transparent" role="row">
                            <th style={{ width: "50px" }}>#</th>
                            <th> Task name </th>
                            <th> Assigned To </th>
                            <th> Due Time </th>
                            <th> Estimate Time </th>
                            <th> Actual Time </th>
                            <th> Client </th>
                            <th> Process </th>
                            <th> Activity Cycle </th>
                            <th> Activity Status </th>
                            <th> Review Status </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      )}

                      <tbody>
                        {!activitys_loading ? (
                          activitys &&
                          activitys.map((single, index) => {
                            if (editId && editId == single._id) {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT} </td>

                                  <td>
                                    {editField == "task_name" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input
                                              className="form-control"
                                              value={
                                                newValues && newValues.task_name
                                              }
                                              onChange={(e) =>
                                                setNewValues({
                                                  task_name: e.target.value,
                                                })
                                              }
                                            />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.task_name
                                    )}
                                  </td>
                                  <td>
                                    {single.assigned_to &&
                                      single.assigned_to.name}

                                    {editField == "assigned_to" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  assigned_to: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.assigned_to &&
                                                dropdownOptions.assigned_to.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.assigned_to &&
                                                          single.assigned_to
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.assigned_to &&
                                      single.assigned_to.name
                                    )}
                                  </td>
                                  <td>
                                    {single.due_time &&
                                      moment(single.due_time)
                                        .utc()
                                        .format("DD-MM-YYYY hh:mm A")}

                                    {editField == "due_time" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input
                                              className="form-control"
                                              value={
                                                newValues && newValues.due_time
                                              }
                                              type="datetime-local"
                                              onChange={(e) =>
                                                setNewValues({
                                                  due_time: e.target.value,
                                                })
                                              }
                                            />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.due_time
                                    )}
                                  </td>
                                  <td>
                                    {editField == "estimated_time" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input
                                              className="form-control"
                                              value={
                                                newValues &&
                                                newValues.estimated_time
                                              }
                                              onChange={(e) =>
                                                setNewValues({
                                                  estimated_time:
                                                    e.target.value,
                                                })
                                              }
                                            />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.estimated_time
                                    )}
                                  </td>
                                  <td>
                                    {editField == "actual_time" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input
                                              className="form-control"
                                              value={
                                                newValues &&
                                                newValues.actual_time
                                              }
                                              onChange={(e) =>
                                                setNewValues({
                                                  actual_time: e.target.value,
                                                })
                                              }
                                            />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.actual_time
                                    )}
                                  </td>
                                  <td>
                                    {editField == "client" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  client: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.client &&
                                                dropdownOptions.client.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.client &&
                                                          single.client._id ==
                                                            item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.activity_status &&
                                      single.activity_status.name
                                    )}
                                  </td>
                                  <td>
                                    {editField == "process" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  process: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.process &&
                                                dropdownOptions.process.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.process &&
                                                          single.process._id ==
                                                            item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.process && single.process.name
                                    )}
                                  </td>
                                  <td>
                                    {editField == "activity_cycle" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  activity_cycle:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.activity_cycle &&
                                                dropdownOptions.activity_cycle.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.activity_cycle &&
                                                          single.activity_cycle
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.activity_cycle &&
                                      single.activity_cycle.name
                                    )}
                                  </td>
                                  <td>
                                    {editField == "activity_status" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  activity_status:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.activity_status &&
                                                dropdownOptions.activity_status.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.activity_status &&
                                                          single.activity_status
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.activity_status &&
                                      single.activity_status.name
                                    )}
                                  </td>
                                  <td>
                                    {editField == "review_status" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  review_status: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.review_status &&
                                                dropdownOptions.review_status.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.review_status &&
                                                          single.review_status
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.review_status &&
                                      single.review_status.name
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        setActivity(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT}</td>
                                  <td>
                                    {" "}
                                    {single.task_name}
                                    <a
                                      onClick={() => {
                                        setEditField("task_name");
                                        setEditId(single._id);
                                        setNewValues({
                                          task_name: single.task_name,
                                        });
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.assigned_to &&
                                      single.assigned_to.name}
                                    <a
                                      onClick={() => {
                                        setEditField("assigned_to");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.due_time &&
                                      moment(single.due_time)
                                        .utc()
                                        .format("DD-MM-YYYY hh:mm A")}

                                    {single.activity_status &&
                                    single.activity_status.name !==
                                      "COMPLETED" &&
                                    single.activity_status.name !== "ON-HOLD" &&
                                    moment().diff(
                                      moment(single.due_time).utc(),
                                      "hours"
                                    ) > 0 ? (
                                      <span class="badge bg-danger p-1">
                                        Over Due Task
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    <a
                                      onClick={() => {
                                        setEditField("due_time");
                                        setEditId(single._id);
                                        setNewValues({
                                          due_time: single.due_time,
                                        });
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.estimated_time}
                                    <a
                                      onClick={() => {
                                        setEditField("estimated_time");
                                        setEditId(single._id);
                                        setNewValues({
                                          estimated_time: single.estimated_time,
                                        });
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.actual_time}
                                    <a
                                      onClick={() => {
                                        setEditField("actual_time");
                                        setEditId(single._id);
                                        setNewValues({
                                          actual_time: single.actual_time,
                                        });
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>

                                  <td>
                                    {single.client && single.client.name}
                                    <a
                                      onClick={() => {
                                        setEditField("client");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.process && single.process.name}
                                    <a
                                      onClick={() => {
                                        setEditField("process");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.activity_cycle &&
                                      single.activity_cycle.name}
                                    <a
                                      onClick={() => {
                                        setEditField("activity_cycle");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>

                                  <td>
                                    {" "}
                                    {single.activity_status &&
                                      single.activity_status.name}{" "}
                                    <a
                                      onClick={() => {
                                        setEditField("activity_status");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.review_status &&
                                      single.review_status.name}
                                    <a
                                      onClick={() => {
                                        setEditField("review_status");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        setActivity(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={20} className="text-center">
                              <Spinner />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <Pagination
                      data={activitys}
                      page={page}
                      pages={pages}
                      count={total_activitys}
                      setPage={setPageNumber}
                      loading={activitys_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={() => {
          setModal(false);
        }}
      >
        <div className="quick-view">
          <div className="qv-header">
            <div className="title"> {PAGE_SINGLE_TITLE} </div>
            <div>
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
          {modalData && (
            <>
              <div className="qv-body">
                <SingleView
                  hideGallery={true}
                  data={modalData}
                  inputFields={inputFields}
                  label={PAGE_SINGLE_TITLE}
                  link={LINK_URL}
                  id={modalData._id}
                  hideAllBtn={true}
                  col={12}
                />
                <div className="card">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        comment: "",
                      }}
                      validationSchema={Yup.object({
                        comment: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        values.activity = modalData._id;
                        await submitFormClicked(values);
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row align-items-center">
                              <div className="col-md-9">
                                <TextArea
                                  label="Comment"
                                  name="comment"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  {" "}
                                  {formik.isSubmitting
                                    ? "SAVING..."
                                    : "SAVE"}{" "}
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <table className="table table-stripped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Comment</th>
                          <th>Comment By</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!comments_loading ? (
                          <>
                            {comments &&
                              comments.map((item, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1} </td>
                                    <td> {item.comment} </td>
                                    <td>
                                      {" "}
                                      {item.created_by &&
                                        item.created_by.name}{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      {item.createdAt &&
                                        moment(item.createdAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <p> Loading... </p>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AllActivityComponent;
