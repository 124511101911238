export const GET_REVIEWSTATUSS_STATED = "GET_REVIEWSTATUSS_STATED";
export const GET_REVIEWSTATUSS = "GET_REVIEWSTATUSS";
export const GET_REVIEWSTATUSS_ENDED = "GET_REVIEWSTATUSS_ENDED";
export const ADD_REVIEWSTATUS_STATED = "ADD_REVIEWSTATUS_STARTED";
export const ADD_REVIEWSTATUS = "ADD_REVIEWSTATUS";
export const ADD_REVIEWSTATUS_ENDED = "ADD_REVIEWSTATUS_ENDED";
export const EDIT_REVIEWSTATUS_STATED = "EDIT_REVIEWSTATUS_STATED";
export const EDIT_REVIEWSTATUS = "EDIT_REVIEWSTATUS";
export const EDIT_REVIEWSTATUS_ENDED = "EDIT_REVIEWSTATUS_ENDED";
export const GET_REVIEWSTATUS = "GET_REVIEWSTATUS";
export const GET_REVIEWSTATUS_STATED = "GET_REVIEWSTATUS_STATED";
export const GET_REVIEWSTATUS_ENDED = "GET_REVIEWSTATUS_ENDED";
export const RESET_REVIEWSTATUS = "RESET_REVIEWSTATUS";
export const ERROR_REVIEWSTATUS = "ERROR_REVIEWSTATUS";
export const GET_ALL_REVIEWSTATUSS_STATED = "GET_ALL_REVIEWSTATUSS_STATED";
export const GET_ALL_REVIEWSTATUSS = "GET_ALL_REVIEWSTATUSS";
export const GET_ALL_REVIEWSTATUSS_ENDED = "GET_ALL_REVIEWSTATUSS_ENDED";
