import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/activitys_enum";
import {
  useUpdateActivity,
  useSingleActivity,
  useGetDropdownOptions,
} from "../../shared/hooks/UseActivity";
// import { useSelectAllActivity } from "../../shared/hooks/UseActivity";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { CheckBox, SelectBox, TextArea, TextInput } from "../../components/Form/Form";
import { useSelectAllProcess } from "../../shared/hooks/UseProcess"
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import moment from "moment";



const EditActivity = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleActivity(match.params.id);
  const [updateData] = useUpdateActivity();
  const { activity_loading, activity, edit_activity_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [imagePicked, setImagePicked] = useState(null);
  const pickImage = ({ field, value }) => {
    // console.log("CALLING", field);
    let imagesCollection = imagePicked ? imagePicked : {};
    imagesCollection[field] = value;
    console.log("FILES", imagesCollection);
    setImagePicked(imagesCollection);
    setFeaturedImage(imagesCollection);
  };

  console.log("ACTIVITY -", activity);

  const [process_data] = useSelectAllProcess()
  const { all_processs } = process_data

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(activity._id, data);
    history.push(`/${LINK_URL}/${activity._id}/view`);
  };



  const [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
  ] = useGetDropdownOptions();

  const loadOptions = async (inputValue, callback, field) => {
    if (field == "assigned_to") {
      setEmployeeSearchField("name");
      setEmployeeSearchValue(inputValue);
      callback(dropdownOptions.assigned_to);
    }
    if (field == "client") {
      setClientSearchField("name");
      setClientSearchValue(inputValue);
      callback(dropdownOptions.client);
    }
    if (field == "process") {
      setProcessSearchField("name");
      setProcessSearchValue(inputValue);
      callback(dropdownOptions.process);
    }
    if (field == "activity_status") {
      setActivitystatusSearchField("name");
      setActivitystatusSearchValue(inputValue);
      callback(dropdownOptions.activity_status);
    }
    if (field == "review_status") {
      setReviewstatusSearchField("name");
      setReviewstatusSearchValue(inputValue);
      callback(dropdownOptions.review_status);
    }
  };



  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!activity_loading ? (
              activity && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {activity[Object.keys(inputFields)[0]]} -{" "}
                        <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${activity._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <Formik
                      initialValues={{
                        task_name: activity && activity.task_name ? (activity.task_name) : (""),
                        assigned_to: activity && activity.assigned_to && activity.assigned_to._id ? (activity.assigned_to._id) : (""),
                        activity_cycle: activity && activity.activity_cycle ? (activity.activity_cycle._id) : (""),
                        due_time: activity && activity.due_time ? (moment(activity.due_time).format("yyyy-MM-DDThh:mm")) : (""),
                        client: activity && activity.client && activity.client._id ? (activity.client._id) : (""),
                        process: activity && activity.process && activity.process._id ? (activity.process._id) : (""),
                        checklist: activity.checklist ? activity.checklist : [],
                        estimated_time: activity && activity.estimated_time ? activity.estimated_time : (""),
                        actual_time: activity && activity.actual_time ? activity.actual_time : (""),
                        task_description: activity && activity.task_description ? (activity.task_description) : (""),
                        attachments: activity && activity.attachments ? (activity.attachments) : (""),
                        review_status: activity && activity.review_status && activity.review_status._id ? (activity.review_status._id) : (""),
                        activity_status: activity && activity.activity_status && activity.activity_status._id ? (activity.activity_status._id) : (""),
                        comments: activity && activity.comments ? (activity.comments) : (""),
                      }}
                      validationSchema={Yup.object({
                        task_name: Yup.string().required("Required"),
                        assigned_to: Yup.string().required("Required"),
                        due_time: Yup.string().required("Required"),
                        client: Yup.string().required("Required"),
                        process: Yup.string().required("Required"),
                        review_status: Yup.string().required("Required"),
                        activity_status: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        // addData(values);
                        submitFormClicked(values)
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="add-activity-form-box">
                                  <div className="add-activity-form-header">
                                    <div className="add-activity-form-title">
                                      Activity
                                    </div>
                                    <div className="add-activity-form-desc">
                                      Enter Details to Edit Activity
                                    </div>
                                  </div>
                                  <hr />
                                  <div className="add-activity-form">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <TextInput
                                          label="Task Name"
                                          name="task_name"
                                          type="text"
                                          placeholder="Task Name"
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Assigned to"
                                          name="assigned_to"
                                          placeholder="Enter Assigned to">
                                          <option value=""> Select Assigned to </option>
                                          {dropdownOptions.assigned_to && dropdownOptions.assigned_to.map((item) => {
                                            return (
                                              <option value={item.value}> {item.label} </option>
                                            )
                                          })}
                                        </SelectBox>
                                      </div>
                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Activity Cycle"
                                          name="activity_cycle"
                                          placeholder="Select">
                                          <option value=""> Select Assigned to </option>
                                          {dropdownOptions.activity_cycle && dropdownOptions.activity_cycle.map((item) => {
                                            return (
                                              <option value={item.value}> {item.label} </option>
                                            )
                                          })}
                                        </SelectBox>
                                      </div>
                                      <div className="col-md-6">
                                        <TextInput
                                          label="Due Time"
                                          name="due_time"
                                          type="datetime-local"
                                          placeholder="Enter Due Time"
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Client"
                                          name="client"
                                          placeholder="Enter Process">
                                          <option value=""> Select Client </option>
                                          {dropdownOptions.client && dropdownOptions.client.map((item) => {
                                            return (
                                              <option value={item.value}> {item.label} </option>
                                            )
                                          })}
                                        </SelectBox>
                                      </div>
                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Process"
                                          name="process"
                                          placeholder="Enter Process"
                                          onChange={(e) => {
                                            formik.setFieldValue("process", e.target.value);
                                            formik.setFieldValue("checklist", [])
                                            if (all_processs) {
                                              const filteredData = all_processs.filter((item, index) => item._id == e.target.value);
                                              if (filteredData && filteredData.length > 0) {
                                                const checklistData = filteredData[0].checklist
                                                if (checklistData && checklistData.length > 0) {
                                                  const checklistDataMapped = checklistData.map((item) => {
                                                    return {
                                                      name: item.name,
                                                      is_checked: false
                                                    }
                                                  })
                                                  formik.setFieldValue("checklist", checklistDataMapped)
                                                }
                                              }
                                            }
                                            // all_processs && all_processs.filter((item, index) => item._id == formik.values.process) && all_processs.filter((item) => item._id == formik.values.process)[0] && all_processs.filter((item) => item._id == formik.values.process)[0].checklist && all_processs.filter((item) => item._id == formik.values.process)[0].checklist
                                          }}
                                        >
                                          <option value=""> Select Process </option>
                                          {dropdownOptions.process && dropdownOptions.process.map((item) => {
                                            return (
                                              <option value={item.value}> {item.label} </option>
                                            )
                                          })}
                                        </SelectBox>
                                      </div>
                                      <div className="col-md-6">
                                        <TextInput
                                          label="Estimated Time(in mins)"
                                          name="estimated_time"
                                          type="text"
                                          placeholder="Enter Estimated time(in mins)"
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <TextInput
                                          label="Actual Time"
                                          name="actual_time"
                                          type="text"
                                          placeholder="Enter Actual Time"
                                        />
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <label> Task Description </label>
                                          <EditorWYSIWYG
                                            value={formik.values.task_description ? formik.values.task_description : ""}
                                            name={"task_description"}
                                            changeValue={(value) => {
                                              formik.setFieldValue("task_description", value);
                                            }}
                                          />
                                          {formik.errors && formik.errors["task_description"] && (
                                            <p className="text-danger"> Required </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <label> Attachments </label>
                                        <br />
                                        <input
                                          type="file"

                                          multiple
                                          className="form-control-file"
                                          onChange={(e) =>
                                            pickImage({ field: "attachments", value: e.target.files })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Review Status"
                                          name="review_status"
                                          placeholder="Enter Review Status">
                                          <option value=""> Select Review Status </option>
                                          {dropdownOptions.review_status && dropdownOptions.review_status.map((item) => {
                                            return (
                                              <option value={item.value}>{item.label}</option>
                                            )
                                          })}
                                        </SelectBox>
                                      </div>
                                      <div className="col-md-6">
                                        <SelectBox
                                          label="Activity Status"
                                          name="activity_status"
                                          placeholder="Enter Activity Status">
                                          <option value=""> Select Activity Status </option>
                                          {dropdownOptions.activity_status && dropdownOptions.activity_status.map((item) => {
                                            return (
                                              <option value={item.value}>{item.label}</option>
                                            )
                                          })}
                                        </SelectBox>
                                      </div>
                                      <div className="col-md-6">
                                        <TextArea
                                          label="Comments"
                                          name="comment"
                                          type="text"
                                          placeholder="Enter Comments"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 text-center m-3">
                                        <button
                                          type="submit"
                                          className="btn btn-success"
                                        >
                                          {formik.isSubmitting ? "Processing..." : "Save"}
                                        </button>
                                        <a
                                          className="btn btn-secondary m-3"
                                          // onClick={history.goBack}
                                          href="#goback"
                                        >
                                          <i className="fa fa-angle-left"></i> Go Back
                                        </a>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="card">
                                  <div className="card-header">
                                    <div className="card-title-side">
                                      <h4>Checklist:</h4>
                                    </div>
                                  </div>
                                  <div className="card-body-p-8">
                                    <div className="card-body-single-data">
                                      <div className="card-body-received-datalist">
                                        <FieldArray name="checklist" render={(arrayHelper) => {
                                          return (
                                            <div>
                                              {formik.values.checklist && formik.values.checklist.map((item, index) => {
                                                return (
                                                  <div className="card-body-received-data d-flex">
                                                    <div className="col-md-6">
                                                      <CheckBox
                                                        // label={item.name}
                                                        name={`checklist[${index}].is_checked`}
                                                        type="checkbox"
                                                        className="pt-0"
                                                      >
                                                        {item.name}
                                                      </CheckBox>
                                                    </div>
                                                  </div>
                                                )
                                              })}

                                            </div>
                                          )
                                        }}>
                                        </FieldArray>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card">
                                  <div className="card-header">
                                    <div className="card-title-side">
                                      <h4>Standard Operating Procedure:</h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="card-body-single-data">
                                      <div className="card-body-received-datalist">
                                        {all_processs && all_processs.filter((item) => item._id == formik.values.process) && all_processs.filter((item) => item._id == formik.values.process)[0] && all_processs.filter((item) => item._id == formik.values.process)[0].sop && all_processs.filter((item) => item._id == formik.values.process)[0].sop.map((item) => {
                                          return (
                                            <div className="card-body-received-data d-flex pb-3">
                                              <div className="checklist-data-list-img">
                                                <img src="/images/sop_3.png" alt="" />
                                              </div>
                                              <div className="checklist-data-content">
                                                <div dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>


      </div>
    </div>
  );
};

export default EditActivity;
