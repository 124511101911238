import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  HTMLInput,
  SelectBox,
  TextArea,
  TextInput,
} from '../../components/Form/Form';
import { useGetDropdownOptions } from '../../shared/hooks/UseActivity';
import {
  ADS_ACTIVITY_ENUM,
  ADS_PLATFORM_TYPES,
  INPUT_TYPE_ENUM,
} from '../../domain/constant';
import { EditFieldComponent } from '../../components/ads/EditFieldComponent';
import moment from 'moment';
const QuickViewAdsModel = ({
  toggleModal,
  setToggleModal,
  title,
  showPlatform,
  showAdsId,
  showBenchmark,
  campaign,
  activity_type,
  dropdownOptions,
}) => {
  return (
    <>
      {toggleModal && (
        <section
          className='client-ads-model '
          style={{ overflow: 'scroll' }}
          id='client-model'
        >
          <div className='client-modal-form'>
            <div className='client-modal-form-top d-flex justify-content-between'>
              <h4 className='mb-4 '>View {title}</h4>
              <div className='client-modal-close-btn'>
                <i
                  class='fa fa-times'
                  aria-hidden='true'
                  onClick={() => setToggleModal(false)}
                ></i>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                  <label style={{ color: '#999' }}> Task Name</label>
                  <EditFieldComponent
                    field={'task_name'}
                    value={campaign.task_name}
                    element_type={INPUT_TYPE_ENUM.INPUT}
                    activity={campaign}
                    activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                  />
                </div>
              </div>
              {showPlatform && (
                <div className='col-md-6'>
                  <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                    <label style={{ color: '#999' }}> Platform</label>
                    <EditFieldComponent
                      field={'ads_platform_type'}
                      value={campaign.ads_platform_type}
                      element_type={INPUT_TYPE_ENUM.SELECT}
                      activity={campaign}
                      activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                      options={[
                        { label: 'GOOGLE', value: 'GOOGLE' },
                        { label: 'FACEBOOK', value: 'FACEBOOK' },
                      ]}
                      selected_value={campaign.ads_platform_type}
                    />
                  </div>
                </div>
              )}
              {showAdsId && (
                <div className='col-md-6'>
                  <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                    <label style={{ color: '#999' }}> Ads ID</label>
                    <EditFieldComponent
                      field={'ads_id'}
                      value={campaign.ads_id}
                      element_type={INPUT_TYPE_ENUM.INPUT}
                      activity={campaign}
                      activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                    />
                  </div>
                </div>
              )}
              {showBenchmark && (
                <div className='col-md-6'>
                  <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                    <label style={{ color: '#999' }}> Benchmark</label>
                    <EditFieldComponent
                      field={'benchmark'}
                      value={campaign.benchmark}
                      element_type={INPUT_TYPE_ENUM.INPUT}
                      activity={campaign}
                      activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                      input_type='number'
                    />
                  </div>
                </div>
              )}

              <div className='col-md-6'>
                <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                  <label style={{ color: '#999' }}> Assigned To</label>
                  <EditFieldComponent
                    field={'assigned_to'}
                    value={campaign.assigned_to?.name}
                    element_type={INPUT_TYPE_ENUM.SELECT}
                    activity={campaign}
                    activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                    options={dropdownOptions && dropdownOptions.assigned_to}
                    selected_value={campaign.assigned_to?._id}
                    setToggleModal={setToggleModal}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                  <label style={{ color: '#999' }}> Due time</label>
                  <EditFieldComponent
                    field={'due_time'}
                    value={
                      campaign.due_time &&
                      moment(campaign.due_time).format('DD-MM-YYYY h:mm:ss a')
                    }
                    element_type={INPUT_TYPE_ENUM.INPUT}
                    input_type='datetime-local'
                    activity={campaign}
                    activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                  <label style={{ color: '#999' }}> Activity Status</label>
                  <EditFieldComponent
                    field={'activity_status'}
                    value={campaign.activity_status?.name}
                    element_type={INPUT_TYPE_ENUM.SELECT}
                    activity={campaign}
                    activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                    options={dropdownOptions && dropdownOptions.activity_status}
                    selected_value={campaign.activity_status?._id}
                    styles={{
                      backgroundColor: campaign.activity_status?.color
                        ? campaign.activity_status?.color
                        : '#fff',
                      color: campaign.activity_status?.color ? '#fff' : '#000',
                    }}
                    setToggleModal={setToggleModal}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                  <label style={{ color: '#999' }}> Review Status</label>
                  <EditFieldComponent
                    field={'review_status'}
                    value={campaign.review_status?.name}
                    element_type={INPUT_TYPE_ENUM.SELECT}
                    activity={campaign}
                    activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                    options={dropdownOptions && dropdownOptions.review_status}
                    selected_value={campaign.review_status?._id}
                    styles={{
                      backgroundColor: campaign.review_status?.color
                        ? campaign.review_status?.color
                        : '#fff',
                      color: campaign.review_status?.color ? '#fff' : '#000',
                    }}
                    setToggleModal={setToggleModal}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                  <label style={{ color: '#999' }}> Process</label>
                  <EditFieldComponent
                    field={'process'}
                    value={campaign.process?.name}
                    element_type={INPUT_TYPE_ENUM.SELECT}
                    activity={campaign}
                    activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                    options={dropdownOptions && dropdownOptions.process}
                    selected_value={campaign.process?._id}
                    setToggleModal={setToggleModal}
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <div style={{ borderBottom: '1px solid #f1f1f1' }}>
                  <label style={{ color: '#999' }}> Notes</label>
                  <EditFieldComponent
                    field={'task_description'}
                    value={campaign.task_description}
                    element_type={INPUT_TYPE_ENUM.TEXTAREA}
                    activity={campaign}
                    activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default QuickViewAdsModel;
