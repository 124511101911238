export const MENU = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
  },
  {
    label: 'Clients',
    link: '/clients',
    roles: ['SUPER ADMIN', 'MANAGER', 'ACCOUNTANT'],
  },
  {
    label: 'Employee',
    link: '/employees',
    roles: ['SUPER ADMIN', 'MANAGER'],
  },
  {
    label: 'Activity',
    link: '/activitys',
    roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
  },
  {
    label: 'Activity Cycle',
    link: '/activity-cycles',
    roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
  },
  {
    label: 'Ads',
    link: '#',
    roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
    menu: [
      {
        label: 'Ads Dashboard',
        link: '/ads-dashboard',
        roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Ads',
        link: '/clients-ads',
        roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Google Ad Reports',
        link: '/adsreports',
        roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Facebook Ads Report',
        link: '/adsreports-facebook',
        roles: ['USER', 'SUPER ADMIN', 'MANAGER'],
      },
    ],
  },
  {
    label: 'Bills',
    link: '/bills',
    roles: ['SUPER ADMIN', 'MANAGER', 'ACCOUNTANT'],
  },
  {
    label: 'Settings',
    link: '#',
    roles: ['SUPER ADMIN', 'MANAGER', 'ACCOUNTANT'],
    menu: [
      {
        label: 'Client Status',
        link: '/statuss',
        roles: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Process',
        link: '/processs',
        roles: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Bank Accounts',
        link: '/bankaccounts',
        roles: ['SUPER ADMIN', 'MANAGER', 'ACCOUNTANT'],
      },
      {
        label: 'Activity Status',
        link: '/activitystatuss',
        roles: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Review Status',
        link: '/reviewstatuss',
        roles: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Groups',
        link: '/groups',
        roles: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Package',
        link: '/servicepackages',
        roles: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Bill Review',
        link: '/billreviews',
        roles: ['SUPER ADMIN', 'MANAGER'],
      },
    ],
  },

  // {
  //   label: "Process",
  //   link: "/processs",
  // },
  {
    label: 'Leads',
    link: '/leads',
    roles: ['SUPER ADMIN', 'MANAGER'],
  },
  {
    label: 'SOP',
    link: '/processs',
    roles: ['SUPER ADMIN', 'MANAGER', 'USER'],
  },
  {
    label: 'Employee Report',
    link: '/employeereports',
    roles: ['SUPER ADMIN', 'MANAGER', 'USER'],
  },
];
