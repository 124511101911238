import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
import {
  addClient,
  getClients,
  getClient,
  editClient,
  deleteClient,
  getAllClients,
  getClientDashboard,
  getAdClients,
  getAdClient,
} from '../../store/actions/client_action';
import { useSelectAllEmployee } from './UseEmployee';
import { useSelectAllStatus } from './UseStatus';
import { useSelectAllServicepackage } from './UseServicepackage';

// Get All Data
export const useAllClients = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  console.log('ID TO DELETE', deleteEntry);
  useEffect(() => {
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getClients({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteClient(deleteEntry));
      allQuery();
    }
  }, [deleteEntry]);

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};
// Get Single Data
export const useSingleClient = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  useEffect(() => {
    dispatch(getClient(id));
  }, [id]);
  return [data];
};
// Get Single Data
export const useSingleClientDashboard = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  useEffect(() => {
    dispatch(getClientDashboard(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateClient = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  const addData = async (data) => {
    await dispatch(addClient(data));
  };
  return [data, addData];
};
export const useUpdateClient = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.client);
  const updateData = async (id, data) => {
    await dispatch(editClient(id, data));
  };
  return [updateData];
};
export const useSelectAllClient = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const data = useSelector((state) => state.client);
  useEffect(() => {
    dispatch(getAllClients({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useSelectAllAdsClient = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  useEffect(() => {
    dispatch(getAdClients());
  }, []);
  return { clients_data: data };
};

export const useCreateAdClient = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.client);
  useEffect(() => {
    dispatch(getAdClient(id));
  }, []);
  return { clients_data: data };
};

export const useGetDropdownOptions = () => {
  // const [poc, setPOCSearchField, setPOCSearchValue] = useSelectAllEmployee();
  // const [am, setAMSearchField, setAMSearchValue] = useSelectAllEmployee();
  const [ad_manager, setADSearchField, setADSearchValue] =
    useSelectAllEmployee();

  const [status] = useSelectAllStatus();
  const [servicepackage] = useSelectAllServicepackage();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (status && status.all_statuss) {
      const newData = status.all_statuss.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, status: newData });
    }
  }, [status]);
  useEffect(() => {
    if (servicepackage && servicepackage.all_servicepackages) {
      const newData = servicepackage.all_servicepackages.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, package: newData });
    }
  }, [servicepackage]);
  useEffect(() => {
    if (ad_manager && ad_manager.all_employees) {
      const newData = ad_manager.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, poc: newData });
    }
  }, [ad_manager]);
  useEffect(() => {
    if (ad_manager && ad_manager.all_employees) {
      const newData = ad_manager.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, account_manager: newData });
    }
  }, [ad_manager]);
  useEffect(() => {
    if (ad_manager && ad_manager.all_employees) {
      const newData = ad_manager.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({
        ...dropdownOptions,
        ads_manager: newData,
        account_manager: newData,
        poc: newData,
      });
    }
  }, [ad_manager]);

  return [dropdownOptions, setADSearchField, setADSearchValue];
};
