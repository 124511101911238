import {
  GET_ACTIVITYS_STATED,
  GET_ACTIVITYS,
  GET_ACTIVITYS_ENDED,
  ADD_ACTIVITY_STATED,
  ADD_ACTIVITY,
  ADD_ACTIVITY_ENDED,
  EDIT_ACTIVITY_STATED,
  EDIT_ACTIVITY,
  EDIT_ACTIVITY_ENDED,
  GET_ACTIVITY_STATED,
  GET_ACTIVITY,
  GET_ACTIVITY_ENDED,
  GET_ALL_ACTIVITYS_STATED,
  GET_ALL_ACTIVITYS,
  GET_ALL_ACTIVITYS_ENDED,
  GET_ACTIVITYS_BY_EMPLOYEE_STARTED,
  GET_ACTIVITYS_BY_EMPLOYEE,
  GET_ACTIVITYS_BY_EMPLOYEE_ENDED,
  GET_ADS_CLIENT_ACTIVITIES_STARTED,
  GET_ADS_CLIENT_ACTIVITIES,
  GET_ADS_CLIENT_ACTIVITIES_ENDED,
  ADD_ADS_CLIENT_ACTIVITY_STARTED,
  ADD_ADS_CLIENT_ACTIVITY_ENDED,
  EDIT_ADS_CLIENT_ACTIVITY_STARTED,
  EDIT_ADS_CLIENT_ACTIVITY_ENDED,
} from '../types/activity_type';

const initialState = {
  activitys_loading: true,
  activitys: null,
  page: null,
  pages: null,
  total_activitys: 0,

  activity: null,
  activity_loading: null,

  loading: true,

  activity_message: null,
  all_activitys: null,
  all_activitys_loading: null,
  add_activity_loading: true,
  edit_activity_loading: true,

  activities_by_employee_loading: false,
  activities_by_employee: null,
  activities_status_array: null,

  campaigns_loading: false,
  total_campaigns: null,
  campaigns: null,
  campaign_page: null,
  campaigns_pages: null,

  landing_pages_loading: false,
  total_landing_pages: null,
  landing_pages: null,
  landing_page_page: null,
  landing_pages_pages: null,

  creatives_loading: false,
  total_creatives: null,
  creatives: null,
  creative_page: null,
  creatives_pages: null,

  add_ads_client_activity_loading: null,
  add_ads_client_activity: null,

  edit_ads_client_activity_loading: null,
  edit_ads_client_activity: null,
};

export const activity_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ACTIVITYS_STATED:
      return {
        ...state,
        activitys: null,
        pages: null,
        page: null,
        total_activitys: 0,
        activitys_loading: true,
      };
    case GET_ACTIVITYS:
      return {
        ...state,
        activitys: payload.activitys,
        pages: payload.pages,
        page: payload.page,
        total_activitys: payload.count,
      };
    case GET_ACTIVITYS_ENDED:
      return {
        ...state,
        activitys_loading: false,
      };
    case GET_ACTIVITYS_BY_EMPLOYEE_STARTED:
      return {
        ...state,
        activitys: null,
        pages: null,
        page: null,
        total_activitys: 0,
        activitys_loading: true,
        activities_status_array: null,
      };
    case GET_ACTIVITYS_BY_EMPLOYEE:
      return {
        ...state,
        activitys: payload.activitys,
        pages: payload.pages,
        page: payload.page,
        total_activitys: payload.count,
        activities_status_array: payload.activities_status_array,
      };
    case GET_ACTIVITYS_BY_EMPLOYEE_ENDED:
      return {
        ...state,
        activitys_loading: false,
      };

    case GET_ALL_ACTIVITYS_STATED:
      return {
        ...state,
        all_activitys_loading: true,
        all_activitys: null,
      };
    case GET_ALL_ACTIVITYS:
      return {
        ...state,
        all_activitys: payload,
      };
    case GET_ALL_ACTIVITYS_ENDED:
      return {
        ...state,
        all_activitys_loading: false,
      };

    case ADD_ACTIVITY_STATED:
      return {
        ...state,
        activity_message: null,
        add_activity_loading: true,
      };
    case ADD_ACTIVITY:
      return {
        ...state,
        activity_message: payload,
      };
    case ADD_ACTIVITY_ENDED:
      return {
        ...state,
        add_activity_loading: false,
      };
    case GET_ACTIVITY_STATED:
      return {
        ...state,
        activity: null,
        activity_loading: true,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        activity: payload,
      };
    case GET_ACTIVITY_ENDED:
      return {
        ...state,
        activity_loading: false,
      };
    case GET_ADS_CLIENT_ACTIVITIES_STARTED:
      return {
        ...state,
        campaigns_loading: true,
        total_campaigns: null,
        campaigns: null,
        campaign_page: null,
        campaigns_pages: null,
        landing_pages_loading: true,
        total_landing_pages: null,
        landing_pages: null,
        landing_page_page: null,
        landing_pages_pages: null,
        creatives_loading: true,
        total_creatives: null,
        creatives: null,
        creative_page: null,
        creatives_pages: null,
      };
    case GET_ADS_CLIENT_ACTIVITIES:
      return {
        ...state,
        total_campaigns: payload.totalCampaigns,
        campaigns: payload.campaigns,
        campaign_page: payload.campaign_page,
        campaigns_pages: payload.campaigns_pages,
        total_landing_pages: payload.totalLandingPages,
        landing_pages: payload.landingPages,
        landing_page_page: payload.landing_page_page,
        landing_pages_pages: payload.landingPage_pages,
        total_creatives: payload.totalCreatives,
        creatives: payload.creatives,
        creative_page: payload.creative_page,
        creatives_pages: payload.creative_pages,
      };

    case GET_ADS_CLIENT_ACTIVITIES_ENDED:
      return {
        ...state,
        campaigns_loading: false,
        landing_pages_loading: false,
        creatives_loading: false,
      };

    case ADD_ADS_CLIENT_ACTIVITY_STARTED:
      return {
        ...state,
        add_ads_client_activity_loading: true,
        add_ads_client_activity: payload,
      };
    case ADD_ADS_CLIENT_ACTIVITY_ENDED:
      return {
        ...state,
        add_ads_client_activity_loading: false,
        add_ads_client_activity: null,
      };

    case EDIT_ADS_CLIENT_ACTIVITY_STARTED:
      return {
        ...state,
        edit_ads_client_activity_loading: true,
        edit_ads_client_activity: payload,
      };
    case EDIT_ADS_CLIENT_ACTIVITY_ENDED:
      return {
        ...state,
        edit_ads_client_activity_loading: false,
        edit_ads_client_activity: null,
      };

    case EDIT_ACTIVITY_STATED:
      return {
        ...state,
        activity_message: null,
        edit_activity_loading: true,
      };
    case EDIT_ACTIVITY:
      return {
        ...state,
        activity_message: payload,
      };
    case EDIT_ACTIVITY_ENDED:
      return {
        ...state,
        edit_activity_loading: false,
      };

    default:
      return state;
  }
};
