import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDashboards } from "../../store/actions/dashboard_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllDashboards = () => {
  const dispatch = useDispatch();
 
  const data = useSelector((state) => state.dashboard);
  useEffect(() => {
    dispatch(getDashboards());
  }, [window.location.search]);
  return [data];
};


