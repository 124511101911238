import {
  GET_COMMENTS_STATED,
  GET_COMMENTS,
  GET_COMMENTS_ENDED,
  ADD_COMMENT_STATED,
  ADD_COMMENT,
  ADD_COMMENT_ENDED,
  EDIT_COMMENT_STATED,
  EDIT_COMMENT,
  EDIT_COMMENT_ENDED,
  GET_COMMENT_STATED,
  GET_COMMENT,
  GET_COMMENT_ENDED,
  GET_ALL_COMMENTS_STATED,
  GET_ALL_COMMENTS,
  GET_ALL_COMMENTS_ENDED,
} from "../types/comment_type";

const initialState = {
  comments_loading: true,
  comments: null,
  page: null,
  pages: null,
  total_comments: 0,
  comment: null,
  comment_loading: null,
  loading: true,
  comment_message: null,
  all_comments: null,
  all_comments_loading: null,
  add_comment_loading: true,
  edit_comment_loading: true,
};

export const comment_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMMENTS_STATED:
      return {
        ...state,
        comments: null,
        pages: null,
        page: null,
        total_comments: 0,
        comments_loading: true,
      };
    case GET_COMMENTS:
      return {
        ...state,
        comments: payload.comments,
        pages: payload.pages,
        page: payload.page,
        total_comments: payload.count,
      };
    case GET_COMMENTS_ENDED:
      return {
        ...state,
        comments_loading: false,
      };
    case GET_ALL_COMMENTS_STATED:
      return {
        ...state,
        all_comments_loading: true,
        all_comments: null,
      };
    case GET_ALL_COMMENTS:
      return {
        ...state,
        all_comments: payload,
      };
    case GET_ALL_COMMENTS_ENDED:
      return {
        ...state,
        all_comments_loading: false,
      };

    case ADD_COMMENT_STATED:
      return {
        ...state,
        comment_message: null,
        add_comment_loading: true,
      };
    case ADD_COMMENT:
      return {
        ...state,
        comment_message: payload,
      };
    case ADD_COMMENT_ENDED:
      return {
        ...state,
        add_comment_loading: false,
      };
    case GET_COMMENT_STATED:
      return {
        ...state,
        comment: null,
        comment_loading: true,
      };
    case GET_COMMENT:
      return {
        ...state,
        comment: payload,
      };
    case GET_COMMENT_ENDED:
      return {
        ...state,
        comment_loading: false,
      };
    case EDIT_COMMENT_STATED:
      return {
        ...state,
        comment_message: null,
        edit_comment_loading: true,
      };
    case EDIT_COMMENT:
      return {
        ...state,
        comment_message: payload,
      };
    case EDIT_COMMENT_ENDED:
      return {
        ...state,
        edit_comment_loading: false,
      };

    default:
      return state;
  }
};
