export const PAGE_TITLE = 'Reviewstatuss';
export const PAGE_SINGLE_TITLE = 'Reviewstatus';
export const LINK_URL = 'reviewstatuss';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },
  color: {
    type: 'string',
    required: true,
    title: 'Color',
    inputType: 'text',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [
  { name: 'Name', value: 'name' },
  { name: 'Color', value: 'color' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
];
