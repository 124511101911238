export const GET_CLIENTS_STATED = 'GET_CLIENTS_STATED';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_ENDED = 'GET_CLIENTS_ENDED';
export const ADD_CLIENT_STATED = 'ADD_CLIENT_STARTED';
export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_ENDED = 'ADD_CLIENT_ENDED';
export const EDIT_CLIENT_STATED = 'EDIT_CLIENT_STATED';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const EDIT_CLIENT_ENDED = 'EDIT_CLIENT_ENDED';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENT_STATED = 'GET_CLIENT_STATED';
export const GET_CLIENT_ENDED = 'GET_CLIENT_ENDED';
export const RESET_CLIENT = 'RESET_CLIENT';
export const ERROR_CLIENT = 'ERROR_CLIENT';
export const GET_ALL_CLIENTS_STATED = 'GET_ALL_CLIENTS_STATED';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_CLIENTS_ENDED = 'GET_ALL_CLIENTS_ENDED';

export const GET_CLIENT_DASHBOARD_STARTED = 'GET_CLIENT_DASHBOARD_STARTED';
export const GET_CLIENT_DASHBOARD = 'GET_CLIENT_DASHBOARD';
export const GET_CLIENT_DASHBOARD_ENDED = 'GET_CLIENT_DASHBOARD_ENDED';

export const GET_CLIENT_ADS_STARTED = 'GET_CLIENT_ADS_STARTED';
export const GET_CLIENT_ADS = 'GET_CLIENT_ADS';
export const GET_CLIENT_ADS_ENDED = 'GET_CLIENT_ADS_ENDED';

export const GET_SINGLE_CLIENT_ADS_STARTED = 'GET_SINGLE_CLIENT_ADS_STARTED';
export const GET_SINGLE_CLIENT_ADS = 'GET_SINGLE_CLIENT_ADS';
export const GET_SINGLE_CLIENT_ADS_ENDED = 'GET_SINGLE_CLIENT_ADS_ENDED';
