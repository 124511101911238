export const PAGE_TITLE = 'Ads Reports';
export const PAGE_SINGLE_TITLE = 'Ads Report';
export const LINK_URL = 'adsreports';
export const inputFields = {
  report_date: {
    type: 'string',
    required: true,
    title: 'Report Date',
    inputType: 'date',
  },
  client: {
    type: 'related',
    required: true,
    title: 'Client',
    inputType: 'text',
    field: 'name',
  },
  account_name: {
    type: 'string',
    required: true,
    title: 'Account Name',
  },
  customer_id: {
    type: 'string',
    required: true,
    title: 'Customer ID',
  },
  ad_group_id: {
    type: 'string',
    required: true,
    title: 'Ad Group ID',
  },
  clicks: {
    type: 'string',
    required: true,
    title: 'Clicks',
  },
  impressions: {
    type: 'string',
    required: true,
    title: 'Impressions',
  },
  ctr: {
    type: 'string',
    required: true,
    title: 'CTR',
  },
  cost: {
    type: 'string',
    required: true,
    title: 'Cost',
  },
  conversions: {
    type: 'string',
    required: true,
    title: 'Conversions',
  },
};
export const initialValues = {
  report_date: '',
};

export const view_all_table = [
  { name: 'Report Date', value: 'report_date', date: true, utc: true },
  { name: 'Client', value: 'client', related: true, field: 'name' },
  { name: 'Campaign', value: 'campaign', related: true, field: 'task_name' },
  { name: 'Ads ID', value: 'ads_id' },
  { name: 'Conversions', value: 'conversions' },
  { name: 'Phone Calls.', value: 'phone_calls' },
  { name: 'Total Cost', value: 'total_cost' },
  { name: 'CPC', value: 'cost_per_conversion' },
  { name: 'Clicks', value: 'clicks' },
  { name: 'Benchmark', value: 'benchmark' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'report_date',
    field: 'report_date',
    label: 'Report Date',
    type: 'string',
    search_type: 'search',
    inputType: 'date',
    condition: '',
  },
  {
    id: 'client',
    field: 'client',
    label: 'Client',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'account_name',
    field: 'account_name',
    label: 'Account Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'customer_id',
    field: 'customer_id',
    label: 'Customer ID',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'ad_group',
    field: 'ad_group',
    label: 'Ad Group',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'ctr',
    field: 'ctr',
    label: 'CTR',
    type: 'string',
    search_type: 'conditional',
    inputType: 'number',
    condition: '$gte',
  },
  {
    id: 'date_from',
    field: 'createdAt',
    label: 'Date From',
    type: 'string',
    search_type: 'conditional',
    inputType: 'date',
    condition: '$gte',
  },
  {
    id: 'date_to',
    field: 'createdAt',
    label: 'Date To',
    type: 'string',
    search_type: 'conditional',
    inputType: 'date',
    condition: '$lte',
  },
];

export const inputFields_google = {
  report_date: {
    type: 'string',
    required: true,
    title: 'Start Date',
    inputType: 'date',
  },

  google_file: {
    type: 'file',
    required: false,
    title: 'Google File',
    // inputType: "number",
  },
};
export const initialValues_google = {
  report_date: '',
};
