export const PAGE_TITLE = 'Faccebook Ads Reports';
export const PAGE_SINGLE_TITLE = 'Ads Report';
export const LINK_URL = 'adsreports';
export const inputFields = {
  ad_set_name: {
    type: 'string',
    required: true,
    title: 'Ad Set Name',
    // inputType: "date",
  },
  delivery_status: {
    type: 'string',
    required: true,
    title: 'Delivery Status',
  },
  delivery_level: {
    type: 'string',
    required: true,
    title: 'Delivery Level',
  },
  reach: {
    type: 'string',
    required: true,
    title: 'Reach',
  },
  impressions: {
    type: 'string',
    required: true,
    title: 'Impressions',
  },
  result_type: {
    type: 'string',
    required: true,
    title: 'Result Type',
  },
  results: {
    type: 'string',
    required: true,
    title: 'Results',
  },
  amount_spent: {
    type: 'string',
    required: true,
    title: 'Amount Spent',
  },
  cpr: {
    type: 'string',
    required: true,
    title: 'CPR',
  },
  reporting_start_date: {
    type: 'string',
    required: true,
    title: 'Report Start Date',
    inputType: 'date',
  },
  reporting_end_date: {
    type: 'string',
    required: true,
    title: 'Report End Date',
    inputType: 'date',
  },

  //Old Fields
  // report_start_date: {
  //   type: "string",
  //   required: true,
  //   title: "Report Start Date",
  //   inputType: "date",
  // },
  // report_end_date: {
  //   type: "string",
  //   required: true,
  //   title: "Report End Date",
  //   inputType: "date",
  // },
  // client: {
  //   type: "related",
  //   required: true,
  //   title: "Client",
  //   inputType: "text",
  //   field: "name",
  // },
  // starting_date: {
  //   type: "string",
  //   required: true,
  //   title: "Starting Date",
  // },
  // ending_date: {
  //   type: "string",
  //   required: true,
  //   title: "Ending Date",
  // },
  // campaign_name: {
  //   type: "string",
  //   required: true,
  //   title: "Campaign Name",
  // },
  // campaign_delivery: {
  //   type: "string",
  //   required: true,
  //   title: "Campaign Delivery",
  // },
  // ad_set_budget: {
  //   type: "string",
  //   required: true,
  //   title: "Ad Set Budget",
  // },
  // ad_set_budget_type: {
  //   type: "string",
  //   required: true,
  //   title: "Ad Set Budget Type",
  // },
  // attr_setting: {
  //   type: "string",
  //   required: true,
  //   title: "Attr Setting",
  // },
  // results: {
  //   type: "string",
  //   required: true,
  //   title: "Results",
  // },
  // results_indicator: {
  //   type: "string",
  //   required: true,
  //   title: "Results Indicator",
  // },
  // reach: {
  //   type: "string",
  //   required: true,
  //   title: "Reach",
  // },
  // impressions: {
  //   type: "string",
  //   required: true,
  //   title: "Impressions",
  // },
  // cpr: {
  //   type: "string",
  //   required: true,
  //   title: "CPR",
  // },
  // amount_spent: {
  //   type: "string",
  //   required: true,
  //   title: "Amount Spent",
  // },
  // ends: {
  //   type: "string",
  //   required: true,
  //   title: "ends",
  // },
};
export const initialValues = {
  report_date: '',
};

export const view_all_table = [
  { name: 'Date', value: 'date', date: true },
  { name: 'Client', value: 'client', related: true, field: 'name' },
  { name: 'Campaign', value: 'campaign', related: true, field: 'task_name' },
  { name: 'Result Type', value: 'result_type' },
  { name: 'Conversions', value: 'conversions' },
  { name: 'Reach', value: 'reach' },
  { name: 'Impressions', value: 'impressions' },
  { name: 'Cost Per Result', value: 'cost_per_result' },
  { name: 'Total Cost', value: 'total_cost' },
  { name: 'Reach', value: 'reach' },
  { name: 'benchmark', value: 'benchmark' },
];

export const SIDEBAR_OPTIONS = [
  {
    id: 'report_date',
    field: 'report_date',
    label: 'Report Date',
    type: 'string',
    search_type: 'search',
    inputType: 'date',
    condition: '',
  },
  {
    id: 'client',
    field: 'client',
    label: 'Client',
    type: 'related',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'account_name',
    field: 'account_name',
    label: 'Account Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'customer_id',
    field: 'customer_id',
    label: 'Customer ID',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'ad_group',
    field: 'ad_group',
    label: 'Ad Group',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'cpr',
    field: 'cpr',
    label: 'cPR',
    type: 'string',
    search_type: 'conditional',
    inputType: 'number',
    condition: '$gte',
  },
  {
    id: 'date_from',
    field: 'createdAt',
    label: 'Date From',
    type: 'string',
    search_type: 'conditional',
    inputType: 'date',
    condition: '$gte',
  },
  {
    id: 'date_to',
    field: 'createdAt',
    label: 'Date To',
    type: 'string',
    search_type: 'conditional',
    inputType: 'date',
    condition: '$lte',
  },
];

export const inputFields_facebook = {
  client: {
    type: 'related',
    required: true,
    title: 'Client',
    field: 'name',
  },

  facebook_file: {
    type: 'file',
    required: false,
    title: 'Facebook File',
    // inputType: "number",
  },
};
export const initialValues_facebook = {
  report_start_date: '',
  report_end_date: '',
};
