import api from "../../domain/api";
import {
  GET_EMPLOYEEREPORTS_STATED,
  GET_EMPLOYEEREPORTS,
  GET_EMPLOYEEREPORTS_ENDED,
  ADD_EMPLOYEEREPORT_STATED,
  ADD_EMPLOYEEREPORT,
  ADD_EMPLOYEEREPORT_ENDED,
  EDIT_EMPLOYEEREPORT_STATED,
  EDIT_EMPLOYEEREPORT,
  EDIT_EMPLOYEEREPORT_ENDED,
  GET_EMPLOYEEREPORT_STATED,
  GET_EMPLOYEEREPORT,
  GET_EMPLOYEEREPORT_ENDED,
  GET_ALL_EMPLOYEEREPORTS_STATED,
  GET_ALL_EMPLOYEEREPORTS,
  GET_ALL_EMPLOYEEREPORTS_ENDED,
} from "../types/employeereport_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addEmployeereport = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_EMPLOYEEREPORT_STATED,
    });
    const { data } = await api.post(`/employeereports`, formData);
    dispatch({
      type: ADD_EMPLOYEEREPORT,
      payload: data,
    });
    dispatch({
      type: ADD_EMPLOYEEREPORT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_EMPLOYEEREPORT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getEmployeereports =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_EMPLOYEEREPORTS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/employeereports?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_EMPLOYEEREPORTS,
        payload: data,
      });
      dispatch({
        type: GET_EMPLOYEEREPORTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEEREPORTS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getEmployeereport = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EMPLOYEEREPORT_STATED,
    });
    const { data } = await api.get(`/employeereports/${id}`);

    dispatch({
      type: GET_EMPLOYEEREPORT,
      payload: data,
    });
    dispatch({
      type: GET_EMPLOYEEREPORT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEEREPORT_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editEmployeereport = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_EMPLOYEEREPORT_STATED,
    });
    const { data } = await api.put(`/employeereports/${id}`, formData);
    dispatch({
      type: EDIT_EMPLOYEEREPORT,
      payload: data,
    });
    dispatch({
      type: EDIT_EMPLOYEEREPORT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEEREPORT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteEmployeereport = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/employeereports/${id}`);
    dispatch(setAlert("Employeereport Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllEmployeereports = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_EMPLOYEEREPORTS_STATED,
    });
    const { data } = await api.get(`/employeereports/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_EMPLOYEEREPORTS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_EMPLOYEEREPORTS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_EMPLOYEEREPORTS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
