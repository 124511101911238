import React, { useEffect, useRef } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/bills_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleBill } from "../../shared/hooks/UseBill";
import { useReactToPrint } from "react-to-print";
import TableComponent from "../../components/template/TableComponent";
import WithoutGSTComponent from "../../components/template/WithoutGSTComponent";
const ViewBill = ({ match }) => {
  const [data] = useSingleBill(match.params.id);
  const { bill_loading, bill } = data;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentWithoutRef = useRef(null);
  const handlePrintWithout = useReactToPrint({
    content: () => componentWithoutRef.current,
  });
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {bill && <TableComponent ref={componentRef} bill={bill} />}
        <div style={{ float: "right", marginRight: "10px" }}>
          <button onClick={handlePrint} className="btn btn-success">
            Print Bill
          </button>
        </div>
        {bill && <WithoutGSTComponent ref={componentWithoutRef} bill={bill} />}
        <div style={{ float: "right", marginRight: "10px" }}>
          <button onClick={handlePrintWithout} className="btn btn-success">
            Print Bill W/O GST
          </button>
        </div>

        {!bill_loading ? (
          bill && (
            <SingleView
              data={bill}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={bill._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBill;
