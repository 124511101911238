export const GET_ADS_STATED = "GET_ADS_STATED";
export const GET_ADS = "GET_ADS";
export const GET_ADS_ENDED = "GET_ADS_ENDED";
export const ADD_AD_STATED = "ADD_AD_STARTED";
export const ADD_AD = "ADD_AD";
export const ADD_AD_ENDED = "ADD_AD_ENDED";
export const EDIT_AD_STATED = "EDIT_AD_STATED";
export const EDIT_AD = "EDIT_AD";
export const EDIT_AD_ENDED = "EDIT_AD_ENDED";
export const GET_AD = "GET_AD";
export const GET_AD_STATED = "GET_AD_STATED";
export const GET_AD_ENDED = "GET_AD_ENDED";
export const RESET_AD = "RESET_AD";
export const ERROR_AD = "ERROR_AD";
export const GET_ALL_ADS_STATED = "GET_ALL_ADS_STATED";
export const GET_ALL_ADS = "GET_ALL_ADS";
export const GET_ALL_ADS_ENDED = "GET_ALL_ADS_ENDED";
