import {
  GET_EMPLOYEEREPORTS_STATED,
  GET_EMPLOYEEREPORTS,
  GET_EMPLOYEEREPORTS_ENDED,
  ADD_EMPLOYEEREPORT_STATED,
  ADD_EMPLOYEEREPORT,
  ADD_EMPLOYEEREPORT_ENDED,
  EDIT_EMPLOYEEREPORT_STATED,
  EDIT_EMPLOYEEREPORT,
  EDIT_EMPLOYEEREPORT_ENDED,
  GET_EMPLOYEEREPORT_STATED,
  GET_EMPLOYEEREPORT,
  GET_EMPLOYEEREPORT_ENDED,
  GET_ALL_EMPLOYEEREPORTS_STATED,
  GET_ALL_EMPLOYEEREPORTS,
  GET_ALL_EMPLOYEEREPORTS_ENDED
} from "../types/employeereport_type";

const initialState = {
  employeereports_loading: true,
  employeereports: null,
  page: null,
  pages: null,
  total_employeereports: 0,

  employeereport: null,
  employeereport_loading: null,

  loading: true,

  employeereport_message: null,
  all_employeereports: null,
  all_employeereports_loading: null,
  add_employeereport_loading: true,
  edit_employeereport_loading: true
};

export const employeereport_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMPLOYEEREPORTS_STATED:
      return {
        ...state,
        employeereports: null,
        pages: null,
        page: null,
        total_employeereports: 0,
        employeereports_loading: true
      };
    case GET_EMPLOYEEREPORTS:
      return {
        ...state,
        employeereports: payload.employeereports,
        pages: payload.pages,
        page: payload.page,
        total_employeereports: payload.count
      };
    case GET_EMPLOYEEREPORTS_ENDED:
      return {
        ...state,
        employeereports_loading: false
      };
    case GET_ALL_EMPLOYEEREPORTS_STATED:
      return {
        ...state,
        all_employeereports_loading: true,
        all_employeereports: null
      };
    case GET_ALL_EMPLOYEEREPORTS:
      return {
        ...state,
        all_employeereports: payload
      };
    case GET_ALL_EMPLOYEEREPORTS_ENDED:
      return {
        ...state,
        all_employeereports_loading: false
      };

    case ADD_EMPLOYEEREPORT_STATED:
      return {
        ...state,
        employeereport_message: null,
        add_employeereport_loading: true
      };
    case ADD_EMPLOYEEREPORT:
      return {
        ...state,
        employeereport_message: payload
      };
    case ADD_EMPLOYEEREPORT_ENDED:
      return {
        ...state,
        add_employeereport_loading: false
      };
    case GET_EMPLOYEEREPORT_STATED:
      return {
        ...state,
        employeereport: null,
        employeereport_loading: true
      };
    case GET_EMPLOYEEREPORT:
      return {
        ...state,
        employeereport: payload
      };
    case GET_EMPLOYEEREPORT_ENDED:
      return {
        ...state,
        employeereport_loading: false
      };
    case EDIT_EMPLOYEEREPORT_STATED:
      return {
        ...state,
        employeereport_message: null,
        edit_employeereport_loading: true
      };
    case EDIT_EMPLOYEEREPORT:
      return {
        ...state,
        employeereport_message: payload
      };
    case EDIT_EMPLOYEEREPORT_ENDED:
      return {
        ...state,
        edit_employeereport_loading: false
      };

    default:
      return state;
  }
};
