import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import DoughnutChart from "../../components/charts/DonughtChart";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import { useAllDashboards } from "../../shared/hooks/UseDashboard";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import List from "./list";
import JobList from "./JobList";
import { useAllEmployees } from "../../shared/hooks/UseEmployee";
import { quotes } from "../../components/models/Quote";
import ChristmasCard from "../../components/christmascards/ChristmasCard";
import Snow from "../../components/snow/Snow";
import FireworkComponent from "./FireworkComponent";
import EmployeeOfMonth from "./EmployeeOfMonth";
import Slider from "react-slick";

const Dashboard = ({ auth: { user } }) => {

  const [data] = useAllDashboards();
  // console.log("DATA", data);
  const { dashboards } = data;
  const history = useHistory();
  const location = useLocation();

  const [employeeData] = useAllEmployees();

  const { employees, total_employee } = employeeData;

  const Today = (person) => {
    let currentDay = new Date().getDate();
    let currentMonth = new Date().getMonth();

    let filter =
      person &&
      person.filter((data) => {
        // console.log(data);
        let day = new Date(data.dob).getDate();
        let month = new Date(data.dob).getMonth();

        // console.log("DAY", day);
        // console.log("MONTH", month);

        return currentDay == day && currentMonth == month;
      });
    return filter;
  };
  const TodayDOJ = (person) => {
    let currentDay = new Date().getDate();
    let currentMonth = new Date().getMonth();

    let filter =
      person &&
      person.filter((data) => {
        // console.log(data);
        let day = data.doj && new Date(data.doj).getDate();
        let month = data.doj && new Date(data.doj).getMonth();

        // console.log("DAY", day);
        // console.log("MONTH", month);

        return currentDay == day && currentMonth == month;
      });
    return filter;
  };

  // upcoming birthdays
  const Upcoming = (person, toMonth) => {
    let currentMonth = new Date().getMonth();
    let currentDay = new Date().getDate();

    let filter =
      person &&
      person.filter((data) => {
        let month = new Date(data.dob).getMonth();
        let day = new Date(data.dob).getDate();

        if (currentDay == day) return;
        return month >= currentMonth && month <= currentMonth + toMonth;
      });

    return filter;
  };
  const UpcomingDOJ = (person, toMonth) => {
    let currentMonth = new Date().getMonth();
    let currentDay = new Date().getDate();

    let filter =
      person &&
      person.filter((data) => {
        let month = data.doj && new Date(data.doj).getMonth();
        let day = data.doj && new Date(data.doj).getDate();

        if (currentDay == day) return;
        return month >= currentMonth && month <= currentMonth + toMonth;
      });

    return filter;
  };

  let randomQuote =
    quotes && quotes.quote[Math.floor(quotes.quote.length * Math.random())];





  return (
    <div className="pace-done">
      <div>
        <Header />
        {
          <BreadCrumb
            title="Dashboard"
            mainLinkTitle="Dashboard"
            mainLinkUrl="/dashboard"
            activeLink="Main"
          />
        }

        <div className="marquee-text" >
          <marquee behavior="scroll" direction="right" className="">

            <span style={{ paddingLeft: "400px", color: "#E41D2E" }}>
              <a href="/assets/hr-policy.pdf">-- HR Policy 2023 --</a>
            </span>

          </marquee>
        </div>
        <div className="marquee-text" >
          <marquee behavior="scroll" direction="left" className="">
            NORMALIZE BEING NICE TO PEOPLE FOR NO REASON!! --Unknown
            <span style={{ paddingLeft: "400px", color: "#AFB42B" }}>
              "It's not about ideas, it's about making ideas happen". --Ankita Acharjya
            </span>
            <span style={{ paddingLeft: "400px", color: "#FA7070" }}>
              Note: Please complete your Due Activities
            </span>
            <span style={{ paddingLeft: "400px", color: "deeppink" }}>
              Note: Please Don't forget to fillup Estimate time (mins) in
              Activity
            </span>
            <span style={{ paddingLeft: "400px", color: "#367E18" }}>
              Note: Please Add link of Completed task in activity comments for
              review!!
            </span>
          </marquee>
        </div>
        <FilterDateComponent link="/dashboard" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              {user && (user.role == "SUPER ADMIN" || user.role == "MANAGER") && (
                <div className="row">
                  <div className="col-md-3">
                    <div className="dashboard-stat pending">
                      <Link to={`/clients`}>
                        <div className="report-title">Total Clients </div>
                      </Link>
                      <Link to={`/clients`}>
                        <div className="report-stat">
                          {dashboards && dashboards.total_client
                            ? dashboards.total_client
                            : 0}
                        </div>
                      </Link>
                    </div>
                  </div>
                  {dashboards &&
                    dashboards.status_stats &&
                    dashboards.status_stats.map((item) => {
                      console.log("item mai kya hai -", item);
                      return (
                        <>
                          {
                            item && item._id && item._id == "62b2a81edb1f21d46398f0c5" ? (
                              <>
                                <div className="col-md-3" >
                                  <div className="dashboard-stat closed" style={{ backgroundColor: "#E41D2E" }} >
                                    <div className="report-title-not-satisfied" >
                                      <Link to={`/clients?exact[status]=${item._id}`}>
                                        {item.status ? item.status : "Other Clients"}
                                      </Link>
                                    </div>
                                    <Link to={`/clients?exact[status]=${item._id}`} >
                                      <div className="report-stat-not-satisfied">{item.count}</div>
                                    </Link>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-md-3">
                                  <div className="dashboard-stat closed">
                                    <div className="report-title">
                                      <Link to={`/clients?exact[status]=${item._id}`}>
                                        {item.status ? item.status : "Other Clients"}
                                      </Link>
                                    </div>
                                    <Link to={`/clients?exact[status]=${item._id}`}>
                                      <div className="report-stat">{item.count}</div>
                                    </Link>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    })}

                  <p>
                    <strong>Client Review Status -</strong>
                  </p>

                  {dashboards &&
                    dashboards.client_review_status_array &&
                    dashboards.client_review_status_array.map((item) => {
                      return (
                        <div className="col-md-3">
                          <div className="dashboard-stat closed">
                            <div className="report-title">
                              <Link
                                to={`/clients?exact[review_status]=${item._id}`}
                              >
                                {item._id ? item._id : "Other Clients"}
                              </Link>
                            </div>
                            <Link
                              to={`/clients?exact[review_status]=${item._id}`}
                            >
                              <div className="report-stat">{item.count}</div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <strong>Pending Tasks</strong>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-stripped">
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Client Name</th>
                              <th>Task Name</th>
                              <th>Process</th>
                              <th>Assigned To</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboards &&
                              dashboards.activities &&
                              dashboards.activities.slice(0, 100).map((item, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.client && item.client.name}</td>
                                    <td>{item.task_name}</td>
                                    <td>{item.process && item.process.name}</td>
                                    <td>
                                      {item.assigned_to &&
                                        item.assigned_to.name}
                                    </td>
                                    <td>
                                      {item.activity_status &&
                                        item.activity_status.name}
                                    </td>
                                    <td>
                                      {" "}
                                      <Link to={`/activitys/${item._id}/view`}>
                                        {" "}
                                        View{" "}
                                      </Link>{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <strong>Important Dates</strong>
                    </div>
                    <div className="card-body">
                      <p>
                        <strong>
                          Monthly Schedule for Client’s Meeting:
                        </strong>
                      </p>
                      <p>
                        <strong>22nd: Report and Meeting</strong>
                      </p>

                      <p>
                        <strong>
                          24th: Content Deck with Content will be shared with the client as per the strategy for approval.
                        </strong>
                      </p>
                      <p><strong>26th: Content Deck with Creatives/Videos will be shared with the client for approval.</strong></p>
                      <p><strong>
                        28th: Scheduling on SM platforms
                      </strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashboard-stat">
                <h3> Activity </h3>
                <hr />

                <DoughnutChart
                  graph_data={dashboards && dashboards.activities_status_array}
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="dashboard-stat pending">
                    <div className="report-title"> Total Activities</div>
                    <div className="report-stat">
                      <Link to={`/activitys`}>
                        {dashboards && dashboards.total_activities
                          ? dashboards.total_activities
                          : 0}
                      </Link>
                    </div>
                  </div>
                </div>
                {dashboards &&
                  dashboards.activities_status_array &&
                  dashboards.activities_status_array.map((item, index) => {
                    return (
                      <div className="col-md-6">
                        <div className="dashboard-stat pending">
                          <div className="report-title">
                            {item.status ? item.status : "Other Clients"}{" "}
                          </div>
                          <div className="report-stat">
                            <Link
                              to={`/activitys?exact[activity_status]=${item._id}`}
                            >
                              {item.count}
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="dashboard-stat">
                  <p className="text-dark title">Todays Birthday 🥳🎉</p>
                  <hr />
                  <List info={Today(employees)}></List>
                </div>
                <div className="dashboard-stat">
                  <div className="board">
                    <p className="upcoming text-dark">Upcoming Birthday</p>
                    <hr />
                    <List info={Upcoming(employees, 3)} Upcoming></List>
                  </div>
                </div>
                <div className="dashboard-stat">
                  <p className="text-dark title">Todays Job Anniversary 🥳🎉</p>
                  <hr />
                  <JobList info={TodayDOJ(employees)}></JobList>
                </div>
                <div className="dashboard-stat">
                  <div className="board">
                    <p className="upcoming text-dark">
                      Upcoming Job Anniversary
                    </p>
                    <hr />
                    <JobList
                      info={UpcomingDOJ(employees, 3)}
                      Upcoming
                    ></JobList>
                  </div>
                </div>
                <div className="employee-section">
                  <div className="luhaif-holiday-content">
                    <div className="holiday-heading">
                      <h3>Holidays <strong> List 2024</strong></h3>
                    </div>
                    <div className="holiday-table">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Holiday</th>
                            <th scope="col">Date</th>
                            <th scope="col">Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td >Republic Day </td>
                            <td>26-01-2024</td>
                            <td>Friday</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td >Holi</td>
                            <td>25-03-2024</td>
                            <td>Monday</td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td >Id-ul-Fitr </td>
                            <td>11-04-2024</td>
                            <td>Thursday</td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td >Ram Navami</td>
                            <td>17-04-2024</td>
                            <td>Wednesday</td>
                          </tr>
                          <tr>
                            <th scope="row">4</th>
                            <td >Id-ul-Zuha (Bakrid) </td>
                            <td>17-06-2023</td>
                            <td>Monday</td>
                          </tr>
                          <tr>
                            <th scope="row">5</th>
                            <td >Muharram </td>
                            <td>17-07-2024</td>
                            <td>Wednesday</td>
                          </tr>
                          <tr>
                            <th scope="row">6</th>
                            <td >Independence Day </td>
                            <td>15-08-2024</td>
                            <td>Thursday</td>
                          </tr>
                          <tr>
                            <th scope="row">6</th>
                            <td >Rakha Bandhan </td>
                            <td>19-08-2024</td>
                            <td>Monday</td>
                          </tr>
                          <tr>
                            <th scope="row">7</th>
                            <td >Janmastmi </td>
                            <td>26-08-2024</td>
                            <td>Monday</td>
                          </tr>
                          <tr>
                            <th scope="row">8</th>
                            <td >Mahatma Gandhi’s Birthday </td>
                            <td>02-10-2024</td>
                            <td>Wednesday</td>
                          </tr>
                          <tr>
                            <th scope="row">9</th>
                            <td >Dussehra </td>
                            <td>12-10-2024</td>
                            <td>Saturday</td>
                          </tr>
                          <tr>
                            <th scope="row">10</th>
                            <td >Diwali (Deepawali) </td>
                            <td>31-10-2024</td>
                            <td>Thursday</td>
                          </tr>
                          <tr>
                            <th scope="row">11</th>
                            <td >Guru Nanak’s Birthday </td>
                            <td>15-Nov-2024</td>
                            <td>Friday</td>
                          </tr>
                          <tr>
                            <th scope="row">12</th>
                            <td >Christmas Day </td>
                            <td>25-12-2024</td>
                            <td>Wednesday</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div>
                  <EmployeeOfMonth
                    image={'/images/employee-of-month/manmendar.jpeg'}
                    name={"Manmender"}
                    short_desc={"Congratulations on delivering the most amazing work Luhaif Digitech 🎉"}
                  />
                </div>
                <div className="employee-section ">
                  <img src="/images/eid_mubarak.jpg" alt="" />
                  <hr />
                  <div className="employee-section-title">
                    <h5>Eid Mubarak 🌙</h5>
                    <span>
                      <p></p>
                    </span>
                    <h5>
                      Wish you a very Happy Eid Mubarak!! <br /> -
                      Luhaif Digitech
                    </h5>
                  </div>
                </div>
                <div className="employee-section">
                  <img src="/images/ld-winner.jpeg" alt="" />
                  <hr />
                  <div className="employee-section-title">
                    <h5>Congratulations on your well-deserved victory! You're an inspiration! 🎉
                      <br /> -
                      Luhaif Digitech</h5>
                    <span>
                      <p></p>
                    </span>

                  </div>
                </div>
                {
                  // <ChristmasCard />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
