import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import ReactModal from 'react-modal';
import * as Yup from 'yup';
import { CheckBox } from '../Form/Form';
import moment from 'moment';
export const ChecklistModal = ({
  modal,
  setModal,
  activity,
  setActivity,
  updateStatus,
}) => {
  return (
    <div>
      <ReactModal
        isOpen={modal}
        contentLabel='Modal'
        className='Modal'
        overlayClassName='Overlay'
        onRequestClose={() => {
          setModal(false);
          setActivity(null);
        }}
      >
        {activity && (
          <div className='quick-view'>
            <div className='qv-header'>
              <div className='title'> Checklist </div>
              <div>
                <button
                  onClick={() => {
                    setModal(false);
                    setActivity(null);
                  }}
                  className='btn btn-primary'
                >
                  <i className='fa fa-times'></i>
                </button>
              </div>
            </div>
            {activity.checklist && activity.checklist.length > 0 && (
              <div className='qv-body'>
                <Formik
                  initialValues={{
                    task_name:
                      activity && activity.task_name ? activity.task_name : '',
                    assigned_to:
                      activity &&
                        activity.assigned_to &&
                        activity.assigned_to._id
                        ? activity.assigned_to._id
                        : '',
                    activity_cycle:
                      activity && activity.activity_cycle
                        ? activity.activity_cycle._id
                        : '',
                    due_time:
                      activity && activity.due_time
                        ? moment(activity.due_time).format('yyyy-MM-DDThh:mm')
                        : '',
                    client:
                      activity && activity.client && activity.client._id
                        ? activity.client._id
                        : '',
                    process:
                      activity && activity.process && activity.process._id
                        ? activity.process._id
                        : '',
                    checklist: activity.checklist ? activity.checklist : [],
                    estimated_time:
                      activity && activity.estimated_time
                        ? activity.estimated_time
                        : '',
                    actual_time:
                      activity && activity.actual_time
                        ? activity.actual_time
                        : '',
                    task_description:
                      activity && activity.task_description
                        ? activity.task_description
                        : '',
                    attachments:
                      activity && activity.attachments
                        ? activity.attachments
                        : '',
                    review_status:
                      activity &&
                        activity.review_status &&
                        activity.review_status._id
                        ? activity.review_status._id
                        : '',
                    activity_status:
                      activity &&
                        activity.activity_status &&
                        activity.activity_status._id
                        ? activity.activity_status._id
                        : '',
                    comments:
                      activity && activity.comments ? activity.comments : '',
                  }}
                  validationSchema={Yup.object({})}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    // addData(values);
                    // submitFormClicked(values);
                    updateStatus(activity._id, values);
                    setModal(false);
                    setActivity(null);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <Form>
                        <div style={{ padding: '20px' }}>
                          <FieldArray
                            name='checklist'
                            render={(arrayHelper) => {
                              return (
                                <div>
                                  {formik.values.checklist &&
                                    formik.values.checklist.map(
                                      (item, index) => {
                                        return (
                                          <div className='card-body-received-data d-flex'>
                                            <div className='col-md'>
                                              <CheckBox
                                                // label={item.name}
                                                name={`checklist[${index}].is_checked`}
                                                type='checkbox'
                                              >
                                                {item.name}
                                              </CheckBox>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              );
                            }}
                          ></FieldArray>
                          <button className='btn btn-success'>Save</button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            )}
          </div>
        )}
      </ReactModal>
    </div>
  );
};
