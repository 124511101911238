export const PAGE_TITLE = "Ads";
export const PAGE_SINGLE_TITLE = "Ad";
export const LINK_URL = "ads";
export const inputFields = {
  ad_name: {
    type: "string",
    required: true,
    title: "Ad Name",
    inputType: "text",
  },
  benchmark: {
    type: "string",
    required: true,
    title: "Benchmark",
    inputType: "number",
  },
  platform: {
    type: "select",
    required: true,
    title: "Platform",
    inputType: "select",
    options: ["Facebook", "Google", "Instagram", "Other"],
  },

  assigned_to: {
    type: "related",
    required: true,
    title: "Assigned To",
    inputType: "text",
    field: "name",
  },
  due_time: {
    type: "string",
    required: true,
    title: "Due Time",
    inputType: "datetime-local",
  },
  client: {
    type: "related",
    required: true,
    title: "Client",
    inputType: "text",
    field: "name",
  },
  estimated_time: {
    type: "string",
    required: false,
    title: "Estimated Time",
    inputType: "text",
  },
  attachments: {
    type: "gallery",
    required: false,
    title: "Attachments",
    inputType: "text",
    multiple: true,
  },
  review_status: {
    type: "related",
    required: true,
    title: "Review Status",
    inputType: "text",
    field: "name",
  },
  activity_status: {
    type: "related",
    required: true,
    title: "Activity Status",
    inputType: "text",
    field: "name",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [{ name: "Name", value: "name" }];

export const SIDEBAR_OPTIONS = [
  {
    id: "ad_name",
    field: "ad_name",
    label: "Ad Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "assigned_to",
    field: "assigned_to",
    label: "Assigned To",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "due_time",
    field: "due_time",
    label: "Due Time",
    type: "string",
    search_type: "conditional",
    inputType: "datetime-local",
    condition: "$gte",
  },
  {
    id: "client",
    field: "client",
    label: "Client",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "process",
    field: "process",
    label: "Process",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "activity_status",
    field: "activity_status",
    label: "Activity Status",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "review_status",
    field: "review_status",
    label: "Review Status",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "date_from",
    field: "createdAt",
    label: "Date From",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "date_to",
    field: "createdAt",
    label: "Date To",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
];

export const inputFieldsImport = {
  ad_type: {
    type: "select",
    required: true,
    title: "Ad Type",
    inputType: "select",
    options: ["Facebook", "Google"],
  },
  client: {
    type: "related",
    required: true,
    title: "Client",
    inputType: "text",
    field: "name",
  },
  image: {
    type: "file",
    required: false,
    title: "Attach Report",
    inputType: "text",
    // field: "name",
  },
};

export const initialValuesImport = {
  ad_type: "",
  client: "",
};
