import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

export const UseFilter = () => {
  const [activeBtn, setActiveBtn] = useState('Lifetime');
  var now = new Date();
  var todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  let weekStartDate = moment().startOf('isoweek').toDate();
  let weekEndsDate = moment().endOf('isoweek').toDate();
  useEffect(() => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));

    // console.log("Search Params", queryParams["date_type"]);

    if (queryParams['date_type']) {
      setActiveBtn(queryParams['date_type']);
    } else {
      setActiveBtn('Lifetime');
    }
  }, [window.location.search]);
  return [activeBtn, todayDate, weekStartDate, weekEndsDate];
};
export const UseSearchFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const handleSidebarChange = ({ name, value, type = 'search', condition }) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (type == 'conditional') {
      if (queryParams[type]) {
        if (queryParams[type][name]) {
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        } else {
          queryParams[type][name] = {};
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        }
      } else {
        queryParams[type] = {};
        queryParams[type][name] = {};
        if (value && value.length > 0) {
          queryParams[type][name][condition ? condition : '$gte'] = value;
        } else {
          delete queryParams[type][name][condition ? condition : '$gte'];
        }
      }
    } else {
      if (queryParams[type]) {
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      } else {
        queryParams[type] = {};
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      }
    }

    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    // console.log("PARAMS", params.toString());
    // console.log("QUERY", `${location.pathname}?${query}`);
    history.push(`${location.pathname}?${query}`);
  };
  const getSidebarValue = ({ search_type, field, condition }) => {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // return urlParams.get(name);
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (search_type) {
      const searchValue =
        search_type == 'conditional'
          ? queryParams[search_type] &&
            queryParams[search_type][field] &&
            queryParams[search_type][field][condition]
          : queryParams[search_type]
          ? queryParams[search_type][field]
          : '';
      return searchValue;
    }
  };
  return { handleSidebarChange, getSidebarValue };
};
