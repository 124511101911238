import api from '../../domain/api';
import {
  GET_ACTIVITYS_STATED,
  GET_ACTIVITYS,
  GET_ACTIVITYS_ENDED,
  ADD_ACTIVITY_STATED,
  ADD_ACTIVITY,
  ADD_ACTIVITY_ENDED,
  EDIT_ACTIVITY_STATED,
  EDIT_ACTIVITY,
  EDIT_ACTIVITY_ENDED,
  GET_ACTIVITY_STATED,
  GET_ACTIVITY,
  GET_ACTIVITY_ENDED,
  GET_ALL_ACTIVITYS_STATED,
  GET_ALL_ACTIVITYS,
  GET_ALL_ACTIVITYS_ENDED,
  GET_ACTIVITYS_BY_EMPLOYEE_STARTED,
  GET_ACTIVITYS_BY_EMPLOYEE,
  GET_ACTIVITYS_BY_EMPLOYEE_ENDED,
  GET_ADS_CLIENT_ACTIVITIES_STARTED,
  GET_ADS_CLIENT_ACTIVITIES,
  GET_ADS_CLIENT_ACTIVITIES_ENDED,
  ADD_ADS_CLIENT_ACTIVITY_ENDED,
  ADD_ADS_CLIENT_ACTIVITY_STARTED,
  EDIT_ADS_CLIENT_ACTIVITY_STARTED,
  EDIT_ADS_CLIENT_ACTIVITY_ENDED,
} from '../types/activity_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';

export const addActivity = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ACTIVITY_STATED,
    });
    const { data } = await api.post(`/activitys`, formData);
    dispatch({
      type: ADD_ACTIVITY,
      payload: data,
    });
    dispatch({
      type: ADD_ACTIVITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_ACTIVITY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const getAdsActivities = (client) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADS_CLIENT_ACTIVITIES_STARTED,
    });
    const { data } = await api.get(`/activitys/ads-activities/${client}`);
    dispatch({
      type: GET_ADS_CLIENT_ACTIVITIES,
      payload: data,
    });
    dispatch({
      type: GET_ADS_CLIENT_ACTIVITIES_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ADS_CLIENT_ACTIVITIES_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const addAdsActivity =
  ({ client, formData, activity_type }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_ADS_CLIENT_ACTIVITY_STARTED,
        payload: activity_type,
      });
      const { data } = await api.post(
        `/activitys/ads-activities/${client}/add`,
        formData
      );
      dispatch({
        type: GET_ADS_CLIENT_ACTIVITIES,
        payload: data,
      });
      dispatch({
        type: ADD_ADS_CLIENT_ACTIVITY_ENDED,
      });
    } catch (error) {
      dispatch({
        type: ADD_ADS_CLIENT_ACTIVITY_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const updateAdsActivity =
  ({ activity, formData, activity_type }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDIT_ADS_CLIENT_ACTIVITY_STARTED,
        payload: activity_type,
      });
      const { data } = await api.put(
        `/activitys/ads-activities/${activity}/update`,
        formData
      );
      dispatch({
        type: GET_ADS_CLIENT_ACTIVITIES,
        payload: data,
      });
      dispatch({
        type: EDIT_ADS_CLIENT_ACTIVITY_ENDED,
      });
    } catch (error) {
      dispatch({
        type: EDIT_ADS_CLIENT_ACTIVITY_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const quickAddActivity = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ACTIVITY_STATED,
    });
    const { data } = await api.post(`/activitys/quick`, formData);
    dispatch({
      type: GET_ACTIVITYS,
      payload: data,
    });
    dispatch({
      type: ADD_ACTIVITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_ACTIVITY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const addBulkActivity = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ACTIVITY_STATED,
    });
    const { data } = await api.post(`/activitys/bulk`, formData);
    dispatch({
      type: ADD_ACTIVITY,
      payload: data,
    });
    dispatch({
      type: ADD_ACTIVITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_ACTIVITY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getActivitys =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ACTIVITYS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/activitys?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_ACTIVITYS,
        payload: data,
      });
      dispatch({
        type: GET_ACTIVITYS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ACTIVITYS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const getActivitysByEmployee =
  ({ pageNumber = '', employee }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ACTIVITYS_BY_EMPLOYEE_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/activitys/employee/${employee}?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_ACTIVITYS_BY_EMPLOYEE,
        payload: data,
      });
      dispatch({
        type: GET_ACTIVITYS_BY_EMPLOYEE_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ACTIVITYS_BY_EMPLOYEE_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getActivitysByCycle = (activity_cycle) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACTIVITYS_STATED,
    });

    const { data } = await api.get(
      `/activitys?exact[activity_cycle]=${activity_cycle}`
    );

    dispatch({
      type: GET_ACTIVITYS,
      payload: data,
    });
    dispatch({
      type: GET_ACTIVITYS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ACTIVITYS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getActivity = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACTIVITY_STATED,
    });
    const { data } = await api.get(`/activitys/${id}`);

    dispatch({
      type: GET_ACTIVITY,
      payload: data,
    });
    dispatch({
      type: GET_ACTIVITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ACTIVITY_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editActivity = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_ACTIVITY_STATED,
    });
    const { data } = await api.put(`/activitys/${id}`, formData);
    dispatch({
      type: EDIT_ACTIVITY,
      payload: data,
    });
    dispatch({
      type: EDIT_ACTIVITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_ACTIVITY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteActivity = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/activitys/${id}`);
    dispatch(setAlert('Activity Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllActivitys =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_ACTIVITYS_STATED,
      });
      const { data } = await api.get(
        `/activitys/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_ACTIVITYS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_ACTIVITYS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_ACTIVITYS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
