import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  HTMLInput,
  SelectBox,
  TextArea,
  TextInput,
} from '../../components/Form/Form';
import {
  useAddAdsActivity,
  useGetDropdownOptions,
} from '../../shared/hooks/UseActivity';
import {
  ADS_ACTIVITY_ENUM,
  ADS_ACTIVITY_TYPES,
  ADS_PLATFORM_TYPES,
} from '../../domain/constant';

export const AddAdsActivityComponent = ({ dropdownOptions, client }) => {
  const { addAdActivityHandler } = useAddAdsActivity(client);
  const handleFormSubmit = (values) => {
    addAdActivityHandler(values, values.activity_type);
  };
  const [showPlatform, setShowPlatform] = useState(false);
  const [showAdsId, setShowAdsId] = useState(false);
  const [showBenchmark, setShowBenchmark] = useState(false);
  return (
    <div className='single-client-ads-activity-form'>
      <h4 className=' mb-4'>Add Activity</h4>
      <Formik
        initialValues={{
          task_name: '',
          assigned_to: '',
          due_time: '',
          process: '',
          activity_status: '',
          review_status: '',
          task_description: '',
          ads_id: '',
          benchmark: '',
          ads_platform_type: '',
        }}
        validationSchema={Yup.object({
          task_name: Yup.string().required('Required'),
          assigned_to: Yup.string().required('Required'),
          due_time: Yup.string().required('Required'),
          process: Yup.string().required('Required'),
          activity_status: Yup.string().required('Required'),
          ads_platform_type: showPlatform && Yup.string().required('Required'),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleFormSubmit(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => {
          console.log(formik);
          return (
            <Form>
              <div className='row'>
                <div className='col-md-6'>
                  <TextInput label='Task Name' name='task_name' type='text' />
                </div>
                <div className='col-md-6'>
                  <SelectBox
                    label='Activity Type'
                    name='activity_type'
                    onChange={(e) => {
                      formik.setFieldValue('activity_type', e.target.value);
                      if (e.target.value === ADS_ACTIVITY_ENUM.CAMPAIGN) {
                        setShowAdsId(true);
                        setShowBenchmark(true);
                        setShowPlatform(true);
                        formik.setFieldValue('ads_id', '');
                        formik.setFieldValue('benchmark', '');
                        formik.setFieldValue('ads_platform_type', '');
                      } else {
                        setShowAdsId(false);
                        setShowBenchmark(false);
                        setShowPlatform(false);
                        formik.setFieldValue('ads_id', '');
                        formik.setFieldValue('benchmark', '');
                        formik.setFieldValue('ads_platform_type', '');
                      }
                    }}
                  >
                    <option value=''>Select </option>
                    {ADS_ACTIVITY_TYPES &&
                      ADS_ACTIVITY_TYPES.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                  </SelectBox>
                </div>
                {showPlatform && (
                  <div className='col-md-6'>
                    <SelectBox label='Platform' name='ads_platform_type'>
                      <option value=''>Select </option>
                      {ADS_PLATFORM_TYPES &&
                        ADS_PLATFORM_TYPES.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </SelectBox>
                  </div>
                )}
                {showAdsId && (
                  <div className='col-md-6'>
                    <TextInput label='Ads ID' name='ads_id' type='text' />
                  </div>
                )}

                {showBenchmark && (
                  <div className='col-md-6'>
                    <TextInput
                      label='Benchmark'
                      name='benchmark'
                      type='number'
                    />
                  </div>
                )}

                <div className='col-md-6'>
                  <SelectBox label='Assigned To' name='assigned_to'>
                    <option value=''> Select Assigned to </option>
                    {dropdownOptions.assigned_to &&
                      dropdownOptions.assigned_to.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                  </SelectBox>
                </div>
                <div className='col-md-6'>
                  <TextInput
                    label='Due Time'
                    name='due_time'
                    type='datetime-local'
                  />
                </div>
                <div className='col-md-6'>
                  <SelectBox label='Process' name='process'>
                    <option value=''> Select Process </option>
                    {dropdownOptions.process &&
                      dropdownOptions.process.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                  </SelectBox>
                </div>

                <div className='col-md-6'>
                  <SelectBox label='Review Status' name='review_status'>
                    <option value=''> Select Review Status </option>
                    {dropdownOptions.review_status &&
                      dropdownOptions.review_status.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                  </SelectBox>
                </div>
                <div className='col-md-6'>
                  <SelectBox label='Activity Status' name='activity_status'>
                    <option value=''> Select Activity Status </option>
                    {dropdownOptions.activity_status &&
                      dropdownOptions.activity_status.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                  </SelectBox>
                </div>
                <div className='col-md-12'>
                  <TextArea
                    label={'Description'}
                    name='task_description'
                    value={formik.values.task_description}
                  />
                </div>
                <div className='client-modal-form-btn text-center'>
                  <button className='btn btn-success m-1' type='submit'>
                    Save
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
