import api from "../../domain/api";
import {
  GET_LEADCOMMENTS_STATED,
  GET_LEADCOMMENTS,
  GET_LEADCOMMENTS_ENDED,
  ADD_LEADCOMMENT_STATED,
  ADD_LEADCOMMENT,
  ADD_LEADCOMMENT_ENDED,
  EDIT_LEADCOMMENT_STATED,
  EDIT_LEADCOMMENT,
  EDIT_LEADCOMMENT_ENDED,
  GET_LEADCOMMENT_STATED,
  GET_LEADCOMMENT,
  GET_LEADCOMMENT_ENDED,
  GET_ALL_LEADCOMMENTS_STATED,
  GET_ALL_LEADCOMMENTS,
  GET_ALL_LEADCOMMENTS_ENDED,
} from "../types/leadcomment_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addLeadcomment = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_LEADCOMMENT_STATED,
    });
    const { data } = await api.post(`/leadcomments`, formData);
    dispatch({
      type: ADD_LEADCOMMENT,
      payload: data,
    });
    dispatch({
      type: ADD_LEADCOMMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_LEADCOMMENT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getLeadcomments = (lead) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LEADCOMMENTS_STATED,
    });

    const { data } = await api.get(`/leadcomments?lead=${lead}`);

    dispatch({
      type: GET_LEADCOMMENTS,
      payload: data,
    });
    dispatch({
      type: GET_LEADCOMMENTS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_LEADCOMMENTS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getLeadcomment = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LEADCOMMENT_STATED,
    });
    const { data } = await api.get(`/leadcomments/${id}`);

    dispatch({
      type: GET_LEADCOMMENT,
      payload: data,
    });
    dispatch({
      type: GET_LEADCOMMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_LEADCOMMENT_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editLeadcomment = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_LEADCOMMENT_STATED,
    });
    const { data } = await api.put(`/leadcomments/${id}`, formData);
    dispatch({
      type: EDIT_LEADCOMMENT,
      payload: data,
    });
    dispatch({
      type: EDIT_LEADCOMMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_LEADCOMMENT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteLeadcomment = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/leadcomments/${id}`);
    dispatch(setAlert("Leadcomment Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllLeadcomments =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_LEADCOMMENTS_STATED,
      });
      const { data } = await api.get(
        `/leadcomments/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_LEADCOMMENTS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_LEADCOMMENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_LEADCOMMENTS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
