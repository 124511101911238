import React from 'react'
import Header from '../../components/template/Header'

function MyProfile() {
    return (
        <div className="pace-done">
            <Header />
            <div className="header">
                {/*Content before waves*/}
                <div className="inner-header flex">
                    {/*Just the logo.. Don't mind this*/}
                    <svg
                        version="1.1"
                        baseProfile="tiny"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 500 500"
                        xmlSpace="preserve"
                    >
                        <path
                            fill="#FFFFFF"
                            stroke="#000000"
                            strokeWidth={10}
                            strokeMiterlimit={10}
                            d="M57,283"
                        />
                        <g></g>
                    </svg>
                </div>

            </div>

            <section className='my-profile ptb-40'>
                <div className="container">
                    <div className="row my-profile-card-box">
                        <div className="col-md-5">
                            <div className="my-profile-img">
                                <img src="/images/iktiyar-bhai.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="my-profile-content">
                                <div className="my-profile-content-heading">
                                    <a href=""><h2>Ikhtiyar Badar</h2></a>
                                </div>
                                <div className="my-profile-content-para">
                                    <h4>Hello, I have 5+ years of experience in Graphic Designing</h4>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Designation: <span>Senior Graphic Designer</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Status: <span>Working</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Reporting Manager: <span>Test User</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Designation: <span>Senior Graphic Designer</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Wish Me On: <span>1 Nov</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Date of Joining: <span>23 Nov</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Email: <span>1khtiyar@test.com</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Phone: <span>+91-9999999999</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Address: <span>E16 Derma Puritys GK1, Delhi</span></p>
                                </div>
                                <div className="my-profile-content-para">
                                    <p>Emergency Contact No.: <span>+91-8888888888</span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MyProfile