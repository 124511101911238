import {
  GET_GROUPS_STATED,
  GET_GROUPS,
  GET_GROUPS_ENDED,
  ADD_GROUP_STATED,
  ADD_GROUP,
  ADD_GROUP_ENDED,
  EDIT_GROUP_STATED,
  EDIT_GROUP,
  EDIT_GROUP_ENDED,
  GET_GROUP_STATED,
  GET_GROUP,
  GET_GROUP_ENDED,
  GET_ALL_GROUPS_STATED,
  GET_ALL_GROUPS,
  GET_ALL_GROUPS_ENDED
} from "../types/group_type";

const initialState = {
  groups_loading: true,
  groups: null,
  page: null,
  pages: null,
  total_groups: 0,

  group: null,
  group_loading: null,

  loading: true,

  group_message: null,
  all_groups: null,
  all_groups_loading: null,
  add_group_loading: true,
  edit_group_loading: true
};

export const group_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GROUPS_STATED:
      return {
        ...state,
        groups: null,
        pages: null,
        page: null,
        total_groups: 0,
        groups_loading: true
      };
    case GET_GROUPS:
      return {
        ...state,
        groups: payload.groups,
        pages: payload.pages,
        page: payload.page,
        total_groups: payload.count
      };
    case GET_GROUPS_ENDED:
      return {
        ...state,
        groups_loading: false
      };
    case GET_ALL_GROUPS_STATED:
      return {
        ...state,
        all_groups_loading: true,
        all_groups: null
      };
    case GET_ALL_GROUPS:
      return {
        ...state,
        all_groups: payload
      };
    case GET_ALL_GROUPS_ENDED:
      return {
        ...state,
        all_groups_loading: false
      };

    case ADD_GROUP_STATED:
      return {
        ...state,
        group_message: null,
        add_group_loading: true
      };
    case ADD_GROUP:
      return {
        ...state,
        group_message: payload
      };
    case ADD_GROUP_ENDED:
      return {
        ...state,
        add_group_loading: false
      };
    case GET_GROUP_STATED:
      return {
        ...state,
        group: null,
        group_loading: true
      };
    case GET_GROUP:
      return {
        ...state,
        group: payload
      };
    case GET_GROUP_ENDED:
      return {
        ...state,
        group_loading: false
      };
    case EDIT_GROUP_STATED:
      return {
        ...state,
        group_message: null,
        edit_group_loading: true
      };
    case EDIT_GROUP:
      return {
        ...state,
        group_message: payload
      };
    case EDIT_GROUP_ENDED:
      return {
        ...state,
        edit_group_loading: false
      };

    default:
      return state;
  }
};
