import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addBill,
  getBills,
  getBill,
  editBill,
  deleteBill,
  getAllBills,
} from "../../store/actions/bill_action";
import _debounce from "lodash/debounce";
import { useSelectAllClient } from "./UseClient";
import { useSelectAllBankaccount } from "./UseBankaccount";
import { useSelectAllEmployee } from "./UseEmployee";
import { useSelectAllBillreview } from "./UseBillreview";
import { useSelectAllServicepackage } from "./UseServicepackage";

// Get All Data
export const useAllBills = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bill);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteBill(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getBills({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (reloadData) {
      allQuery();
      setReloadData(false);
    }
  }, [reloadData]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData];
};

// Get Single Data
export const useSingleBill = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bill);
  useEffect(() => {
    dispatch(getBill(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateBill = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bill);
  const addData = async (data) => {
    await dispatch(addBill(data));
  };
  return [data, addData];
};
export const useUpdateBill = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.bill);
  const updateData = async (id, data) => {
    await dispatch(editBill(id, data));
  };
  return [updateData];
};

export const useSelectAllBill = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.bill);
  useEffect(() => {
    dispatch(getAllBills({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [client, setClientSearchField, setClientSearchValue] =
    useSelectAllClient();
  const [bankaccount, setBankaccountSearchField, setBankaccountSearchValue] =
    useSelectAllBankaccount();
  const [employee, setEmployeeSearchField, setEmployeeSearchValue] =
    useSelectAllEmployee();
  const [billreview, setBillreviewSearchField, setBillreviewSearchValue] =
    useSelectAllBillreview();
  const [
    servicepackage,
    setServicepackageSearchField,
    setServicepackageSearchValue,
  ] = useSelectAllServicepackage();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, client: newData });
    }
  }, [client]);
  useEffect(() => {
    if (bankaccount && bankaccount.all_bankaccounts) {
      const newData = bankaccount.all_bankaccounts.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, bank_account: newData });
    }
  }, [bankaccount]);
  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, collected_by: newData });
    }
  }, [employee]);
  useEffect(() => {
    if (billreview && billreview.all_billreviews) {
      const newData = billreview.all_billreviews.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, review_status: newData });
    }
  }, [billreview]);
  useEffect(() => {
    if (servicepackage && servicepackage.all_servicepackages) {
      const newData = servicepackage.all_servicepackages.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, package: newData });
    }
  }, [servicepackage]);

  return [dropdownOptions];
};
