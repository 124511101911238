export const GET_BANKACCOUNTS_STATED = "GET_BANKACCOUNTS_STATED";
export const GET_BANKACCOUNTS = "GET_BANKACCOUNTS";
export const GET_BANKACCOUNTS_ENDED = "GET_BANKACCOUNTS_ENDED";
export const ADD_BANKACCOUNT_STATED = "ADD_BANKACCOUNT_STARTED";
export const ADD_BANKACCOUNT = "ADD_BANKACCOUNT";
export const ADD_BANKACCOUNT_ENDED = "ADD_BANKACCOUNT_ENDED";
export const EDIT_BANKACCOUNT_STATED = "EDIT_BANKACCOUNT_STATED";
export const EDIT_BANKACCOUNT = "EDIT_BANKACCOUNT";
export const EDIT_BANKACCOUNT_ENDED = "EDIT_BANKACCOUNT_ENDED";
export const GET_BANKACCOUNT = "GET_BANKACCOUNT";
export const GET_BANKACCOUNT_STATED = "GET_BANKACCOUNT_STATED";
export const GET_BANKACCOUNT_ENDED = "GET_BANKACCOUNT_ENDED";
export const RESET_BANKACCOUNT = "RESET_BANKACCOUNT";
export const ERROR_BANKACCOUNT = "ERROR_BANKACCOUNT";
export const GET_ALL_BANKACCOUNTS_STATED = "GET_ALL_BANKACCOUNTS_STATED";
export const GET_ALL_BANKACCOUNTS = "GET_ALL_BANKACCOUNTS";
export const GET_ALL_BANKACCOUNTS_ENDED = "GET_ALL_BANKACCOUNTS_ENDED";
