import React from 'react'
import Header from '../../components/template/Header'

function Event() {
    return (
        <div>
            <Header />
            <section className="events-section ptb-180-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-text">
                                <div className="event-sec-title-barbie">
                                    <a href="https://in.bookmyshow.com/delhi/movies/barbie/ET00072466" target="_blank">
                                        <h3>Barbie has Arrived!</h3>
                                    </a>
                                </div>
                                <div className="event-sec-short-desc">
                                    <p>Are you going to Barbie land with us? <span>Do watch the movie on 21st July!!</span></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row event-section-card-box">
                        <div className="col-md-5">
                            <div className="events-images">
                                <iframe width={444} height={250} src="https://www.youtube.com/embed/pBk4NYhWNMM?start=1" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="events-content">
                                <div className="events-content-heading">
                                    <h4>Barbie</h4>
                                </div>
                                <div className="event-content-para">
                                    <p>Barbie suffers a crisis that leads her to question her world and her existence.</p>
                                </div>
                                <div className="event-content-para">
                                    <p>Director: <span>Greta Gerwig</span></p>
                                </div>
                                <div className="event-content-para">
                                    <p>Writers: <span>Greta Gerwig, Noah Baumbach</span></p>
                                </div>
                                <div className="event-content-para">
                                    <p>Stars: <span>Margot Robbie, Ariana Greenblatt, Kingsley Ben-Adir</span></p>
                                </div>
                                <div className="events-btn">
                                    {
                                        // <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/playlist/37i9dQZF1DX0wIBbVnPvie?utm_source=generator" width="70%" height={90} frameBorder={0} allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />
                                    }

                                    <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/track/7njDhlprmHJ1I9pM0rxMON?utm_source=generator" width="70%" height={90} frameBorder={0} allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="events-section ptb-20">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="heading-text">
                                {
                                    // <div className="popcorn_icon">
                                    //     <img className='shape-2 movebounce-03' src="/images/popcorn_sm.png" alt="" />
                                    // </div>
                                }
                                <div className="event-sec-title-opph">
                                    <a href="https://in.bookmyshow.com/delhi/movies/oppenheimer/ET00347867" target="_blank">
                                        <h3>Oppenheimer is Here!</h3>
                                    </a>
                                </div>
                                <div className="event-sec-short-desc-opph">
                                    <p>A Film By Christopher Nolan <span>Releasing on 21st July</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row event-section-card-box">
                        <div className="col-md-7">
                            <div className="events-content">
                                <div className="events-content-heading-opph">
                                    <h4>Oppenheimer</h4>
                                </div>
                                <div className="event-content-para-opph">
                                    <p>The story of American scientist J. Robert Oppenheimer and his role in the development of the atomic bomb.</p>
                                </div>
                                <div className="event-content-para-opph">
                                    <p>Director: <span>Christopher Nolan</span></p>
                                </div>
                                <div className="event-content-para-opph">
                                    <p>Writers: <span>Christopher Nolan, Kai Bird, Martin Sherwin</span></p>
                                </div>
                                <div className="event-content-para-opph">
                                    <p>Stars: <span>Cillian Murphy, Emily Blunt, Matt Damon</span></p>
                                </div>
                                <div className="events-btn">
                                    <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/track/1EJUCdDSBSwcjTFSV4cCDG?utm_source=generator" width="70%" height={90} frameBorder={0} allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="events-images-opph">
                                <iframe width={444} height={250} src="https://www.youtube.com/embed/uYPbbksJxIg" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Event