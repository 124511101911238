import React, { useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { QuickActivityForm } from "../activity/QuickActivityForm";

function QuickAddBtn({ title, }) {
    const [modal, setModal] = useState(false)
    return (
        <div className="row">
            <div className="col-sm">
                <div className="mb-4">
                    <button
                        onClick={() => setModal(true)}
                        type="button"
                        className="btn btn-primary
                                    waves-effect waves-light"
                    >
                        <i className="fas fa-code-branch me-1" /> {title}
                    </button>
                </div>
            </div>
            <QuickActivityForm modal={modal} setModal={setModal} />
        </div>
    )
}

export default QuickAddBtn