export const GET_STATUSS_STATED = "GET_STATUSS_STATED";
export const GET_STATUSS = "GET_STATUSS";
export const GET_STATUSS_ENDED = "GET_STATUSS_ENDED";
export const ADD_STATUS_STATED = "ADD_STATUS_STARTED";
export const ADD_STATUS = "ADD_STATUS";
export const ADD_STATUS_ENDED = "ADD_STATUS_ENDED";
export const EDIT_STATUS_STATED = "EDIT_STATUS_STATED";
export const EDIT_STATUS = "EDIT_STATUS";
export const EDIT_STATUS_ENDED = "EDIT_STATUS_ENDED";
export const GET_STATUS = "GET_STATUS";
export const GET_STATUS_STATED = "GET_STATUS_STATED";
export const GET_STATUS_ENDED = "GET_STATUS_ENDED";
export const RESET_STATUS = "RESET_STATUS";
export const ERROR_STATUS = "ERROR_STATUS";
export const GET_ALL_STATUSS_STATED = "GET_ALL_STATUSS_STATED";
export const GET_ALL_STATUSS = "GET_ALL_STATUSS";
export const GET_ALL_STATUSS_ENDED = "GET_ALL_STATUSS_ENDED";
