import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/layout/Spinner";
import AdForm from "../../components/ads/AdForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/ads_enum";
import {
  useUpdateAd,
  useSingleAd,
  useGetDropdownOptions,
} from "../../shared/hooks/UseAd";
import AddForm from "../../components/common/AddForm";
// import { useSelectAllAd } from "../../shared/hooks/UseAd";

const EditAd = ({ match }) => {
  let history = useHistory();
  const [single_data] = useSingleAd(match.params.id);
  const [updateData] = useUpdateAd();
  const { ad_loading, ad, edit_ad_loading } = single_data;
  const [featuredImage, setFeaturedImage] = useState(null);

  // const [dropdownOptions] = useGetDropdownOptions();
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await updateData(ad._id, data);
    history.push(`/${LINK_URL}/${ad._id}/view`);
  };
  const [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
  ] = useGetDropdownOptions();

  const loadOptions = async (inputValue, callback, field) => {
    if (field == "assigned_to") {
      setEmployeeSearchField("name");
      setEmployeeSearchValue(inputValue);
      callback(dropdownOptions.assigned_to);
    }
    if (field == "client") {
      setClientSearchField("name");
      setClientSearchValue(inputValue);
      callback(dropdownOptions.client);
    }
    if (field == "process") {
      setProcessSearchField("name");
      setProcessSearchValue(inputValue);
      callback(dropdownOptions.process);
    }
    if (field == "activity_status") {
      setActivitystatusSearchField("name");
      setActivitystatusSearchValue(inputValue);
      callback(dropdownOptions.activity_status);
    }
    if (field == "review_status") {
      setReviewstatusSearchField("name");
      setReviewstatusSearchValue(inputValue);
      callback(dropdownOptions.review_status);
    }
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Edit ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Edit"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            {!ad_loading ? (
              ad && (
                <div>
                  <div className="card-header">
                    <div>
                      <h4 className="card-title">
                        {ad[Object.keys(inputFields)[0]]} - <span>Edit</span>{" "}
                      </h4>
                      <p className="card-title-desc">
                        <Link
                          to={`/${LINK_URL}`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-angle-left"></i> {PAGE_TITLE}
                        </Link>
                        <Link
                          to={`/${LINK_URL}/${ad._id}/view`}
                          className="btn btn-soft-light"
                        >
                          <i className="fa fa-eye"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <AddForm
                    data={ad}
                    edit={true}
                    featuredImage={featuredImage}
                    setFeaturedImage={setFeaturedImage}
                    submitForm={submitFormClicked}
                    inputFields={inputFields}
                    initialValues={initialValues}
                    dropdown_options={dropdownOptions}
                    loading={edit_ad_loading}
                    loadOptions={loadOptions}
                  />
                </div>
              )
            ) : (
              <div className="text-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAd;
