import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import AddBtn from "../../components/common/AddBtn";
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/leads_enum";
import DataTable from "../../components/common/DataTable";
import { useAllLeads, useGetDropdownOptions } from "../../shared/hooks/UseLead";
import SidebarFilter from "../../components/common/SidebarFilter";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";
import ExportComponent from "../../components/common/ExportComponent";
import { useSelector } from "react-redux";
import { LIMIT } from "../../domain/constant";
import moment from "moment";
import ReactModal from "react-modal";
import SingleView from "../../components/common/SingleView";
import Spinner from "../../components/layout/Spinner";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextArea, TextInput } from "../../components/Form/Form";
import { Link } from "react-router-dom";
import {
  useAllLeadComments,
  useCreateLeadcomment,
  useSelectAllLeadcomment,
} from "../../shared/hooks/UseLeadcomment";
import { convertToFormData } from "../../shared/upload";

const AllLeads = ({}) => {
  const [data, setPageNumber, deleteBtnClicked] = useAllLeads();
  const { leads_loading, leads, total_leads, page, pages } = data;
  const [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
  ] = useGetDropdownOptions();
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "client") {
    //   await setClientSearchField("name");
    //   await setClientSearchValue(inputValue);
    //   callback(dropdownOptions.client);
    // }
  };

  const [comment_data, setLead, setReloadComments] = useAllLeadComments();
  const { leadcomments, leadcomments_loading } = comment_data;
  const [commentData, addData] = useCreateLeadcomment();
  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values });
    await addData(data);
    setReloadComments(true);

    // history.push(`/${LINK_URL}`);
  };

  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (leads) {
      convertToReadable(leads, inputFields);
    }
  }, [leads]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Leads", "leads");
  };
  const userData = useSelector((state) => state.auth);
  const { user } = userData;

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editField, setEditField] = useState(null);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />
        <ExportComponent handleOnExport={handleOnExport} />
        {user && (
          <FilterDateComponent
            link="/leads"
            showOwn={true}
            searchParams={{ term: "created_by", value: user._id }}
          />
        )}

        <div className="container-fluid">
          <div className="row">
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
              />
            )}
            <div className="col-lg-9">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  {total_leads} records found
                  <div>
                    {/* <DataTable
                      keys={view_all_table}
                      data={leads}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={leads_loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    /> */}
                    <table className="table table-new align-middle table-striped  table-bordered">
                      {!leads_loading && (
                        <thead>
                          <tr className="bg-transparent" role="row">
                            <th style={{ width: "50px" }}>#</th>
                            <th>Date</th>
                            <th> Name </th>
                            <th> Phone </th>
                            <th> Email </th>
                            <th> Whatsapp No </th>
                            <th> Group </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      )}

                      <tbody>
                        {!leads_loading ? (
                          leads &&
                          leads.map((single, index) => {
                            if (editId && editId == single._id) {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT}</td>
                                  <td>
                                    {" "}
                                    {single.createdAt &&
                                      moment(single.createdAt).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                  </td>
                                  <td> {single.name} </td>
                                  <td>{single.phone}</td>
                                  <td>{single.email}</td>
                                  <td>{single.whatsapp_no}</td>

                                  <td> {single.group} </td>

                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        setLead(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT}</td>
                                  <td>
                                    {" "}
                                    {single.createdAt &&
                                      moment(single.createdAt).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                  </td>
                                  <td> {single.name} </td>
                                  <td>{single.phone}</td>
                                  <td>{single.email}</td>
                                  <td>{single.whatsapp_no}</td>

                                  <td> {single.group} </td>

                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        setLead(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={20} className="text-center">
                              <Spinner />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <Pagination
                      data={leads}
                      page={page}
                      pages={pages}
                      count={total_leads}
                      setPage={setPageNumber}
                      loading={leads_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={() => {
          setModal(false);
        }}
      >
        <div className="quick-view">
          <div className="qv-header">
            <div className="title"> {PAGE_SINGLE_TITLE} </div>
            <div>
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
          {modalData && (
            <>
              <div className="qv-body">
                <SingleView
                  hideGallery={true}
                  data={modalData}
                  inputFields={inputFields}
                  label={PAGE_SINGLE_TITLE}
                  link={LINK_URL}
                  id={modalData._id}
                  hideAllBtn={true}
                  col={12}
                />
                <div className="card">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        comment: "",
                      }}
                      validationSchema={Yup.object({
                        comment: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        values.activity = modalData._id;
                        await submitFormClicked(values);
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row align-items-center">
                              <div className="col-md-9">
                                <TextArea
                                  label="Comment"
                                  name="comment"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  {" "}
                                  {formik.isSubmitting
                                    ? "SAVING..."
                                    : "SAVE"}{" "}
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <table className="table table-stripped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Comment</th>
                          <th>Comment By</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!leadcomments_loading ? (
                          <>
                            {leadcomments &&
                              leadcomments.map((item, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1} </td>
                                    <td> {item.comment} </td>
                                    <td>
                                      {" "}
                                      {item.created_by &&
                                        item.created_by.name}{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      {item.createdAt &&
                                        moment(item.createdAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <p> Loading... </p>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AllLeads;
