export const GET_EMPLOYEEREPORTS_STATED = "GET_EMPLOYEEREPORTS_STATED";
export const GET_EMPLOYEEREPORTS = "GET_EMPLOYEEREPORTS";
export const GET_EMPLOYEEREPORTS_ENDED = "GET_EMPLOYEEREPORTS_ENDED";
export const ADD_EMPLOYEEREPORT_STATED = "ADD_EMPLOYEEREPORT_STARTED";
export const ADD_EMPLOYEEREPORT = "ADD_EMPLOYEEREPORT";
export const ADD_EMPLOYEEREPORT_ENDED = "ADD_EMPLOYEEREPORT_ENDED";
export const EDIT_EMPLOYEEREPORT_STATED = "EDIT_EMPLOYEEREPORT_STATED";
export const EDIT_EMPLOYEEREPORT = "EDIT_EMPLOYEEREPORT";
export const EDIT_EMPLOYEEREPORT_ENDED = "EDIT_EMPLOYEEREPORT_ENDED";
export const GET_EMPLOYEEREPORT = "GET_EMPLOYEEREPORT";
export const GET_EMPLOYEEREPORT_STATED = "GET_EMPLOYEEREPORT_STATED";
export const GET_EMPLOYEEREPORT_ENDED = "GET_EMPLOYEEREPORT_ENDED";
export const RESET_EMPLOYEEREPORT = "RESET_EMPLOYEEREPORT";
export const ERROR_EMPLOYEEREPORT = "ERROR_EMPLOYEEREPORT";
export const GET_ALL_EMPLOYEEREPORTS_STATED = "GET_ALL_EMPLOYEEREPORTS_STATED";
export const GET_ALL_EMPLOYEEREPORTS = "GET_ALL_EMPLOYEEREPORTS";
export const GET_ALL_EMPLOYEEREPORTS_ENDED = "GET_ALL_EMPLOYEEREPORTS_ENDED";
