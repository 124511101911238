import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/employeereports_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleEmployeereport } from "../../shared/hooks/UseEmployeereport";
const ViewEmployeereport = ({ match }) => {
  const [data] = useSingleEmployeereport(match.params.id);
  const { employeereport_loading, employeereport } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />

        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!employeereport_loading ? (
          employeereport && (
            <SingleView
              data={employeereport}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={employeereport._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewEmployeereport;
