import {
  GET_LEADS_STATED,
  GET_LEADS,
  GET_LEADS_ENDED,
  ADD_LEAD_STATED,
  ADD_LEAD,
  ADD_LEAD_ENDED,
  EDIT_LEAD_STATED,
  EDIT_LEAD,
  EDIT_LEAD_ENDED,
  GET_LEAD_STATED,
  GET_LEAD,
  GET_LEAD_ENDED,
  GET_ALL_LEADS_STATED,
  GET_ALL_LEADS,
  GET_ALL_LEADS_ENDED
} from "../types/lead_type";

const initialState = {
  leads_loading: true,
  leads: null,
  page: null,
  pages: null,
  total_leads: 0,

  lead: null,
  lead_loading: null,

  loading: true,

  lead_message: null,
  all_leads: null,
  all_leads_loading: null,
  add_lead_loading: true,
  edit_lead_loading: true
};

export const lead_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADS_STATED:
      return {
        ...state,
        leads: null,
        pages: null,
        page: null,
        total_leads: 0,
        leads_loading: true
      };
    case GET_LEADS:
      return {
        ...state,
        leads: payload.leads,
        pages: payload.pages,
        page: payload.page,
        total_leads: payload.count
      };
    case GET_LEADS_ENDED:
      return {
        ...state,
        leads_loading: false
      };
    case GET_ALL_LEADS_STATED:
      return {
        ...state,
        all_leads_loading: true,
        all_leads: null
      };
    case GET_ALL_LEADS:
      return {
        ...state,
        all_leads: payload
      };
    case GET_ALL_LEADS_ENDED:
      return {
        ...state,
        all_leads_loading: false
      };

    case ADD_LEAD_STATED:
      return {
        ...state,
        lead_message: null,
        add_lead_loading: true
      };
    case ADD_LEAD:
      return {
        ...state,
        lead_message: payload
      };
    case ADD_LEAD_ENDED:
      return {
        ...state,
        add_lead_loading: false
      };
    case GET_LEAD_STATED:
      return {
        ...state,
        lead: null,
        lead_loading: true
      };
    case GET_LEAD:
      return {
        ...state,
        lead: payload
      };
    case GET_LEAD_ENDED:
      return {
        ...state,
        lead_loading: false
      };
    case EDIT_LEAD_STATED:
      return {
        ...state,
        lead_message: null,
        edit_lead_loading: true
      };
    case EDIT_LEAD:
      return {
        ...state,
        lead_message: payload
      };
    case EDIT_LEAD_ENDED:
      return {
        ...state,
        edit_lead_loading: false
      };

    default:
      return state;
  }
};
