import {
  GET_ACTIVITYCYCLES_STATED,
  GET_ACTIVITYCYCLES,
  GET_ACTIVITYCYCLES_ENDED,
  ADD_ACTIVITYCYCLE_STATED,
  ADD_ACTIVITYCYCLE,
  ADD_ACTIVITYCYCLE_ENDED,
  EDIT_ACTIVITYCYCLE_STATED,
  EDIT_ACTIVITYCYCLE,
  EDIT_ACTIVITYCYCLE_ENDED,
  GET_ACTIVITYCYCLE_STATED,
  GET_ACTIVITYCYCLE,
  GET_ACTIVITYCYCLE_ENDED,
  GET_ALL_ACTIVITYCYCLES_STATED,
  GET_ALL_ACTIVITYCYCLES,
  GET_ALL_ACTIVITYCYCLES_ENDED
} from "../types/activitycycle_type";

const initialState = {
  activitycycles_loading: true,
  activitycycles: null,
  page: null,
  pages: null,
  total_activitycycles: 0,

  activitycycle: null,
  activitycycle_loading: null,

  loading: true,

  activitycycle_message: null,
  all_activitycycles: null,
  all_activitycycles_loading: null,
  add_activitycycle_loading: true,
  edit_activitycycle_loading: true
};

export const activitycycle_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ACTIVITYCYCLES_STATED:
      return {
        ...state,
        activitycycles: null,
        pages: null,
        page: null,
        total_activitycycles: 0,
        activitycycles_loading: true
      };
    case GET_ACTIVITYCYCLES:
      return {
        ...state,
        activitycycles: payload.activitycycles,
        pages: payload.pages,
        page: payload.page,
        total_activitycycles: payload.count
      };
    case GET_ACTIVITYCYCLES_ENDED:
      return {
        ...state,
        activitycycles_loading: false
      };
    case GET_ALL_ACTIVITYCYCLES_STATED:
      return {
        ...state,
        all_activitycycles_loading: true,
        all_activitycycles: null
      };
    case GET_ALL_ACTIVITYCYCLES:
      return {
        ...state,
        all_activitycycles: payload
      };
    case GET_ALL_ACTIVITYCYCLES_ENDED:
      return {
        ...state,
        all_activitycycles_loading: false
      };

    case ADD_ACTIVITYCYCLE_STATED:
      return {
        ...state,
        activitycycle_message: null,
        add_activitycycle_loading: true
      };
    case ADD_ACTIVITYCYCLE:
      return {
        ...state,
        activitycycle_message: payload
      };
    case ADD_ACTIVITYCYCLE_ENDED:
      return {
        ...state,
        add_activitycycle_loading: false
      };
    case GET_ACTIVITYCYCLE_STATED:
      return {
        ...state,
        activitycycle: null,
        activitycycle_loading: true
      };
    case GET_ACTIVITYCYCLE:
      return {
        ...state,
        activitycycle: payload
      };
    case GET_ACTIVITYCYCLE_ENDED:
      return {
        ...state,
        activitycycle_loading: false
      };
    case EDIT_ACTIVITYCYCLE_STATED:
      return {
        ...state,
        activitycycle_message: null,
        edit_activitycycle_loading: true
      };
    case EDIT_ACTIVITYCYCLE:
      return {
        ...state,
        activitycycle_message: payload
      };
    case EDIT_ACTIVITYCYCLE_ENDED:
      return {
        ...state,
        edit_activitycycle_loading: false
      };

    default:
      return state;
  }
};
