import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/processs_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleProcess } from "../../shared/hooks/UseProcess";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import renderHTML from "react-render-html";
import moment from "moment";

const ViewProcess = ({ match }) => {
  const [data] = useSingleProcess(match.params.id);
  const { process_loading, process } = data;

  console.log("Process -", process);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {
          !process_loading ? (
            process && (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <div>
                          <h4 className="card-title">
                            {process.name}
                            <span>View</span>
                          </h4>
                          <p className="card-title-desc">

                            <Link to={`/`} className="btn btn-soft-light">
                              <i className="fa fa-angle-left"></i> Process
                            </Link>

                            <Link
                              to={`/`}
                              className="btn btn-soft-light"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                          </p>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Name</div>
                              <div className="card-body-received-data">
                                {process.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Description</div>
                              <div className="card-body-received-data">
                                {process.description && renderHTML(process.description)}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Created By</div>
                              <div className="card-body-received-data">
                                {process.created_by && process.created_by.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Created At</div>
                              <div className="card-body-received-data">
                                {moment(process.createdAt).format('MM/DD/YYYY h:mm:ss a')}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-body-single-data">
                              <div className="title">Updated At</div>
                              <div className="card-body-received-data">
                                {moment(process.updatedAt).format('MM/DD/YYYY h:mm:ss a')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-side">
                          <h4>Standard operating procedure:</h4>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="card-body-single-data">
                          <div className="card-body-received-datalist">
                            {
                              process.sop && process.sop.map((item, index) => {
                                return (
                                  <div className="card-body-received-data d-flex">
                                    <div className="checklist-data-list-img">
                                      <img src="/images/sop_3.png" alt="" />
                                    </div>
                                    <div className="checklist-data-content">
                                      {renderHTML(item.name)}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <div className="card-title-side">
                          <h4>Checklist:</h4>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="card-body-single-data">
                          <div className="card-body-received-datalist">
                            {
                              process.sop && process.checklist.map((item) => {
                                return (
                                  <div className="card-body-received-data d-flex">
                                    <div className="checklist-data-list-img">
                                      <img src="/images/checklist_3.png" alt="" />
                                    </div>
                                    <div className="checklist-data-content">
                                      {renderHTML(item.name)}
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div>
              <Spinner />
            </div>
          )
        }

      </div>
    </div>
  );
};

export default ViewProcess;
