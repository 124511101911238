import api from "../../domain/api";
import {
  GET_BILLREVIEWS_STATED,
  GET_BILLREVIEWS,
  GET_BILLREVIEWS_ENDED,
  ADD_BILLREVIEW_STATED,
  ADD_BILLREVIEW,
  ADD_BILLREVIEW_ENDED,
  EDIT_BILLREVIEW_STATED,
  EDIT_BILLREVIEW,
  EDIT_BILLREVIEW_ENDED,
  GET_BILLREVIEW_STATED,
  GET_BILLREVIEW,
  GET_BILLREVIEW_ENDED,
  GET_ALL_BILLREVIEWS_STATED,
  GET_ALL_BILLREVIEWS,
  GET_ALL_BILLREVIEWS_ENDED,
} from "../types/billreview_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addBillreview = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_BILLREVIEW_STATED,
    });
    const { data } = await api.post(`/billreviews`, formData);
    dispatch({
      type: ADD_BILLREVIEW,
      payload: data,
    });
    dispatch({
      type: ADD_BILLREVIEW_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_BILLREVIEW_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getBillreviews =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_BILLREVIEWS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/billreviews?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_BILLREVIEWS,
        payload: data,
      });
      dispatch({
        type: GET_BILLREVIEWS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_BILLREVIEWS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getBillreview = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BILLREVIEW_STATED,
    });
    const { data } = await api.get(`/billreviews/${id}`);

    dispatch({
      type: GET_BILLREVIEW,
      payload: data,
    });
    dispatch({
      type: GET_BILLREVIEW_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_BILLREVIEW_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editBillreview = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BILLREVIEW_STATED,
    });
    const { data } = await api.put(`/billreviews/${id}`, formData);
    dispatch({
      type: EDIT_BILLREVIEW,
      payload: data,
    });
    dispatch({
      type: EDIT_BILLREVIEW_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_BILLREVIEW_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteBillreview = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/billreviews/${id}`);
    dispatch(setAlert("Billreview Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllBillreviews = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_BILLREVIEWS_STATED,
    });
    const { data } = await api.get(`/billreviews/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_BILLREVIEWS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_BILLREVIEWS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_BILLREVIEWS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
