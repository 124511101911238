import api from "../../domain/api";
import {
  GET_COMMENTS_STATED,
  GET_COMMENTS,
  GET_COMMENTS_ENDED,
  ADD_COMMENT_STATED,
  ADD_COMMENT,
  ADD_COMMENT_ENDED,
  EDIT_COMMENT_STATED,
  EDIT_COMMENT,
  EDIT_COMMENT_ENDED,
  GET_COMMENT_STATED,
  GET_COMMENT,
  GET_COMMENT_ENDED,
  GET_ALL_COMMENTS_STATED,
  GET_ALL_COMMENTS,
  GET_ALL_COMMENTS_ENDED,
} from "../types/comment_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addComment = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_COMMENT_STATED,
    });
    const { data } = await api.post(`/comments`, formData);
    dispatch({
      type: ADD_COMMENT,
      payload: data,
    });
    dispatch({
      type: ADD_COMMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_COMMENT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getComments = (activity) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMMENTS_STATED,
    });

    const { data } = await api.get(`/comments?&activity=${activity}`);

    dispatch({
      type: GET_COMMENTS,
      payload: data,
    });
    dispatch({
      type: GET_COMMENTS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_COMMENTS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getComment = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMMENT_STATED,
    });
    const { data } = await api.get(`/comments/${id}`);

    dispatch({
      type: GET_COMMENT,
      payload: data,
    });
    dispatch({
      type: GET_COMMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_COMMENT_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editComment = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COMMENT_STATED,
    });
    const { data } = await api.put(`/comments/${id}`, formData);
    dispatch({
      type: EDIT_COMMENT,
      payload: data,
    });
    dispatch({
      type: EDIT_COMMENT_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_COMMENT_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteComment = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/comments/${id}`);
    dispatch(setAlert("Comment Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllComments =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_COMMENTS_STATED,
      });
      const { data } = await api.get(
        `/comments/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_COMMENTS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_COMMENTS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_COMMENTS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
