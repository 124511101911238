export const GET_PROCESSS_STATED = "GET_PROCESSS_STATED";
export const GET_PROCESSS = "GET_PROCESSS";
export const GET_PROCESSS_ENDED = "GET_PROCESSS_ENDED";
export const ADD_PROCESS_STATED = "ADD_PROCESS_STARTED";
export const ADD_PROCESS = "ADD_PROCESS";
export const ADD_PROCESS_ENDED = "ADD_PROCESS_ENDED";
export const EDIT_PROCESS_STATED = "EDIT_PROCESS_STATED";
export const EDIT_PROCESS = "EDIT_PROCESS";
export const EDIT_PROCESS_ENDED = "EDIT_PROCESS_ENDED";
export const GET_PROCESS = "GET_PROCESS";
export const GET_PROCESS_STATED = "GET_PROCESS_STATED";
export const GET_PROCESS_ENDED = "GET_PROCESS_ENDED";
export const RESET_PROCESS = "RESET_PROCESS";
export const ERROR_PROCESS = "ERROR_PROCESS";
export const GET_ALL_PROCESSS_STATED = "GET_ALL_PROCESSS_STATED";
export const GET_ALL_PROCESSS = "GET_ALL_PROCESSS";
export const GET_ALL_PROCESSS_ENDED = "GET_ALL_PROCESSS_ENDED";
