export const GET_EMPLOYEES_STATED = "GET_EMPLOYEES_STATED";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEES_ENDED = "GET_EMPLOYEES_ENDED";
export const ADD_EMPLOYEE_STATED = "ADD_EMPLOYEE_STARTED";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_EMPLOYEE_ENDED = "ADD_EMPLOYEE_ENDED";
export const EDIT_EMPLOYEE_STATED = "EDIT_EMPLOYEE_STATED";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const EDIT_EMPLOYEE_ENDED = "EDIT_EMPLOYEE_ENDED";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_STATED = "GET_EMPLOYEE_STATED";
export const GET_EMPLOYEE_ENDED = "GET_EMPLOYEE_ENDED";
export const RESET_EMPLOYEE = "RESET_EMPLOYEE";
export const ERROR_EMPLOYEE = "ERROR_EMPLOYEE";
export const GET_ALL_EMPLOYEES_STATED = "GET_ALL_EMPLOYEES_STATED";
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_ALL_EMPLOYEES_ENDED = "GET_ALL_EMPLOYEES_ENDED";
