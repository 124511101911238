import api from "../../domain/api";
import {
  GET_REMARKS_STATED,
  GET_REMARKS,
  GET_REMARKS_ENDED,
  ADD_REMARK_STATED,
  ADD_REMARK,
  ADD_REMARK_ENDED,
  EDIT_REMARK_STATED,
  EDIT_REMARK,
  EDIT_REMARK_ENDED,
  GET_REMARK_STATED,
  GET_REMARK,
  GET_REMARK_ENDED,
  GET_ALL_REMARKS_STATED,
  GET_ALL_REMARKS,
  GET_ALL_REMARKS_ENDED,
} from "../types/remark_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addRemark = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_REMARK_STATED,
    });
    const { data } = await api.post(`/remarks`, formData);
    dispatch({
      type: ADD_REMARK,
      payload: data,
    });
    dispatch({
      type: ADD_REMARK_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_REMARK_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getRemarks =
  ({ pageNumber = "" }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_REMARKS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/remarks?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_REMARKS,
        payload: data,
      });
      dispatch({
        type: GET_REMARKS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_REMARKS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getRemark = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REMARK_STATED,
    });
    const { data } = await api.get(`/remarks/${id}`);

    dispatch({
      type: GET_REMARK,
      payload: data,
    });
    dispatch({
      type: GET_REMARK_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_REMARK_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editRemark = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_REMARK_STATED,
    });
    const { data } = await api.put(`/remarks/${id}`, formData);
    dispatch({
      type: EDIT_REMARK,
      payload: data,
    });
    dispatch({
      type: EDIT_REMARK_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_REMARK_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteRemark = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/remarks/${id}`);
    dispatch(setAlert("Remark Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllRemarks =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_REMARKS_STATED,
      });
      const { data } = await api.get(
        `/remarks/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_REMARKS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_REMARKS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_REMARKS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
