import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AddForm from "../../components/common/AddForm";
import { convertToFormData } from "../../shared/upload";
import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/activitys_enum";
import {
  useCreateActivity,
  useGetDropdownOptions,
} from "../../shared/hooks/UseActivity";
// import { useSelectAllActivity } from "../../shared/hooks/UseActivity";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { CheckBox, SelectBox, TextArea, TextInput } from "../../components/Form/Form";
import slugify from "react-slugify";
import moment from "moment";
import EditorWYSIWYG from "../../components/EditorWYSIWYG";
import AddActivityForm from "../../components/activity/AddActivityForm";
import { useSelectAllProcess } from "../../shared/hooks/UseProcess"
import { useAllActivitys } from "../../shared/hooks/UseActivity";


const AddActivity = ({ data }) => {
  let history = useHistory();
  const [process_data] = useSelectAllProcess()
  const { all_processs } = process_data
  const [activity, addData] = useCreateActivity();
  const { add_activity_loading } = activity;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [imagePicked, setImagePicked] = useState(null);
  const pickImage = ({ field, value }) => {
    // console.log("CALLING", field);
    let imagesCollection = imagePicked ? imagePicked : {};
    imagesCollection[field] = value;
    console.log("FILES", imagesCollection);
    setImagePicked(imagesCollection);
    setFeaturedImage(imagesCollection);
  };

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    console.log("FEATURED IMAGE -", featuredImage);

    await addData(data);
    history.push(`/${LINK_URL}`);
  };

  const [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
    setActivitycycleSearchField,
    setActivitycycleSearchValue,
  ] = useGetDropdownOptions();

  const loadOptions = async (inputValue, callback, field) => {
    if (field == "assigned_to") {
      setEmployeeSearchField("name");
      setEmployeeSearchValue(inputValue);
      callback(dropdownOptions.assigned_to);
    }
    if (field == "activity_cycle") {
      setActivitycycleSearchField("name");
      setActivitycycleSearchValue(inputValue);
      callback(dropdownOptions.activity_cycle);
    }
    if (field == "client") {
      setClientSearchField("name");
      setClientSearchValue(inputValue);
      callback(dropdownOptions.client);
    }
    if (field == "process") {
      setProcessSearchField("name");
      setProcessSearchValue(inputValue);
      callback(dropdownOptions.process);
    }
    if (field == "activity_status") {
      setActivitystatusSearchField("name");
      setActivitystatusSearchValue(inputValue);
      callback(dropdownOptions.activity_status);
    }
    if (field == "review_status") {
      setReviewstatusSearchField("name");
      setReviewstatusSearchValue(inputValue);
      callback(dropdownOptions.review_status);
    }
  };

  const [requiredCheck, setRequiredCheck] = useState({});
  const [customData, setCustomData] = useState(null);

  const [checkedList, setCheckedList] = useState([]);

  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      //Add checked item into checkList
      setCheckedList([...checkedList, value]);
    } else {
      //Remove unchecked item from checkList
      const filteredList = checkedList.filter((item) => item !== value);
      setCheckedList(filteredList);
    }
  };


  console.log("checkedList -", checkedList);

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="Add"
        />
      </div>

      <div className="add-activity-form-section">
        {
          <div className="container-fluid">
            <Formik
              initialValues={{
                task_name: "",
                assigned_to: "",
                activity_cycle: "",
                due_time: "",
                client: "",
                process: "",
                // sop: "",
                sop: "",
                checklist: [],
                estimated_time: "",
                actual_time: "",
                task_description: "",
                attachments: "",
                review_status: "",
                activity_status: "",
                comments: "",
              }}
              validationSchema={Yup.object({
                task_name: Yup.string().required("Required"),
                assigned_to: Yup.string().required("Required"),
                due_time: Yup.string().required("Required"),
                client: Yup.string().required("Required"),
                process: Yup.string().required("Required"),
                review_status: Yup.string().required("Required"),
                activity_status: Yup.string().required("Required"),
              })}
              onSubmit={async (
                values,
                { setSubmitting, resetForm }
              ) => {
                setSubmitting(true);
                // addData(values);
                submitFormClicked(values)
                resetForm();
                setSubmitting(false);
              }}
            >
              {(formik) => {
                console.log(formik);
                return (
                  <Form>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="add-activity-form-box">
                          <div className="add-activity-form-header">
                            <div className="add-activity-form-title">
                              Activity
                            </div>
                            <div className="add-activity-form-desc">
                              Enter Details to add Activity
                            </div>
                          </div>
                          <hr />

                          <div className="add-activity-form">
                            <div className="row">
                              <div className="col-md-6">
                                <TextInput
                                  label="Task Name"
                                  name="task_name"
                                  type="text"
                                  placeholder="Task Name"
                                />
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="Assigned to"
                                  name="assigned_to"
                                  placeholder="Enter Assigned to">
                                  <option value=""> Select Assigned to </option>
                                  {dropdownOptions.assigned_to && dropdownOptions.assigned_to.map((item) => {
                                    return (
                                      <option value={item.value}> {item.label} </option>
                                    )
                                  })}
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="Activity Cycle"
                                  name="activity_cycle"
                                  placeholder="Select">
                                  <option value=""> Select Assigned to </option>
                                  {dropdownOptions.activity_cycle && dropdownOptions.activity_cycle.map((item) => {
                                    return (
                                      <option value={item.value}> {item.label} </option>
                                    )
                                  })}
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <TextInput
                                  label="Due Time"
                                  name="due_time"
                                  type="datetime-local"
                                  placeholder="Enter Due Time"
                                />
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="Client"
                                  name="client"
                                  placeholder="Enter Process">
                                  <option value=""> Select Client </option>
                                  {dropdownOptions.client && dropdownOptions.client.map((item) => {
                                    return (
                                      <option value={item.value}> {item.label} </option>
                                    )
                                  })}
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="Process"
                                  name="process"
                                  placeholder="Enter Process"
                                  onChange={(e) => {
                                    formik.setFieldValue("process", e.target.value);
                                    formik.setFieldValue("checklist", [])
                                    if (all_processs) {
                                      const filteredData = all_processs.filter((item, index) => item._id == e.target.value);
                                      if (filteredData && filteredData.length > 0) {
                                        const checklistData = filteredData[0].checklist
                                        if (checklistData && checklistData.length > 0) {
                                          const checklistDataMapped = checklistData.map((item) => {
                                            return {
                                              name: item.name,
                                              is_checked: false
                                            }
                                          })
                                          formik.setFieldValue("checklist", checklistDataMapped)
                                        }
                                      }
                                    }
                                    // all_processs && all_processs.filter((item, index) => item._id == formik.values.process) && all_processs.filter((item) => item._id == formik.values.process)[0] && all_processs.filter((item) => item._id == formik.values.process)[0].checklist && all_processs.filter((item) => item._id == formik.values.process)[0].checklist
                                  }}
                                >
                                  <option value=""> Select Process </option>
                                  {dropdownOptions.process && dropdownOptions.process.map((item) => {
                                    return (
                                      <option value={item.value}> {item.label} </option>
                                    )
                                  })}
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <TextInput
                                  label="Estimated Time(in mins)"
                                  name="estimated_time"
                                  type="number"
                                  placeholder="Enter Estimated time(in mins)"
                                />
                              </div>
                              <div className="col-md-6">
                                <TextInput
                                  label="Actual Time"
                                  name="actual_time"
                                  type="number"
                                  placeholder="Enter Actual Time(in mins)"
                                />
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label> Task Description </label>
                                  <EditorWYSIWYG
                                    value={formik.values.task_description ? formik.values.task_description : ""}
                                    name={"task_description"}
                                    changeValue={(value) => {
                                      formik.setFieldValue("task_description", value);
                                    }}
                                  />
                                  {formik.errors && formik.errors["task_description"] && (
                                    <p className="text-danger"> Required </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <label> Attachments </label>
                                <br />
                                <input
                                  type="file"
                                  multiple
                                  className="form-control-file"
                                  onChange={(e) =>
                                    pickImage({ field: "attachments", value: e.target.files })
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="Review Status"
                                  name="review_status"
                                  placeholder="Enter Review Status">
                                  <option value=""> Select Review Status </option>
                                  {dropdownOptions.review_status && dropdownOptions.review_status.map((item) => {
                                    return (
                                      <option value={item.value}>{item.label}</option>
                                    )
                                  })}
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <SelectBox
                                  label="Activity Status"
                                  name="activity_status"
                                  placeholder="Enter Activity Status">
                                  <option value=""> Select Activity Status </option>
                                  {dropdownOptions.activity_status && dropdownOptions.activity_status.map((item) => {
                                    return (
                                      <option value={item.value}>{item.label}</option>
                                    )
                                  })}
                                </SelectBox>
                              </div>
                              <div className="col-md-6">
                                <TextArea
                                  label="Comments"
                                  name="comment"
                                  type="text"
                                  placeholder="Enter Comments"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 text-center m-3">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  {formik.isSubmitting ? "Processing..." : "Save"}
                                </button>
                                <a
                                  className="btn btn-secondary m-3"
                                  // onClick={history.goBack}
                                  href="#goback"
                                >
                                  <i className="fa fa-angle-left"></i> Go Back
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-header">
                            <div className="card-title-side">
                              <h4>Checklist:{Formik && Formik.process && Formik.process.name}</h4>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="card-body-single-data">
                              <div className="card-body-received-datalist">
                                <FieldArray name="checklist" render={(arrayHelper) => {
                                  return (
                                    <div>
                                      {formik.values.checklist && formik.values.checklist.map((item, index) => {
                                        console.log("IM HERE");
                                        console.log("ITEM -", item);
                                        return (
                                          <div className="card-body-received-data checkbox-points d-flex">
                                            <div className="col-md-12">
                                              <CheckBox
                                                // label={item.name}
                                                name={`checklist[${index}].is_checked`}
                                                type="checkbox"
                                              >
                                                {item.name}
                                              </CheckBox>
                                            </div>
                                          </div>
                                        )
                                      })}

                                    </div>
                                  )
                                }}>
                                </FieldArray>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <div className="card-title-side">
                              <h4>SOP:</h4>
                              <p>Standard Operating Procedure</p>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="card-body-single-data">
                              <div className="card-body-received-datalist">
                                {all_processs && all_processs.filter((item) => item._id == formik.values.process) && all_processs.filter((item) => item._id == formik.values.process)[0] && all_processs.filter((item) => item._id == formik.values.process)[0].sop && all_processs.filter((item) => item._id == formik.values.process)[0].sop.map((item) => {
                                  return (
                                    <div className="card-body-received-data d-flex">
                                      <div className="checklist-data-list-img">
                                        <img src="/images/sop_3.png" alt="" />
                                      </div>
                                      <div className="checklist-data-content">
                                        <div dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                      </div>
                                    </div>
                                  )
                                })}


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        }
      </div>
      {
        // <div className="container-fluid">
        //   <div className="col-lg-9">
        //     <div className="card">
        //       <div className="card-header">
        //         <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
        //         <p className="card-title-desc">
        //           Enter Details to add {PAGE_SINGLE_TITLE}
        //         </p>
        //       </div>
        //       <AddForm
        //         edit={false}
        //         featuredImage={featuredImage}
        //         gallery={gallery}
        //         setFeaturedImage={setFeaturedImage}
        //         setGallery={setGallery}
        //         submitForm={submitFormClicked}
        //         inputFields={inputFields}
        //         initialValues={initialValues}
        //         dropdown_options={dropdownOptions}
        //         loading={add_activity_loading}
        //         loadOptions={loadOptions}
        //       />
        //     </div>
        //   </div>
        // </div>
      }
    </div>
  );
};

export default AddActivity;
