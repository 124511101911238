import {
  GET_BANKACCOUNTS_STATED,
  GET_BANKACCOUNTS,
  GET_BANKACCOUNTS_ENDED,
  ADD_BANKACCOUNT_STATED,
  ADD_BANKACCOUNT,
  ADD_BANKACCOUNT_ENDED,
  EDIT_BANKACCOUNT_STATED,
  EDIT_BANKACCOUNT,
  EDIT_BANKACCOUNT_ENDED,
  GET_BANKACCOUNT_STATED,
  GET_BANKACCOUNT,
  GET_BANKACCOUNT_ENDED,
  GET_ALL_BANKACCOUNTS_STATED,
  GET_ALL_BANKACCOUNTS,
  GET_ALL_BANKACCOUNTS_ENDED
} from "../types/bankaccount_type";

const initialState = {
  bankaccounts_loading: true,
  bankaccounts: null,
  page: null,
  pages: null,
  total_bankaccounts: 0,

  bankaccount: null,
  bankaccount_loading: null,

  loading: true,

  bankaccount_message: null,
  all_bankaccounts: null,
  all_bankaccounts_loading: null,
  add_bankaccount_loading: true,
  edit_bankaccount_loading: true
};

export const bankaccount_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BANKACCOUNTS_STATED:
      return {
        ...state,
        bankaccounts: null,
        pages: null,
        page: null,
        total_bankaccounts: 0,
        bankaccounts_loading: true
      };
    case GET_BANKACCOUNTS:
      return {
        ...state,
        bankaccounts: payload.bankaccounts,
        pages: payload.pages,
        page: payload.page,
        total_bankaccounts: payload.count
      };
    case GET_BANKACCOUNTS_ENDED:
      return {
        ...state,
        bankaccounts_loading: false
      };
    case GET_ALL_BANKACCOUNTS_STATED:
      return {
        ...state,
        all_bankaccounts_loading: true,
        all_bankaccounts: null
      };
    case GET_ALL_BANKACCOUNTS:
      return {
        ...state,
        all_bankaccounts: payload
      };
    case GET_ALL_BANKACCOUNTS_ENDED:
      return {
        ...state,
        all_bankaccounts_loading: false
      };

    case ADD_BANKACCOUNT_STATED:
      return {
        ...state,
        bankaccount_message: null,
        add_bankaccount_loading: true
      };
    case ADD_BANKACCOUNT:
      return {
        ...state,
        bankaccount_message: payload
      };
    case ADD_BANKACCOUNT_ENDED:
      return {
        ...state,
        add_bankaccount_loading: false
      };
    case GET_BANKACCOUNT_STATED:
      return {
        ...state,
        bankaccount: null,
        bankaccount_loading: true
      };
    case GET_BANKACCOUNT:
      return {
        ...state,
        bankaccount: payload
      };
    case GET_BANKACCOUNT_ENDED:
      return {
        ...state,
        bankaccount_loading: false
      };
    case EDIT_BANKACCOUNT_STATED:
      return {
        ...state,
        bankaccount_message: null,
        edit_bankaccount_loading: true
      };
    case EDIT_BANKACCOUNT:
      return {
        ...state,
        bankaccount_message: payload
      };
    case EDIT_BANKACCOUNT_ENDED:
      return {
        ...state,
        edit_bankaccount_loading: false
      };

    default:
      return state;
  }
};
