import React from 'react';
import { useAllAdsReviews } from '../../shared/hooks/UseAdsReview';
import moment from 'moment';
import { EditFieldComponent } from './EditFieldComponent';
import { EditReviewFieldComponent } from './EditReviewFieldComponent';
import { ADS_ACTIVITY_ENUM, INPUT_TYPE_ENUM } from '../../domain/constant';
export const ReviewComponent = ({ client, dropdownOptions }) => {
  const { ads_reviews_data } = useAllAdsReviews(client);
  const { ads_reviews } = ads_reviews_data;
  return (
    <div className='col-md-12'>
      <div className='single-client-reviews-table'>
        <h4 className='mb-4'>Reviews:</h4>
        <table className='table table-sm border '>
          <thead>
            <tr>
              <th scope='col'>Sr. No.</th>
              <th scope='col'>Review Date</th>
              <th scope='col'>Campaign</th>
              <th scope='col'> Review Title </th>
              <th scope='col'>Notes</th>
              <th scope='col'>Assigned To</th>
              <th scope='col'>Status </th>
            </tr>
          </thead>
          <tbody>
            {ads_reviews &&
              ads_reviews.map((item, review_index) => {
                return (
                  <tr key={item._id}>
                    <th scope='row'> {review_index + 1} </th>
                    <td>
                      {item.createdAt &&
                        moment(item.createdAt).format('DD-MM-YYYY')}
                    </td>
                    <td> {item.campaign?.task_name} </td>

                    <td>
                      <EditReviewFieldComponent
                        field={'review_title'}
                        value={item.review_title}
                        element_type={INPUT_TYPE_ENUM.INPUT}
                        activity={item}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                      />
                    </td>
                    <td>
                      <EditReviewFieldComponent
                        field={'notes'}
                        value={item.notes}
                        element_type={INPUT_TYPE_ENUM.TEXTAREA}
                        activity={item}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                      />
                    </td>
                    <td>
                      <EditReviewFieldComponent
                        field={'assigned_to'}
                        value={item.assigned_to?.name}
                        element_type={INPUT_TYPE_ENUM.SELECT}
                        activity={item}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                        options={dropdownOptions && dropdownOptions.assigned_to}
                        selected_value={item.assigned_to?._id}
                      />
                    </td>
                    <td>
                      <EditReviewFieldComponent
                        field={'status'}
                        value={item.status}
                        element_type={INPUT_TYPE_ENUM.SELECT}
                        activity={item}
                        options={[
                          { label: 'PENDING', value: 'PENDING' },
                          { label: 'WORKING', value: 'WORKING' },
                          { label: 'DONE', value: 'DONE' },
                        ]}
                        activity_type={ADS_ACTIVITY_ENUM.CAMPAIGN}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
