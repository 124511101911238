import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import AddBtn from "../../components/common/AddBtn";
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/ads_enum";
import DataTable from "../../components/common/DataTable";
import {
  useAllAds,
  useGetDropdownOptions,
  useUpdateAd,
} from "../../shared/hooks/UseAd";
import { LIMIT } from "../../domain/constant";

import SidebarFilter from "../../components/common/SidebarFilter";
import moment from "moment";
import Spinner from "../../components/layout/Spinner";
import ReactModal from "react-modal";
import SingleView from "../../components/common/SingleView";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextArea, TextInput } from "../../components/Form/Form";
const AllAds = ({}) => {
  const [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData] =
    useAllAds();
  const { ads_loading, ads, total_ads, page, pages } = data;
  const [updateData] = useUpdateAd();

  const [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
  ] = useGetDropdownOptions();
  const loadOptions = async (inputValue, callback, field) => {
    if (field == "assigned_to") {
      setEmployeeSearchField("name");
      setEmployeeSearchValue(inputValue);
      callback(dropdownOptions.assigned_to);
    }
    if (field == "client") {
      setClientSearchField("name");
      setClientSearchValue(inputValue);
      callback(dropdownOptions.client);
    }
    if (field == "process") {
      setProcessSearchField("name");
      setProcessSearchValue(inputValue);
      callback(dropdownOptions.process);
    }
    if (field == "activity_status") {
      setActivitystatusSearchField("name");
      setActivitystatusSearchValue(inputValue);
      callback(dropdownOptions.activity_status);
    }
    if (field == "review_status") {
      setReviewstatusSearchField("name");
      setReviewstatusSearchValue(inputValue);
      callback(dropdownOptions.review_status);
    }
  };
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editField, setEditField] = useState(null);

  const [newStatus, setNewStatus] = useState(null);
  const [newValues, setNewValues] = useState(null);
  const [loadingActivityStatus, setLoadingActivityStatus] = useState(false);
  const updateStatus = async (id, status) => {
    // console.log("ID", id, value);
    if (id && status) {
      setLoadingActivityStatus(true);
      const newForm = new FormData();
      newForm.append("values", JSON.stringify(status));
      await updateData(id, newForm);
      await setReloadData(true);
      setEditId(null);
      setLoadingActivityStatus(false);
    } else {
      setEditId(null);
    }
  };
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />

        <div className="container-fluid">
          <div className="row">
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
              />
            )}
            <div className="col-lg-9">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  {total_ads} records found
                  <div>
                    <table className="table table-new align-middle table-striped  table-bordered">
                      {!ads_loading && (
                        <thead>
                          <tr className="bg-transparent" role="row">
                            <th style={{ width: "50px" }}>#</th>
                            <th> Ad name </th>
                            <th> Assigned To </th>
                            <th> Due Time </th>
                            <th> Estimate Time </th>
                            <th> Client </th>
                            <th> Process </th>
                            <th> Activity Status </th>
                            <th> Review Status </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                      )}

                      <tbody>
                        {!ads_loading ? (
                          ads &&
                          ads.map((single, index) => {
                            if (editId && editId == single._id) {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT} </td>
                                  <td> {single.ad_name} </td>
                                  <td>
                                    {single.assigned_to &&
                                      single.assigned_to.name}
                                  </td>
                                  <td>
                                    {single.due_time &&
                                      moment(single.due_time)
                                        .utc()
                                        .format("DD-MM-YYYY hh:mm:ss A")}
                                  </td>
                                  <td>{single.estimated_time}</td>
                                  <td>
                                    {" "}
                                    {single.client && single.client.name}{" "}
                                  </td>
                                  <td>{single.platform}</td>

                                  <td>
                                    {editField == "activity_status" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  activity_status:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.activity_status &&
                                                dropdownOptions.activity_status.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.activity_status &&
                                                          single.activity_status
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.activity_status &&
                                      single.activity_status.name
                                    )}
                                  </td>
                                  <td>
                                    {editField == "review_status" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  review_status: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.review_status &&
                                                dropdownOptions.review_status.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.review_status &&
                                                          single.review_status
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.review_status &&
                                      single.review_status.name
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        // setActivity(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  <td> {index + 1 + (page - 1) * LIMIT}</td>
                                  <td> {single.ad_name} </td>
                                  <td>
                                    {single.assigned_to &&
                                      single.assigned_to.name}
                                  </td>
                                  <td>
                                    {single.due_time &&
                                      moment(single.due_time)
                                        .utc()
                                        .format("DD-MM-YYYY hh:mm:ss A")}
                                  </td>
                                  <td>{single.estimated_time}</td>

                                  <td>
                                    {" "}
                                    {single.client && single.client.name}{" "}
                                  </td>
                                  <td>{single.platform}</td>

                                  <td>
                                    {" "}
                                    {single.activity_status &&
                                      single.activity_status.name}{" "}
                                    <a
                                      onClick={() => {
                                        setEditField("activity_status");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.review_status &&
                                      single.review_status.name}
                                    <a
                                      onClick={() => {
                                        setEditField("review_status");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                        // setActivity(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars" />{" "}
                                    </button>
                                    <button
                                      className="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i className="fa fa-trash" />
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={20} className="text-center">
                              <Spinner />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <Pagination
                      data={ads}
                      page={page}
                      pages={pages}
                      count={total_ads}
                      setPage={setPageNumber}
                      loading={ads_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={() => {
          setModal(false);
        }}
      >
        <div className="quick-view">
          <div className="qv-header">
            <div className="title"> {PAGE_SINGLE_TITLE} </div>
            <div>
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
          {modalData && (
            <>
              <div className="qv-body">
                <SingleView
                  data={modalData}
                  inputFields={inputFields}
                  label={PAGE_SINGLE_TITLE}
                  link={LINK_URL}
                  id={modalData._id}
                  hideAllBtn={true}
                  col={12}
                />
                {/* <div className="card">
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        comment: "",
                      }}
                      validationSchema={Yup.object({
                        comment: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        values.activity = modalData._id;
                        await submitFormClicked(values);
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row align-items-center">
                              <div className="col-md-9">
                                <TextArea
                                  label="Comment"
                                  name="comment"
                                  type="text"
                                />
                              </div>
                              <div className="col-md-3">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  {" "}
                                  {formik.isSubmitting
                                    ? "SAVING..."
                                    : "SAVE"}{" "}
                                </button>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div> */}
                <div className="card">
                  <div className="card-body">
                    <table className="table table-stripped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Comment</th>
                          <th>Comment By</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      {/* <tbody>
                        {!comments_loading ? (
                          <>
                            {comments &&
                              comments.map((item, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1} </td>
                                    <td> {item.comment} </td>
                                    <td>
                                      {" "}
                                      {item.created_by &&
                                        item.created_by.name}{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      {item.createdAt &&
                                        moment(item.createdAt).format(
                                          "DD-MM-YYYY hh:mm A"
                                        )}{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <p> Loading... </p>
                          </>
                        )}
                      </tbody> */}
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AllAds;
