import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as qs from 'qs';

import { UseFilter } from '../../shared/hooks/UseFilter';
import { useHistory, useLocation } from 'react-router';
function FilterDateComponent({ link, showOwn, searchParams, searchField }) {
  const [activeBtn, todayDate, weekStartDate, weekEndsDate] = UseFilter();
  // console.log(window.location.search);
  const [customDataSelected, setCustomDataSelected] = useState(false);
  const [dateConditions, setDateConditions] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const [selectedFields, setSelectedFields] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    // console.log(queryParams);
    if (
      queryParams.conditional &&
      queryParams.conditional[searchField ? searchField : 'createdAt']
    ) {
      setDateConditions(
        queryParams.conditional[searchField ? searchField : 'createdAt']
      );
    } else {
      setDateConditions(null);
    }
  }, [window.location.search]);

  const handleSidebarChange = ({ name, value, type = 'search', condition }) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (type == 'conditional') {
      if (queryParams[type]) {
        if (queryParams[type][name]) {
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        } else {
          queryParams[type][name] = {};
          if (value && value.length > 0) {
            queryParams[type][name][condition ? condition : '$gte'] = value;
          } else {
            delete queryParams[type][name][condition ? condition : '$gte'];
          }
        }
      } else {
        queryParams[type] = {};
        queryParams[type][name] = {};
        if (value && value.length > 0) {
          queryParams[type][name][condition ? condition : '$gte'] = value;
        } else {
          delete queryParams[type][name][condition ? condition : '$gte'];
        }
      }
    } else {
      if (queryParams[type]) {
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      } else {
        queryParams[type] = {};
        if (value && value.length > 0) {
          queryParams[type][name] = value;
        } else {
          delete queryParams[type][name];
        }
      }
    }

    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    // console.log("PARAMS", params.toString());
    // console.log("QUERY", `${location.pathname}?${query}`);
    history.push(`${location.pathname}?${query}`);
  };
  const handleSidebarChangeArray = (fieldsArray, dateType) => {
    const queryParams = qs.parse(window.location.search.replace('?', ''));
    if (fieldsArray) {
      fieldsArray.map(({ name, value, type = 'search', condition }) => {
        if (type == 'conditional') {
          if (queryParams[type]) {
            if (queryParams[type][name]) {
              if (value && value.length > 0) {
                queryParams[type][name][condition ? condition : '$gte'] = value;
              } else {
                delete queryParams[type][name][condition ? condition : '$gte'];
              }
            } else {
              queryParams[type][name] = {};
              if (value && value.length > 0) {
                queryParams[type][name][condition ? condition : '$gte'] = value;
              } else {
                delete queryParams[type][name][condition ? condition : '$gte'];
              }
            }
          } else {
            queryParams[type] = {};
            queryParams[type][name] = {};
            if (value && value.length > 0) {
              queryParams[type][name][condition ? condition : '$gte'] = value;
            } else {
              delete queryParams[type][name][condition ? condition : '$gte'];
            }
          }
        } else {
          if (queryParams[type]) {
            if (value && value.length > 0) {
              queryParams[type][name] = value;
            } else {
              delete queryParams[type][name];
            }
          } else {
            queryParams[type] = {};
            if (value && value.length > 0) {
              queryParams[type][name] = value;
            } else {
              delete queryParams[type][name];
            }
          }
        }
      });
    }

    queryParams.date_type = dateType;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    // console.log("PARAMS", params.toString());
    // console.log("QUERY", `${location.pathname}?${query}`);

    history.push(`${location.pathname}?${query}`);
  };

  return (
    <div className='container-fluid'>
      <div className='card'>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <div>
              Date Filter <br />
              <Link className='btn btn-sm btn-danger' to={link}>
                {' '}
                Clear{' '}
              </Link>
              {dateConditions && dateConditions['$gte'] && (
                <label className='badge  bg-warning m-1'>
                  Created At {'>'}{' '}
                  {moment(dateConditions['$gte']).format('DD-MMM-YYYY')}{' '}
                </label>
              )}
              {dateConditions && dateConditions['$lte'] && (
                <label className='badge bg-warning'>
                  Created At {'<'}{' '}
                  {moment(dateConditions['$lte']).format('DD-MMM-YYYY')}{' '}
                </label>
              )}
            </div>

            <div className='d-flex justify-content-between'>
              <div>
                {/* <Link
                  // to={`${link}?&today=true&conditional[createdAt][$gte]=${moment(
                  //   todayDate
                  // ).format("YYYY-MM-DD")}`}
                  // className={
                  //   activeBtn == "Today"
                  //     ? "btn btn-success m-1"
                  //     : "btn btn-secondary m-1"
                  // }
                  to={`${link}?&today=true&conditional[${
                    searchField ? searchField : "createdAt"
                  }][$gte]=${moment(todayDate).format(
                    "YYYY-MM-DD"
                  )}&conditional[${
                    searchField ? searchField : "createdAt"
                  }][$lt]=${moment(todayDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD")}`}
                  className={
                    activeBtn == "Today"
                      ? "btn btn-success m-1"
                      : "btn btn-secondary m-1"
                  }
                >
                  {" "}
                  Today{" "}
                </Link> */}
                {customDataSelected ? (
                  <div className='d-flex justify-content-between align-items-end'>
                    <div>
                      {' '}
                      <label> From </label>
                      <input
                        type='date'
                        className='form-control'
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </div>
                    <div>
                      <label> To </label>
                      <input
                        type='date'
                        className='form-control'
                        required
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        className={
                          activeBtn == 'Custom'
                            ? 'btn btn-success m-1'
                            : 'btn btn-secondary m-1'
                        }
                        onClick={() => {
                          handleSidebarChangeArray(
                            [
                              {
                                name: searchField ? searchField : 'createdAt',
                                value: moment(fromDate).format('YYYY-MM-DD'),
                                type: 'conditional',
                                condition: '$gte',
                              },
                              {
                                name: searchField ? searchField : 'createdAt',
                                value: moment(toDate)
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD'),
                                type: 'conditional',
                                condition: '$lt',
                              },
                            ],
                            'Custom'
                          );
                          setCustomDataSelected(false);
                        }}
                      >
                        Apply
                      </button>
                      <button
                        className={'btn btn-danger m-1'}
                        onClick={() => {
                          setCustomDataSelected(false);
                          setFromDate('');
                          setToDate('');
                        }}
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      className={
                        activeBtn == 'today'
                          ? 'btn btn-success m-1'
                          : 'btn btn-secondary m-1'
                      }
                      onClick={() => {
                        handleSidebarChangeArray(
                          [
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment(todayDate).format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$gte',
                            },
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment(todayDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$lt',
                            },
                          ],
                          'today'
                        );
                      }}
                    >
                      Today
                    </button>
                    <button
                      className={
                        activeBtn == 'yesterday'
                          ? 'btn btn-success m-1'
                          : 'btn btn-secondary m-1'
                      }
                      onClick={() => {
                        handleSidebarChangeArray(
                          [
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment(todayDate)
                                .subtract(1, 'days')
                                .format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$gte',
                            },
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment(todayDate).format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$lt',
                            },
                          ],
                          'yesterday'
                        );
                      }}
                    >
                      Yesterday
                    </button>
                    {
                      //   <button
                      //   className={
                      //     activeBtn == "weekly"
                      //       ? "btn btn-success m-1"
                      //       : "btn btn-secondary m-1"
                      //   }
                      //   onClick={() => {
                      //     handleSidebarChangeArray(
                      //       [
                      //         {
                      //           name: searchField ? searchField : "createdAt",
                      //           value: moment(weekStartDate).format("YYYY-MM-DD"),
                      //           type: "conditional",
                      //           condition: "$gte",
                      //         },
                      //         {
                      //           name: searchField ? searchField : "createdAt",
                      //           value: moment(weekEndsDate).format("YYYY-MM-DD"),
                      //           type: "conditional",
                      //           condition: "$lte",
                      //         },
                      //       ],
                      //       "weekly"
                      //     );
                      //   }}
                      // >
                      //   Last Month
                      // </button>
                    }
                    <button
                      className={
                        activeBtn == 'weekly'
                          ? 'btn btn-success m-1'
                          : 'btn btn-secondary m-1'
                      }
                      onClick={() => {
                        handleSidebarChangeArray(
                          [
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment(weekStartDate).format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$gte',
                            },
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment(weekEndsDate).format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$lte',
                            },
                          ],
                          'weekly'
                        );
                      }}
                    >
                      This Week
                    </button>
                    <button
                      className={
                        activeBtn == 'monthly'
                          ? 'btn btn-success m-1'
                          : 'btn btn-secondary m-1'
                      }
                      onClick={() => {
                        handleSidebarChangeArray(
                          [
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment()
                                .startOf('month')
                                .format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$gte',
                            },
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment()
                                .endOf('month')
                                .format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$lte',
                            },
                          ],
                          'monthly'
                        );
                      }}
                    >
                      This Month
                    </button>
                    <button
                      className={
                        activeBtn == 'last_month'
                          ? 'btn btn-success m-1'
                          : 'btn btn-secondary m-1'
                      }
                      onClick={() => {
                        handleSidebarChangeArray(
                          [
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment()
                                .subtract(1, 'months')
                                .startOf('month')
                                .format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$gte',
                            },
                            {
                              name: searchField ? searchField : 'createdAt',
                              value: moment()
                                .subtract(1, 'months')
                                .endOf('month')
                                .format('YYYY-MM-DD'),
                              type: 'conditional',
                              condition: '$lte',
                            },
                          ],
                          'last_month'
                        );
                      }}
                    >
                      Last Month
                    </button>
                    <button
                      className={
                        activeBtn == 'Custom'
                          ? 'btn btn-success m-1'
                          : 'btn btn-secondary m-1'
                      }
                      onClick={() => setCustomDataSelected(true)}
                    >
                      Custom
                    </button>
                    <Link
                      to={`${link}`}
                      className={
                        activeBtn == 'Lifetime'
                          ? 'btn btn-success m-1'
                          : 'btn btn-secondary m-1'
                      }
                    >
                      Lifetime
                    </Link>
                    {showOwn && searchParams && (
                      <Link
                        to={`${link}${
                          window.location.search ? window.location.search : '?'
                        }&exact[${searchParams.term}]=${searchParams.value}`}
                        className={'btn btn-secondary m-1'}
                      >
                        My Activity
                      </Link>
                    )}
                  </>
                )}
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterDateComponent;
