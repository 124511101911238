import {
  GET_ADS_STATED,
  GET_ADS,
  GET_ADS_ENDED,
  ADD_AD_STATED,
  ADD_AD,
  ADD_AD_ENDED,
  EDIT_AD_STATED,
  EDIT_AD,
  EDIT_AD_ENDED,
  GET_AD_STATED,
  GET_AD,
  GET_AD_ENDED,
  GET_ALL_ADS_STATED,
  GET_ALL_ADS,
  GET_ALL_ADS_ENDED
} from "../types/ad_type";

const initialState = {
  ads_loading: true,
  ads: null,
  page: null,
  pages: null,
  total_ads: 0,

  ad: null,
  ad_loading: null,

  loading: true,

  ad_message: null,
  all_ads: null,
  all_ads_loading: null,
  add_ad_loading: true,
  edit_ad_loading: true
};

export const ad_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ADS_STATED:
      return {
        ...state,
        ads: null,
        pages: null,
        page: null,
        total_ads: 0,
        ads_loading: true
      };
    case GET_ADS:
      return {
        ...state,
        ads: payload.ads,
        pages: payload.pages,
        page: payload.page,
        total_ads: payload.count
      };
    case GET_ADS_ENDED:
      return {
        ...state,
        ads_loading: false
      };
    case GET_ALL_ADS_STATED:
      return {
        ...state,
        all_ads_loading: true,
        all_ads: null
      };
    case GET_ALL_ADS:
      return {
        ...state,
        all_ads: payload
      };
    case GET_ALL_ADS_ENDED:
      return {
        ...state,
        all_ads_loading: false
      };

    case ADD_AD_STATED:
      return {
        ...state,
        ad_message: null,
        add_ad_loading: true
      };
    case ADD_AD:
      return {
        ...state,
        ad_message: payload
      };
    case ADD_AD_ENDED:
      return {
        ...state,
        add_ad_loading: false
      };
    case GET_AD_STATED:
      return {
        ...state,
        ad: null,
        ad_loading: true
      };
    case GET_AD:
      return {
        ...state,
        ad: payload
      };
    case GET_AD_ENDED:
      return {
        ...state,
        ad_loading: false
      };
    case EDIT_AD_STATED:
      return {
        ...state,
        ad_message: null,
        edit_ad_loading: true
      };
    case EDIT_AD:
      return {
        ...state,
        ad_message: payload
      };
    case EDIT_AD_ENDED:
      return {
        ...state,
        edit_ad_loading: false
      };

    default:
      return state;
  }
};
