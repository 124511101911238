import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addAdsreport,
  getAdsreports,
  getAdsreport,
  editAdsreport,
  deleteAdsreport,
  getAllAdsreports,
  uploadGoogleAdsreport,
  uploadFacebookAdsreports,
  getFacebookAdsreports,
} from '../../store/actions/adsreport_action';
import _debounce from 'lodash/debounce';
import { useSelectAllClient } from './UseClient';
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllAdsreports = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteAdsreport(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getAdsreports({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    dispatch(
      getAdsreports({
        pageNumber,
      })
    );
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};
// Get All Data
export const useAllFacebookAdsreports = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteAdsreport(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getFacebookAdsreports({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    dispatch(
      getFacebookAdsreports({
        pageNumber,
      })
    );
  }, [pageNumber]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleAdsreport = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  useEffect(() => {
    dispatch(getAdsreport(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateAdsreport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  const addData = async (data) => {
    await dispatch(addAdsreport(data));
  };
  return [data, addData];
};
// Add Data
export const useUploadGoogleAdsreport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  const addData = async (data) => {
    await dispatch(uploadGoogleAdsreport(data));
  };
  return [data, addData];
};
// Add Data
export const useUploadFacebookAdsreport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  const addData = async (data) => {
    await dispatch(uploadFacebookAdsreports(data));
  };
  return [data, addData];
};
export const useUpdateAdsreport = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.adsreport);
  const updateData = async (id, data) => {
    await dispatch(editAdsreport(id, data));
  };
  return [updateData];
};

export const useSelectAllAdsreport = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const data = useSelector((state) => state.adsreport);
  useEffect(() => {
    dispatch(getAllAdsreports({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [client, setClientSearchField, setClientSearchValue] =
    useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, client: newData });
    }
  }, [client]);
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "client") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };

  return [dropdownOptions, loadOptions];
};

// Get All Data
export const useAllAdsReportsDashboard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(
      getAdsreports({
        pageNumber,
      })
    );
  }, [pageNumber]);

  return { google_ads_reports: data, setPageNumber, pageNumber };
};
// Facebook Dashboard Ads Report
// Get All Data
export const useAllFacebookAdsreportsDashboard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adsreport);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(
      getFacebookAdsreports({
        pageNumber,
      })
    );
  }, [pageNumber]);

  return { fb_ads_reports_data: data, setPageNumber, pageNumber };
};
