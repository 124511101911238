export const PAGE_TITLE = "Leads";
export const PAGE_SINGLE_TITLE = "Lead";
export const LINK_URL = "leads";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  display_name: {
    type: "string",
    required: false,
    title: "Display Name",
    inputType: "text",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "text",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
    inputType: "text",
  },
  whatsapp_no: {
    type: "string",
    required: false,
    title: "WhatsApp No",
    inputType: "text",
  },
  notes: {
    type: "text",
    required: false,
    title: "Notes",
    inputType: "text",
  },
  group: {
    type: "related",
    required: false,
    title: "Group",
    inputType: "text",
    field: "name",
    static: true,
  },
};
export const initialValues = {
  name: "",
  display_name: "",
  phone: "",
  email: "",
  whatsapp_no: "",
  notes: "",
};

export const view_all_table = [
  { name: "Date", value: "createdAt", date: true },
  { name: "Name", value: "name" },
  { name: "Phone", value: "phone" },
  { name: "Email", value: "email" },
  { name: "Group", value: "group" },
  { name: "Assigned To", value: "created_by", related: true, field: "name" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "display_name",
    field: "display_name",
    label: "Display Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "phone",
    field: "phone",
    label: "Phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "message",
    field: "message",
    label: "Message",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "date_from",
    field: "createdAt",
    label: "Date From",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "date_to",
    field: "createdAt",
    label: "Date To",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
  {
    id: "group",
    field: "group",
    label: "Group",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
];
