export const PAGE_TITLE = "Activity Cycles";
export const PAGE_SINGLE_TITLE = "Activity Cycle";
export const LINK_URL = "activity-cycles";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
  },
  due_time: {
    type: "string",
    required: true,
    title: "Due Time",
    inputType: "datetime-local",
  },
  client: {
    type: "related",
    required: true,
    title: "Client",
    inputType: "text",
    field: "name",
  },
  description: {
    type: "html",
    required: true,
    title: "Description",
  },
  process: {
    type: "related",
    required: true,
    title: "Process",
    field: "name",
  },
  gallery: {
    type: "gallery",
    required: false,
    title: "Attachments",
  },

  status: {
    type: "select",
    required: true,
    title: "Status",
    options: ["PENDING", "COMPLETED"],
    
  },

  activities: {
    type: "array",
    required: false,
    title: "Activities",
    fields: {
      task_name: {
        type: "string",
        required: true,
        title: "Task name",
        inputType: "text",
        field: "name",
      },

      assigned_to: {
        type: "related",
        required: true,
        title: "Assigned To",
        inputType: "text",
        field: "name",
      },
      task_description: {
        type: "html",
        required: false,
        title: "Task Description",
        // inputType: "text",
        // field: "name"
      },
      due_time: {
        type: "string",
        required: true,
        title: "Due Time",
        inputType: "datetime-local",
      },

      process: {
        type: "related",
        required: true,
        title: "Process",
        inputType: "text",
        field: "name",
      },
      estimated_time: {
        type: "string",
        required: false,
        title: "Estimated Time (in mins)",
        inputType: "number",
      },

      attachments: {
        type: "gallery",
        required: false,
        title: "Attachments",
        inputType: "text",
        multiple: true,
      },
      review_status: {
        type: "related",
        required: true,
        title: "Review Status",
        inputType: "text",
        field: "name",
        
      },
      activity_status: {
        type: "related",
        required: true,
        title: "Activity Status",
        inputType: "text",
        field: "name",
      },
    },
  },
};
export const initialValues = {
  name: "",
  due_time: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  {
    name: "Due Time",
    value: "due_time",
    date: true,
  },
  { name: "Client", value: "client", related: true, field: "name" },
  { name: "Process", value: "process", related: true, field: "name" },
  { name: "Status", value: "status" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "due_time",
    field: "due_time",
    label: "Due Time",
    type: "string",
    search_type: "conditional",
    inputType: "datetime-local",
    condition: "$gte",
  },

  {
    id: "client",
    field: "client",
    label: "Client",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "process",
    field: "process",
    label: "Process",
    type: "related",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "activity_status",
    field: "status",
    label: "Activity Status",
    type: "select",
    search_type: "exact",
    inputType: "text",
    condition: "",
    options: ["PENDING", "COMPLETED"],
  },

  {
    id: "date_from",
    field: "createdAt",
    label: "Date From",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$gte",
  },
  {
    id: "date_to",
    field: "createdAt",
    label: "Date To",
    type: "string",
    search_type: "conditional",
    inputType: "date",
    condition: "$lte",
  },
];
