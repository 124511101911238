import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAd,
  getAds,
  getAd,
  editAd,
  deleteAd,
  getAllAds,
  bulkUpload,
} from "../../store/actions/ad_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";
import { useSelectAllEmployee } from "../hooks/UseEmployee";
import { useSelectAllClient } from "../hooks/UseClient";
import { useSelectAllProcess } from "../hooks/UseProcess";
import { useSelectAllActivitystatus } from "../hooks/UseActivitystatus";
import { useSelectAllReviewstatus } from "../hooks/UseReviewstatus";

// Get All Data
export const useAllAds = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ad);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteAd(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getAds({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);
  useEffect(() => {
    if (reloadData) {
      allQuery();
      setReloadData(false);
    }
  }, [reloadData]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData];
};

// Get Single Data
export const useSingleAd = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ad);
  useEffect(() => {
    dispatch(getAd(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateAd = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ad);
  const addData = async (data) => {
    await dispatch(addAd(data));
  };
  return [data, addData];
};
// Add Data
export const useImportReport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ad);
  const addData = async (data) => {
    await dispatch(bulkUpload(data));
  };
  return [data, addData];
};
export const useUpdateAd = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.ad);
  const updateData = async (id, data) => {
    await dispatch(editAd(id, data));
  };
  return [updateData];
};

export const useSelectAllAd = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.ad);
  useEffect(() => {
    dispatch(getAllAds({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [employee, setEmployeeSearchField, setEmployeeSearchValue] =
    useSelectAllEmployee();
  const [client, setClientSearchField, setClientSearchValue] =
    useSelectAllClient();
  const [process, setProcessSearchField, setProcessSearchValue] =
    useSelectAllProcess();
  const [
    activitystatus,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
  ] = useSelectAllActivitystatus();
  const [reviewstatus, setReviewstatusSearchField, setReviewstatusSearchValue] =
    useSelectAllReviewstatus();
  // const [employee] = useSelectAllEmployee();

  const [dropdownOptions, setDropdownOptions] = useState({});

  useEffect(() => {
    if (employee && employee.all_employees) {
      const newData = employee.all_employees.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, assigned_to: newData });
    }
  }, [employee]);

  useEffect(() => {
    if (client && client.all_clients) {
      const newData = client.all_clients.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, client: newData });
    }
  }, [client]);

  useEffect(() => {
    if (process && process.all_processs) {
      const newData = process.all_processs.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, process: newData });
    }
  }, [process]);

  useEffect(() => {
    if (activitystatus && activitystatus.all_activitystatuss) {
      const newData = activitystatus.all_activitystatuss.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, activity_status: newData });
    }
  }, [activitystatus]);
  useEffect(() => {
    if (reviewstatus && reviewstatus.all_reviewstatuss) {
      const newData = reviewstatus.all_reviewstatuss.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, review_status: newData });
    }
  }, [reviewstatus]);

  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);

  return [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
    setProcessSearchField,
    setProcessSearchValue,
    setActivitystatusSearchField,
    setActivitystatusSearchValue,
    setReviewstatusSearchField,
    setReviewstatusSearchValue,
  ];
};
