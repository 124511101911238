import {
  ADD_ADS_REVIEW_STARTED,
  ADD_ADS_REVIEW_ENDED,
  ADS_REVIEWS_STARTED,
  ADS_REVIEWS,
  ADS_REViEWS_ENDED,
  UPDATE_ADS_REVIEW_STARTED,
  UPDATE_ADS_REVIEW_ENDED,
} from '../types/ads_review_type';

const initialState = {
  ads_reviews_loading: false,
  ads_reviews: null,
  ads_reviews_page: null,
  ads_reviews_pages: null,
  total_ads_reviews: null,
  add_ads_review_loading: false,
  update_ads_review_loading: false,
};

export const ads_review_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADS_REVIEWS_STARTED:
      return {
        ...state,
        ads_reviews_loading: true,
        ads_reviews: null,
        ads_reviews_page: null,
        ads_reviews_pages: null,
        total_ads_reviews: null,
      };
    case ADS_REVIEWS:
      return {
        ...state,
        ads_reviews: payload.ads_reviews,
        ads_reviews_pages: payload.pages,
        ads_reviews_page: payload.page,
        total_ads_reviews: payload.count,
      };
    case ADS_REViEWS_ENDED:
      return {
        ...state,
        ads_reviews_loading: false,
      };

    case ADD_ADS_REVIEW_STARTED:
      return {
        ...state,
        add_ads_review_loading: true,
      };
    case ADD_ADS_REVIEW_ENDED:
      return {
        ...state,
        add_ads_review_loading: false,
      };
    case UPDATE_ADS_REVIEW_STARTED:
      return {
        ...state,
        update_ads_review_loading: true,
      };
    case UPDATE_ADS_REVIEW_ENDED:
      return {
        ...state,
        update_ads_review_loading: false,
      };

    default:
      return state;
  }
};
