export const LIMIT = 100;
export const UPLOAD_CONST = '';
export const URI = 'https://lms-api.luhaifdigitech.com/';
// export const URI = 'http://localhost:5050';
// export const URI = "http://localhost:5000";
export const COMPLETED_STATUS = '62b2a7abdb1f21d46398f0b8';
export const DEFAULT_REVIEW_STATUS = '62c41022bed2eece564de15d';
export const ADS_ACTIVITY_TYPES = ['LANDING_PAGE', 'CAMPAIGN', 'CREATIVE'];

export const ADS_ACTIVITY_ENUM = {
  LANDING_PAGE: 'LANDING_PAGE',
  CAMPAIGN: 'CAMPAIGN',
  CREATIVE: 'CREATIVE',
};

export const ADS_PLATFORM_TYPES = ['FACEBOOK', 'GOOGLE'];

export const ADS_PLATFORM_ENUM = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
};

export const REVIEW_STATUS = ['PENDING', 'WORKING', 'DONE'];

export const REVIEW_STATUS_ENUM = {
  PENDING: 'PENDING',
  WORKING: 'WORKING',
  DONE: 'DONE',
};

export const INPUT_TYPE_ENUM = {
  SELECT: 'SELECT',
  INPUT: 'INPUT',
  TEXTAREA: 'TEXTAREA',
};
