import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import { URI } from '../../domain/constant';
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/employees_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleEmployee } from "../../shared/hooks/UseEmployee";
import AllActivityComponent from "./AllActivityComponent";
const ViewEmployee = ({ match }) => {
  const [data] = useSingleEmployee(match.params.id);
  const { employee_loading, employee } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {
          !employee_loading ? employee && (
            <section className='my-profile ptb-20'>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-8 my-profile-card-box">
                    <div className="my-profile-card-box-inside">
                      <div className="my-profile-img">
                        <img src={`${URI}${employee.image}`} alt="" />
                      </div>
                      <div className="my-profile-content">
                        <div className="my-profile-content-heading">
                          <h2>{employee.name}</h2>
                        </div>
                        <div className="my-profile-content-para">
                          <p>Designation: <span>{employee.designation}</span></p>
                        </div>
                        <div className="my-profile-content-para">
                          <p>Status: <span>{employee.employee_status}</span></p>
                        </div>
                        <div className="my-profile-content-para">
                          <p>Reporting Manager: <span>{employee.reporting_manager && employee.reporting_manager.name}</span></p>
                        </div>
                        <div className="my-profile-content-para">
                          <p>Email: <span>{employee.email}</span></p>
                        </div>
                        <div className="my-profile-content-para">
                          <p>Phone: <span>{employee.phone}</span></p>
                        </div>
                        <div className="my-profile-content-para">
                          <p>Emergency Contact No.: <span>{employee.emergency_contact_no}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div></div>
          )
        }

        {!employee_loading ? (
          employee && (
            <>
              <SingleView
                data={employee}
                inputFields={inputFields}
                label={PAGE_SINGLE_TITLE}
                link={LINK_URL}
                id={employee._id}
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                col={3}
              >
                <AllActivityComponent employee={employee} />
              </SingleView>
            </>
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div >
  );
};

export default ViewEmployee;
