import React from 'react';
import { useAllFacebookAdsreportsDashboard } from '../../shared/hooks/UseAdsreport';
import moment from 'moment';
export const AdsReportFBComponent = ({ ads_clients }) => {
  const { fb_ads_reports_data } = useAllFacebookAdsreportsDashboard();
  const { fb_adsreports, fb_total_conversions, fb_total_cost } =
    fb_ads_reports_data;
  return (
    <div>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex justify-content-between'>
              <div>Facebook Ads</div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <table className='table table-bordered table-sm'>
            <thead>
              <tr>
                <th>Sr. No </th>
                <th>Date</th>
                <th>Client Name</th>
                <th> Campaign Name </th>
                <th> Conversions </th>
                <th> Total Cost </th>
                <th> CPC </th>
                <th> Benchmark </th>
                <th> In Benchmark </th>
              </tr>
            </thead>
            <tbody>
              {fb_adsreports &&
                fb_adsreports.map((report, index) => {
                  return (
                    <tr key={report._id}>
                      <td> {index + 1} </td>
                      <td>
                        {report?.report_date
                          ? moment(report.report_date).format('DD-MM-YYYY')
                          : 'NA'}
                      </td>
                      <td> {report?.client?.name} </td>
                      <td> {report?.campaign?.task_name} </td>
                      <td> {report?.conversions} </td>

                      <td> {report?.total_cost} </td>
                      <td> {report?.cost_per_result} </td>
                      <td> {report?.benchmark} </td>

                      <td>
                        {report.in_benchmark ? (
                          <span class='c-pill c-pill--success'>YES</span>
                        ) : (
                          <span class='c-pill c-pill--danger'>NO</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
