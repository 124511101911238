import {
  GET_BILLREVIEWS_STATED,
  GET_BILLREVIEWS,
  GET_BILLREVIEWS_ENDED,
  ADD_BILLREVIEW_STATED,
  ADD_BILLREVIEW,
  ADD_BILLREVIEW_ENDED,
  EDIT_BILLREVIEW_STATED,
  EDIT_BILLREVIEW,
  EDIT_BILLREVIEW_ENDED,
  GET_BILLREVIEW_STATED,
  GET_BILLREVIEW,
  GET_BILLREVIEW_ENDED,
  GET_ALL_BILLREVIEWS_STATED,
  GET_ALL_BILLREVIEWS,
  GET_ALL_BILLREVIEWS_ENDED
} from "../types/billreview_type";

const initialState = {
  billreviews_loading: true,
  billreviews: null,
  page: null,
  pages: null,
  total_billreviews: 0,

  billreview: null,
  billreview_loading: null,

  loading: true,

  billreview_message: null,
  all_billreviews: null,
  all_billreviews_loading: null,
  add_billreview_loading: true,
  edit_billreview_loading: true
};

export const billreview_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BILLREVIEWS_STATED:
      return {
        ...state,
        billreviews: null,
        pages: null,
        page: null,
        total_billreviews: 0,
        billreviews_loading: true
      };
    case GET_BILLREVIEWS:
      return {
        ...state,
        billreviews: payload.billreviews,
        pages: payload.pages,
        page: payload.page,
        total_billreviews: payload.count
      };
    case GET_BILLREVIEWS_ENDED:
      return {
        ...state,
        billreviews_loading: false
      };
    case GET_ALL_BILLREVIEWS_STATED:
      return {
        ...state,
        all_billreviews_loading: true,
        all_billreviews: null
      };
    case GET_ALL_BILLREVIEWS:
      return {
        ...state,
        all_billreviews: payload
      };
    case GET_ALL_BILLREVIEWS_ENDED:
      return {
        ...state,
        all_billreviews_loading: false
      };

    case ADD_BILLREVIEW_STATED:
      return {
        ...state,
        billreview_message: null,
        add_billreview_loading: true
      };
    case ADD_BILLREVIEW:
      return {
        ...state,
        billreview_message: payload
      };
    case ADD_BILLREVIEW_ENDED:
      return {
        ...state,
        add_billreview_loading: false
      };
    case GET_BILLREVIEW_STATED:
      return {
        ...state,
        billreview: null,
        billreview_loading: true
      };
    case GET_BILLREVIEW:
      return {
        ...state,
        billreview: payload
      };
    case GET_BILLREVIEW_ENDED:
      return {
        ...state,
        billreview_loading: false
      };
    case EDIT_BILLREVIEW_STATED:
      return {
        ...state,
        billreview_message: null,
        edit_billreview_loading: true
      };
    case EDIT_BILLREVIEW:
      return {
        ...state,
        billreview_message: payload
      };
    case EDIT_BILLREVIEW_ENDED:
      return {
        ...state,
        edit_billreview_loading: false
      };

    default:
      return state;
  }
};
