import { useState, useEffect,useCallback } from "react";
import { useSelector, useDispatch  } from "react-redux";
import {
  addServicepackage,
  getServicepackages,
  getServicepackage,
  editServicepackage,
  deleteServicepackage,
  getAllServicepackages,
} from "../../store/actions/servicepackage_action";
import _debounce from "lodash/debounce";
// import { useSelectAllIndustry } from "./UseIndustry";

// Get All Data
export const useAllServicepackages = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.servicepackage);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);
 
  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteServicepackage(deleteEntry));
  }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
     
      dispatch(
        getServicepackages({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [
    data, setPageNumber, deleteBtnClicked
  ];
};

// Get Single Data
export const useSingleServicepackage = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.servicepackage);
  useEffect(() => {
    dispatch(getServicepackage(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateServicepackage = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.servicepackage);
  const addData = async (data) => {
    await dispatch(addServicepackage(data));
  };
  return [data, addData];
};
export const useUpdateServicepackage = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.servicepackage);
  const updateData = async (id, data) => {
    await dispatch(editServicepackage(id, data));
  };
  return [updateData];
};

export const useSelectAllServicepackage = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.servicepackage);
  useEffect(() => {
    dispatch(getAllServicepackages({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};


export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
    // useSelectAllClient();
 
  const [dropdownOptions, setDropdownOptions] = useState({});
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);

 
  return [dropdownOptions
    // setClientSearchField, setClientSearchValue
  ];
};