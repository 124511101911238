import api from '../../domain/api';
import {
  ADD_ADS_REVIEW_STARTED,
  ADD_ADS_REVIEW_ENDED,
  ADS_REVIEWS_STARTED,
  ADS_REVIEWS,
  ADS_REViEWS_ENDED,
  UPDATE_ADS_REVIEW_STARTED,
  UPDATE_ADS_REVIEW_ENDED,
} from '../types/ads_review_type';

import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';

export const addAdsReview = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ADS_REVIEW_STARTED,
    });
    const { data } = await api.post('/ads-reviews/add', formData);
    dispatch({
      type: ADS_REVIEWS,
      payload: data,
    });
    dispatch({
      type: ADD_ADS_REVIEW_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_ADS_REVIEW_ENDED,
    });
    console.log('Error', error);
  }
};

export const getAllReviews = (client) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADS_REVIEWS_STARTED,
    });
    const { data } = await api.get(`/ads-reviews/all/${client}`);
    dispatch({
      type: ADS_REVIEWS,
      payload: data,
    });
    dispatch({
      type: ADS_REViEWS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADS_REViEWS_ENDED,
    });
    console.log('Error', error);
  }
};

export const updateAdsReview =
  (review_id, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ADS_REVIEW_STARTED,
      });
      const { data } = await api.put(
        `/ads-reviews/update/${review_id}`,
        formData
      );
      dispatch({
        type: ADS_REVIEWS,
        payload: data,
      });
      dispatch({
        type: UPDATE_ADS_REVIEW_ENDED,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ADS_REVIEW_ENDED,
      });
      console.log('Error', error);
    }
  };
