import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import EditorWYSIWYG from "../EditorWYSIWYG";
import * as Yup from "yup";
import { CheckBox, SelectBox, TextArea, TextInput } from "../Form/Form";
import slugify from "react-slugify";
import { useHistory } from "react-router-dom";
import moment from "moment";
import FormComponents from "../common/FormComponents";
import ClientFormComponent from "./ClientFormComponent";
function AddClientForm({
  data,
  edit,
  submitForm,
  setFeaturedImage,
  setGallery,
  inputFields,
  initialValues,
  dropdown_options,
  loadOptions,
  PAGE_SINGLE_TITLE,
}) {
  let history = useHistory();

  const [requiredCheck, setRequiredCheck] = useState({});
  const [customData, setCustomData] = useState(null);
  useEffect(() => {
    if (inputFields) {
      if (Object.keys(inputFields)) {
        const newRequiredCheck = {};
        Object.keys(inputFields).map((item, index) => {
          if (inputFields[item].required) {
            console.log(item);
            newRequiredCheck[item] = Yup.string().required("Required");
          }
        });

        setRequiredCheck(newRequiredCheck);
      }
    }
  }, []);
  // console.log(requiredCheck);
  useEffect(() => {
    if (data) {
      if (inputFields) {
        if (Object.keys(inputFields)) {
          const newDataCheck = {};
          Object.keys(inputFields).map((item, index) => {
            if (
              inputFields[item] &&
              inputFields[item].type === "string" &&
              !inputFields[item].hide
            ) {
              // console.log(item);
              if (inputFields[item].inputType === "datetime-local") {
                newDataCheck[item] = moment(data[item])
                  .utc()
                  .format("yyyy-MM-DDThh:mm");
              } else {
                if (inputFields[item].inputType === "date") {
                  newDataCheck[item] = moment(data[item]).format("yyyy-MM-DD");
                } else {
                  newDataCheck[item] = data[item];
                }
              }
            }
            if (
              inputFields[item] &&
              inputFields[item].type === "text" &&
              !inputFields[item].hide
            ) {
              // console.log(item);
              newDataCheck[item] = data[item];
            }
            if (
              inputFields[item] &&
              inputFields[item].type === "checkbox" &&
              !inputFields[item].hide
            ) {
              // console.log(item);
              newDataCheck[item] = data[item];
            }
            if (
              inputFields[item] &&
              inputFields[item].type === "html" &&
              !inputFields[item].hide
            ) {
              // console.log(item);
              newDataCheck[item] = data[item];
            }
            if (
              inputFields[item] &&
              inputFields[item].type === "select" &&
              !inputFields[item].hide
            ) {
              // console.log(item);
              newDataCheck[item] = data[item];
            }
            if (
              inputFields[item] &&
              inputFields[item].type === "related" &&
              !inputFields[item].hide
            ) {
              // console.log(item);
              newDataCheck[item] = data[item]
                ? !inputFields[item].static
                  ? data[item]._id
                  : data[item]
                : "";
            }
            if (inputFields[item] && inputFields[item].type === "array") {
              newDataCheck[item] = data[item];
            }
          });
          setCustomData(newDataCheck);
        }
      }
    }
  }, [data]);
  // console.log("CUSTOM DATA", customData);
  return (
    <div>
      {edit ? (
        customData && (
          <div>
            <Formik
              initialValues={customData ? customData : initialValues}
              validationSchema={Yup.object(requiredCheck)}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                await submitForm(values);
                setSubmitting(false);
                resetForm(true);
              }}
            >
              {(formik) => {
                console.log(formik);
                return (
                  <Form autoComplete={"off"}>
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              {" "}
                              {PAGE_SINGLE_TITLE}{" "}
                            </h4>
                            <p className="card-title-desc">
                              Enter Details to add {PAGE_SINGLE_TITLE}
                            </p>
                          </div>
                          <div className="card-body">
                            <ClientFormComponent
                              formik={formik}
                              inputFields={inputFields}
                              setFeaturedImage={setFeaturedImage}
                              dropdown_options={dropdown_options}
                              setGallery={setGallery}
                              edit={edit}
                              loadOptions={loadOptions}
                            ></ClientFormComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )
      ) : (
        <div className="row">
          <Formik
            initialValues={customData ? customData : initialValues}
            validationSchema={Yup.object(requiredCheck)}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
              resetForm(true);
            }}
          >
            {(formik) => {
              // console.log(formik);
              return (
                <Form autoComplete={"off"}>
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title"> {PAGE_SINGLE_TITLE} </h4>
                          <p className="card-title-desc">
                            Enter Details to add {PAGE_SINGLE_TITLE}
                          </p>
                        </div>
                        <div className="card-body">
                          <ClientFormComponent
                            formik={formik}
                            inputFields={inputFields}
                            setFeaturedImage={setFeaturedImage}
                            dropdown_options={dropdown_options}
                            setGallery={setGallery}
                            edit={edit}
                            loadOptions={loadOptions}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="card">
                        <div className="card-header">
                          <div className="p-2 mt-2 mb-1 bg-light">
                            <p className="font-weight-bold">Data Checklist</p>
                          </div>
                        </div>
                        <div className="card-body  p-4">
                          <div className="row">
                            <div className="col-md-6">
                              <CheckBox
                                label="Contract"
                                name="contract_available"
                                type="checkbox"
                              >
                                Contract
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="FB Page Access"
                                name="fb_page_acces"
                                type="checkbox"
                              >
                                FB Page Access
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="GMB Access"
                                name="gmb_access"
                                type="checkbox"
                              >
                                GMB Access
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label=" Link Own Card For Marketing Budget"
                                name="link_own_card_for_marketing_budget"
                                type="checkbox"
                              >
                                Link Own Card For Marketing Budget?
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label=" fb ad account access"
                                name=" fb_ad_account_access"
                                type="checkbox"
                              >
                                [Self Spend]FB Ad Account Access
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Google Ad Account Access"
                                name="google_ad_account_access"
                                type="checkbox"
                              >
                                [Self Spend]Google Ad Account Access
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="developer access"
                                name="developer_access"
                                type="checkbox"
                              >
                                [Domain] Developer Access
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="domain credentials"
                                name="domain_credentials"
                                type="checkbox"
                              >
                                [Domain] Domain Credentials
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="luhaif to decide"
                                name="luhaif_to_decide"
                                type="checkbox"
                              >
                                [Domain] Luhaif To Decide{" "}
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label=" rate_card"
                                name=" rate_card"
                                type="checkbox"
                              >
                                Rate Card (Salons)
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="will use luhaif billing"
                                name="will_use_luhaif_billing"
                                type="checkbox"
                              >
                                [Attribution] Will Use Luhaif Billing
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="billing integration"
                                name="billing_integration"
                                type="checkbox"
                              >
                                [Attribution] Billing Integration
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="clininc template offer"
                                name="clininc_template_offer"
                                type="checkbox"
                              >
                                [Information] Clinic Template/Offer (Salons)
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Manual Visits"
                                name="manual_visits"
                                type="checkbox"
                              >
                                [Attribution] Manual Visits
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Logo From Website"
                                name="logo_from_website"
                                type="checkbox"
                              >
                                [LOGO] From Website{" "}
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="logo file shared"
                                name="logo_file_shared"
                                type="checkbox"
                              >
                                [LOGO] File Shared{" "}
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="no logo"
                                name="no_logo"
                                type="checkbox"
                              >
                                [LOGO] No Logo
                              </CheckBox>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header">
                          <div className="p-2 mt-2 mb-1 bg-light">
                            <p className="font-weight-bold">
                              Website Checklist
                            </p>
                          </div>
                        </div>
                        <div className="card-body  p-4">
                          <div className="row">
                            <div className="col-md-6">
                              <CheckBox
                                label=" Website"
                                name=" website"
                                type="checkbox"
                              >
                                Website
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Android App"
                                name="android_app"
                                type="checkbox"
                              >
                                Android App
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Ios App"
                                name="ios_app"
                                type="checkbox"
                              >
                                IOS App
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Landing Pages"
                                name="landing_pages"
                                type="checkbox"
                              >
                                Landing Pages
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Software"
                                name="software"
                                type="checkbox"
                              >
                                Software
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox label="Crm" name="crm" type="checkbox">
                                CRM
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Logo"
                                name="logo"
                                type="checkbox"
                              >
                                LOGO
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Website Content"
                                name="website_content"
                                type="checkbox"
                              >
                                Website Content
                              </CheckBox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="p-2 mt-2 mb-1 bg-light">
                            <p className="font-weight-bold">Ads Checklist</p>
                          </div>
                        </div>
                        <div className="card-body  p-4">
                          <div className="row">
                            <div className="col-md-6">
                              <CheckBox
                                label=" Fb Ads"
                                name=" fb_ads"
                                type="checkbox"
                              >
                                Facebook Ads
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Google Ads"
                                name="google_ads"
                                type="checkbox"
                              >
                                Google Ads
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Instagram Ads"
                                name="instagram_ads"
                                type="checkbox"
                              >
                                Instagram Ads
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Youtube Ads"
                                name="youtube_ads"
                                type="checkbox"
                              >
                                Youtube Ads
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Linkedin Ads"
                                name="linkedin_ads"
                                type="checkbox"
                              >
                                LinkedIn Ads
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Whatsapp Ads"
                                name="whatsapp_ads"
                                type="checkbox"
                              >
                                Whatsapp Ads
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Email Ads"
                                name="email_ads"
                                type="checkbox"
                              >
                                Email Ads
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label=" Native Ads"
                                name=" native_ads"
                                type="checkbox"
                              >
                                Native Ads
                              </CheckBox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="p-2 mt-2 mb-1 bg-light">
                            <p className="font-weight-bold">
                              Social Media Posting
                            </p>
                          </div>
                        </div>
                        <div className="card-body  p-4">
                          <div className="row">
                            <div className="col-md-6">
                              <CheckBox
                                label="Fb Posting"
                                name="fb_posting"
                                type="checkbox"
                              >
                                Facebook Posting
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Insta Posting"
                                name="insta_posting"
                                type="checkbox"
                              >
                                Instagram Posting
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Linkedin Posting"
                                name="linkedin_posting"
                                type="checkbox"
                              >
                                LinkedIn Posting
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Youtube Posting"
                                name="youtube_posting"
                                type="checkbox"
                              >
                                Youtube Posting
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Twitter Posting"
                                name="twitter_posting"
                                type="checkbox"
                              >
                                Twitter Posting
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Quora Posting"
                                name="quora_posting"
                                type="checkbox"
                              >
                                Quora Posting
                              </CheckBox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <div className="p-2 mt-2 mb-1 bg-light">
                            <p className="font-weight-bold">Video Production</p>
                          </div>
                        </div>
                        <div className="card-body  p-4">
                          <div className="row">
                            <div className="col-md-6">
                              <CheckBox
                                label="Photoshoot"
                                name="photoshoot"
                                type="checkbox"
                              >
                                Photo Shoot
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Video Shoot"
                                name="video_shoot"
                                type="checkbox"
                              >
                                Video Shoot
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Photo Edit"
                                name="photo_edit"
                                type="checkbox"
                              >
                                Photo Edit
                              </CheckBox>
                            </div>
                            <div className="col-md-6">
                              <CheckBox
                                label="Video Edit"
                                name="video_edit"
                                type="checkbox"
                              >
                                Video Edit
                              </CheckBox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default AddClientForm;
