import {
  GET_REMARKS_STATED,
  GET_REMARKS,
  GET_REMARKS_ENDED,
  ADD_REMARK_STATED,
  ADD_REMARK,
  ADD_REMARK_ENDED,
  EDIT_REMARK_STATED,
  EDIT_REMARK,
  EDIT_REMARK_ENDED,
  GET_REMARK_STATED,
  GET_REMARK,
  GET_REMARK_ENDED,
  GET_ALL_REMARKS_STATED,
  GET_ALL_REMARKS,
  GET_ALL_REMARKS_ENDED
} from "../types/remark_type";

const initialState = {
  remarks_loading: true,
  remarks: null,
  page: null,
  pages: null,
  total_remarks: 0,

  remark: null,
  remark_loading: null,

  loading: true,

  remark_message: null,
  all_remarks: null,
  all_remarks_loading: null,
  add_remark_loading: true,
  edit_remark_loading: true
};

export const remark_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_REMARKS_STATED:
      return {
        ...state,
        remarks: null,
        pages: null,
        page: null,
        total_remarks: 0,
        remarks_loading: true
      };
    case GET_REMARKS:
      return {
        ...state,
        remarks: payload.remarks,
        pages: payload.pages,
        page: payload.page,
        total_remarks: payload.count
      };
    case GET_REMARKS_ENDED:
      return {
        ...state,
        remarks_loading: false
      };
    case GET_ALL_REMARKS_STATED:
      return {
        ...state,
        all_remarks_loading: true,
        all_remarks: null
      };
    case GET_ALL_REMARKS:
      return {
        ...state,
        all_remarks: payload
      };
    case GET_ALL_REMARKS_ENDED:
      return {
        ...state,
        all_remarks_loading: false
      };

    case ADD_REMARK_STATED:
      return {
        ...state,
        remark_message: null,
        add_remark_loading: true
      };
    case ADD_REMARK:
      return {
        ...state,
        remark_message: payload
      };
    case ADD_REMARK_ENDED:
      return {
        ...state,
        add_remark_loading: false
      };
    case GET_REMARK_STATED:
      return {
        ...state,
        remark: null,
        remark_loading: true
      };
    case GET_REMARK:
      return {
        ...state,
        remark: payload
      };
    case GET_REMARK_ENDED:
      return {
        ...state,
        remark_loading: false
      };
    case EDIT_REMARK_STATED:
      return {
        ...state,
        remark_message: null,
        edit_remark_loading: true
      };
    case EDIT_REMARK:
      return {
        ...state,
        remark_message: payload
      };
    case EDIT_REMARK_ENDED:
      return {
        ...state,
        edit_remark_loading: false
      };

    default:
      return state;
  }
};
