import {
  GET_REVIEWSTATUSS_STATED,
  GET_REVIEWSTATUSS,
  GET_REVIEWSTATUSS_ENDED,
  ADD_REVIEWSTATUS_STATED,
  ADD_REVIEWSTATUS,
  ADD_REVIEWSTATUS_ENDED,
  EDIT_REVIEWSTATUS_STATED,
  EDIT_REVIEWSTATUS,
  EDIT_REVIEWSTATUS_ENDED,
  GET_REVIEWSTATUS_STATED,
  GET_REVIEWSTATUS,
  GET_REVIEWSTATUS_ENDED,
  GET_ALL_REVIEWSTATUSS_STATED,
  GET_ALL_REVIEWSTATUSS,
  GET_ALL_REVIEWSTATUSS_ENDED
} from "../types/reviewstatus_type";

const initialState = {
  reviewstatuss_loading: true,
  reviewstatuss: null,
  page: null,
  pages: null,
  total_reviewstatuss: 0,

  reviewstatus: null,
  reviewstatus_loading: null,

  loading: true,

  reviewstatus_message: null,
  all_reviewstatuss: null,
  all_reviewstatuss_loading: null,
  add_reviewstatus_loading: true,
  edit_reviewstatus_loading: true
};

export const reviewstatus_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_REVIEWSTATUSS_STATED:
      return {
        ...state,
        reviewstatuss: null,
        pages: null,
        page: null,
        total_reviewstatuss: 0,
        reviewstatuss_loading: true
      };
    case GET_REVIEWSTATUSS:
      return {
        ...state,
        reviewstatuss: payload.reviewstatuss,
        pages: payload.pages,
        page: payload.page,
        total_reviewstatuss: payload.count
      };
    case GET_REVIEWSTATUSS_ENDED:
      return {
        ...state,
        reviewstatuss_loading: false
      };
    case GET_ALL_REVIEWSTATUSS_STATED:
      return {
        ...state,
        all_reviewstatuss_loading: true,
        all_reviewstatuss: null
      };
    case GET_ALL_REVIEWSTATUSS:
      return {
        ...state,
        all_reviewstatuss: payload
      };
    case GET_ALL_REVIEWSTATUSS_ENDED:
      return {
        ...state,
        all_reviewstatuss_loading: false
      };

    case ADD_REVIEWSTATUS_STATED:
      return {
        ...state,
        reviewstatus_message: null,
        add_reviewstatus_loading: true
      };
    case ADD_REVIEWSTATUS:
      return {
        ...state,
        reviewstatus_message: payload
      };
    case ADD_REVIEWSTATUS_ENDED:
      return {
        ...state,
        add_reviewstatus_loading: false
      };
    case GET_REVIEWSTATUS_STATED:
      return {
        ...state,
        reviewstatus: null,
        reviewstatus_loading: true
      };
    case GET_REVIEWSTATUS:
      return {
        ...state,
        reviewstatus: payload
      };
    case GET_REVIEWSTATUS_ENDED:
      return {
        ...state,
        reviewstatus_loading: false
      };
    case EDIT_REVIEWSTATUS_STATED:
      return {
        ...state,
        reviewstatus_message: null,
        edit_reviewstatus_loading: true
      };
    case EDIT_REVIEWSTATUS:
      return {
        ...state,
        reviewstatus_message: payload
      };
    case EDIT_REVIEWSTATUS_ENDED:
      return {
        ...state,
        edit_reviewstatus_loading: false
      };

    default:
      return state;
  }
};
