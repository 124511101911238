export const PAGE_TITLE = "Employees";
export const PAGE_SINGLE_TITLE = "Employee";
export const LINK_URL = "employees";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "text",
    options: "",
    field: "",
  },
  designation: {
    type: "string",
    required: false,
    title: "Designation",
    inputType: "text",
    options: "",
    field: "",
  },
  employee_status: {
    type: "select",
    required: false,
    title: "Employee Status",
    options: ["Working", "Not Working"],
    field: "",
  },
  reporting_manager: {
    type: "related",
    required: false,
    title: "Reporting Manager",
    inputType: "text",
    field: "Reporting Manager",
  },
  phone: {
    type: "string",
    required: false,
    title: "Phone",
    inputType: "text",
    options: "",
    field: "",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
    inputType: "text",
    options: "",
    field: "",
  },
  username: {
    type: "string",
    required: true,
    title: "Username",
    inputType: "text",
    options: "",
    field: "",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
    options: "",
    field: "",
    hideOnView: true,
    // hideOnEdit: true,
  },
  role: {
    type: "select",
    required: true,
    title: "Role",
    options: ["USER", "SUPER ADMIN", "MANAGER", "ACCOUNTANT", "CRMUSER"],
    field: "",
  },
  dob: {
    type: "string",
    required: false,
    title: "DOB",
    inputType: "date",
    options: "",
    field: "",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
  },
  doj: {
    type: "string",
    required: false,
    title: "Date Of Joining",
    inputType: "date",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
  designation: "",
  employee_status: "",
  reporting_manager: "",
  phone: "",
  email: "",
  username: "",
  role: "",
  dob: "",
  doj: "",
  phone: "",
  password: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "Phone", value: "phone" },
  { name: "Username", value: "username" },
  { name: "Role", value: "role" },
  { name: "Date Of Birth", value: "dob" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "email",
    field: "email",
    label: "Email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "username",
    field: "username",
    label: "Username",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "dob",
    field: "dob",
    label: "Dob",
    type: "string",
    search_type: "search",
    inputType: "date",
    condition: "",
  },
  {
    id: "doj",
    field: "doj",
    label: "Doj",
    type: "string",
    search_type: "search",
    inputType: "date",
    condition: "",
  },
  {
    id: "phone",
    field: "phone",
    label: "Phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
