import {
  GET_SERVICEPACKAGES_STATED,
  GET_SERVICEPACKAGES,
  GET_SERVICEPACKAGES_ENDED,
  ADD_SERVICEPACKAGE_STATED,
  ADD_SERVICEPACKAGE,
  ADD_SERVICEPACKAGE_ENDED,
  EDIT_SERVICEPACKAGE_STATED,
  EDIT_SERVICEPACKAGE,
  EDIT_SERVICEPACKAGE_ENDED,
  GET_SERVICEPACKAGE_STATED,
  GET_SERVICEPACKAGE,
  GET_SERVICEPACKAGE_ENDED,
  GET_ALL_SERVICEPACKAGES_STATED,
  GET_ALL_SERVICEPACKAGES,
  GET_ALL_SERVICEPACKAGES_ENDED
} from "../types/servicepackage_type";

const initialState = {
  servicepackages_loading: true,
  servicepackages: null,
  page: null,
  pages: null,
  total_servicepackages: 0,

  servicepackage: null,
  servicepackage_loading: null,

  loading: true,

  servicepackage_message: null,
  all_servicepackages: null,
  all_servicepackages_loading: null,
  add_servicepackage_loading: true,
  edit_servicepackage_loading: true
};

export const servicepackage_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SERVICEPACKAGES_STATED:
      return {
        ...state,
        servicepackages: null,
        pages: null,
        page: null,
        total_servicepackages: 0,
        servicepackages_loading: true
      };
    case GET_SERVICEPACKAGES:
      return {
        ...state,
        servicepackages: payload.servicepackages,
        pages: payload.pages,
        page: payload.page,
        total_servicepackages: payload.count
      };
    case GET_SERVICEPACKAGES_ENDED:
      return {
        ...state,
        servicepackages_loading: false
      };
    case GET_ALL_SERVICEPACKAGES_STATED:
      return {
        ...state,
        all_servicepackages_loading: true,
        all_servicepackages: null
      };
    case GET_ALL_SERVICEPACKAGES:
      return {
        ...state,
        all_servicepackages: payload
      };
    case GET_ALL_SERVICEPACKAGES_ENDED:
      return {
        ...state,
        all_servicepackages_loading: false
      };

    case ADD_SERVICEPACKAGE_STATED:
      return {
        ...state,
        servicepackage_message: null,
        add_servicepackage_loading: true
      };
    case ADD_SERVICEPACKAGE:
      return {
        ...state,
        servicepackage_message: payload
      };
    case ADD_SERVICEPACKAGE_ENDED:
      return {
        ...state,
        add_servicepackage_loading: false
      };
    case GET_SERVICEPACKAGE_STATED:
      return {
        ...state,
        servicepackage: null,
        servicepackage_loading: true
      };
    case GET_SERVICEPACKAGE:
      return {
        ...state,
        servicepackage: payload
      };
    case GET_SERVICEPACKAGE_ENDED:
      return {
        ...state,
        servicepackage_loading: false
      };
    case EDIT_SERVICEPACKAGE_STATED:
      return {
        ...state,
        servicepackage_message: null,
        edit_servicepackage_loading: true
      };
    case EDIT_SERVICEPACKAGE:
      return {
        ...state,
        servicepackage_message: payload
      };
    case EDIT_SERVICEPACKAGE_ENDED:
      return {
        ...state,
        edit_servicepackage_loading: false
      };

    default:
      return state;
  }
};
