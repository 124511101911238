import {
  GET_EMPLOYEES_STATED,
  GET_EMPLOYEES,
  GET_EMPLOYEES_ENDED,
  ADD_EMPLOYEE_STATED,
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_ENDED,
  EDIT_EMPLOYEE_STATED,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_ENDED,
  GET_EMPLOYEE_STATED,
  GET_EMPLOYEE,
  GET_EMPLOYEE_ENDED,
  GET_ALL_EMPLOYEES_STATED,
  GET_ALL_EMPLOYEES,
  GET_ALL_EMPLOYEES_ENDED
} from "../types/employee_type";

const initialState = {
  employees_loading: true,
  employees: null,
  page: null,
  pages: null,
  total_employees: 0,

  employee: null,
  employee_loading: null,

  loading: true,

  employee_message: null,
  all_employees: null,
  all_employees_loading: null,
  add_employee_loading: true,
  edit_employee_loading: true
};

export const employee_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EMPLOYEES_STATED:
      return {
        ...state,
        employees: null,
        pages: null,
        page: null,
        total_employees: 0,
        employees_loading: true
      };
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: payload.employees,
        pages: payload.pages,
        page: payload.page,
        total_employees: payload.count
      };
    case GET_EMPLOYEES_ENDED:
      return {
        ...state,
        employees_loading: false
      };
    case GET_ALL_EMPLOYEES_STATED:
      return {
        ...state,
        all_employees_loading: true,
        all_employees: null
      };
    case GET_ALL_EMPLOYEES:
      return {
        ...state,
        all_employees: payload
      };
    case GET_ALL_EMPLOYEES_ENDED:
      return {
        ...state,
        all_employees_loading: false
      };

    case ADD_EMPLOYEE_STATED:
      return {
        ...state,
        employee_message: null,
        add_employee_loading: true
      };
    case ADD_EMPLOYEE:
      return {
        ...state,
        employee_message: payload
      };
    case ADD_EMPLOYEE_ENDED:
      return {
        ...state,
        add_employee_loading: false
      };
    case GET_EMPLOYEE_STATED:
      return {
        ...state,
        employee: null,
        employee_loading: true
      };
    case GET_EMPLOYEE:
      return {
        ...state,
        employee: payload
      };
    case GET_EMPLOYEE_ENDED:
      return {
        ...state,
        employee_loading: false
      };
    case EDIT_EMPLOYEE_STATED:
      return {
        ...state,
        employee_message: null,
        edit_employee_loading: true
      };
    case EDIT_EMPLOYEE:
      return {
        ...state,
        employee_message: payload
      };
    case EDIT_EMPLOYEE_ENDED:
      return {
        ...state,
        edit_employee_loading: false
      };

    default:
      return state;
  }
};
