import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import { useHistory } from "react-router-dom";
import AdsreportForm from "../../components/adsreports/AdsreportForm";
import { convertToFormData } from "../../shared/upload";
import {
  inputFields_facebook,
  initialValues_facebook,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from "../../shared/enums/facebook_ad_reports_enum";
import {
  useUploadFacebookAdsreport,
  useGetDropdownOptions,
} from "../../shared/hooks/UseAdsreport";
import AddForm from "../../components/common/AddForm";
// import { useSelectAllAdsreport } from "../../shared/hooks/UseAdsreport";

const UploadFacebookReport = ({ }) => {
  let history = useHistory();
  const [adsreport, addData] = useUploadFacebookAdsreport();
  const { add_adsreport_loading } = adsreport;
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.push(`/adsreports-facebook`);
  };

  const [dropdownOptions, loadOptions] = useGetDropdownOptions();

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={`Upload Facebook Report`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={""}
          activeLink="Add"
        />
      </div>
      <div className="container-fluid">
        <div className="col-lg-9">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title"> Upload Facebook Report </h4>
              <p className="card-title-desc">
                Enter Details to add Facebook Report
              </p>
            </div>
            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields_facebook}
              initialValues={initialValues_facebook}
              dropdown_options={dropdownOptions}
              loading={add_adsreport_loading}
              loadOptions={loadOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFacebookReport;
