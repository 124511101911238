import React, { useEffect, useState } from 'react';
import Header from '../../components/template/Header';
import BreadCrumb from '../../components/template/BreadCrumb';
import FilterDateComponent from '../../components/common/FilterDateComponent';
import {
  useAllAdsReportsDashboard,
  useAllFacebookAdsreportsDashboard,
} from '../../shared/hooks/UseAdsreport';
import moment from 'moment';
import { useSelectAllAdsClient } from '../../shared/hooks/UseClient';
import { AdsReportFBComponent } from '../../components/adsreports/AdsReportFBComponent';
import { UseSearchFilter } from '../../shared/hooks/UseFilter';
import { ReviewComponent } from '../../components/ads/ReviewComponent';
import { useGetDropdownOptions } from '../../shared/hooks/UseActivity';

export const AdsDashboard = () => {
  const { google_ads_reports, setPageNumber, pageNumber } =
    useAllAdsReportsDashboard();

  const {
    adsreports_loading,
    adsreports,
    total_adsreports,
    total_clicks,
    total_conversions,
    total_cost,
    fb_total_conversions,
    fb_total_cost,
  } = google_ads_reports;
  const { clients_data } = useSelectAllAdsClient();
  // console.log('clients_data', clients_data);
  const { ads_clients } = clients_data;
  const { handleSidebarChange, getSidebarValue } = UseSearchFilter();
  const [dropdownOptions] = useGetDropdownOptions();
  const [client, setClient] = useState(null);
  const [in_benchmark, setInBenchmark] = useState(null);
  useEffect(() => {
    const searchClient = getSidebarValue({
      search_type: 'exact',
      field: 'client',
    });
    const benchMark = getSidebarValue({
      search_type: 'exact',
      field: 'in_benchmark',
    });
    if (searchClient) {
      setClient(searchClient);
    } else {
      setClient('');
    }
    if (benchMark) {
      setInBenchmark(benchMark == 'true' ? true : false);
    } else {
      setInBenchmark('');
    }
  }, [window.location.search]);
  console.log('Dropdown OPtions', dropdownOptions);
  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title='Ads Dashboard'
          mainLinkTitle='Ads Dashboard'
          mainLinkUrl='/ads-dashboard'
          activeLink='Main'
        />
        <FilterDateComponent link='/ads-dashboard' />
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='card'>
                <div className='card-body'>
                  {/* Client Filter  */}
                  <label htmlFor='client'>Client</label>
                  <select
                    name='client'
                    className='form-control'
                    value={client}
                    onChange={(e) =>
                      handleSidebarChange({
                        name: 'client',
                        value: e.target.value,
                        type: 'exact',
                      })
                    }
                  >
                    <option value=''> Select </option>
                    {dropdownOptions &&
                      dropdownOptions.clients &&
                      dropdownOptions.clients.map((client) => {
                        return (
                          <option value={client.value} key={client._id}>
                            {client.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card'>
                <div className='card-body'>
                  <div>
                    {/* Benchmark */}
                    <label htmlFor='Benchmark'> Benchmark </label>
                    <select
                      name='benchmark'
                      className='form-control'
                      value={in_benchmark}
                      onChange={(e) =>
                        handleSidebarChange({
                          name: 'in_benchmark',
                          value: e.target.value,
                          type: 'exact',
                        })
                      }
                    >
                      <option value=''> Select </option>
                      <option value={true}> In Benchmark </option>
                      <option value={false}> Out of Benchmark </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Accounts to review */}
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-9'>
              <div className='card'>
                <div className='card-header'>
                  <div className='card-title'>
                    <div className='d-flex justify-content-between'>
                      <div>Google Ads</div>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <table className='table table-bordered table-sm'>
                    <thead>
                      <tr>
                        <th>Sr. No </th>
                        <th>Date</th>
                        <th>Client Name</th>
                        <th> Campaign Name </th>
                        <th> Conversions </th>
                        <th> Phone Calls </th>
                        <th> Total Cost </th>
                        <th> CPC </th>
                        <th> Benchmark </th>
                        <th> In Benchmark </th>
                      </tr>
                    </thead>
                    <tbody>
                      {adsreports &&
                        adsreports.map((report, index) => {
                          return (
                            <tr key={report._id}>
                              <td> {index + 1} </td>
                              <td>
                                {report?.report_date
                                  ? moment(report.report_date).format(
                                      'DD-MM-YYYY'
                                    )
                                  : 'NA'}
                              </td>
                              <td> {report?.client?.name} </td>
                              <td> {report?.campaign?.task_name} </td>
                              <td> {report?.conversions} </td>
                              <td> {report?.phone_calls} </td>
                              <td> {report?.total_cost} </td>
                              <td> {report?.cost_per_conversion} </td>
                              <td> {report?.benchmark} </td>

                              <td>
                                {report.in_benchmark ? (
                                  <span class='c-pill c-pill--success'>
                                    YES
                                  </span>
                                ) : (
                                  <span class='c-pill c-pill--danger'>NO</span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <AdsReportFBComponent ads_clients={ads_clients} />
            </div>
            <div className='col-md-3'>
              <div className='card'>
                <div className='card-body'>
                  <div className='card-title'> Client Stats Google</div>
                  <div className='row justify-content-center'>
                    {/* <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'>Total Clicks </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'>{total_clicks}</div>
                        </a>
                      </div>
                    </div> */}
                    <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'> Conversion </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'>{total_conversions}</div>
                        </a>
                      </div>
                    </div>
                    {/* <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'>Avg CPC </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'>0</div>
                        </a>
                      </div>
                    </div> */}
                    <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'> Spent </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'> {total_cost} </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='card-title'> Client Stats Facebook</div>
                  <div className='row justify-content-center'>
                    {/* <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'>Total Clicks </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'>{total_clicks}</div>
                        </a>
                      </div>
                    </div> */}
                    <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'> Conversion </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'>{fb_total_conversions}</div>
                        </a>
                      </div>
                    </div>
                    {/* <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'>Avg CPC </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'>0</div>
                        </a>
                      </div>
                    </div> */}
                    <div className='col-md-6'>
                      <div class='dashboard-stat pending'>
                        <a href='/clients'>
                          <div class='report-title'> Spent </div>
                        </a>
                        <a href='/clients'>
                          <div class='report-stat'> {fb_total_cost} </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {client && (
                <ReviewComponent
                  client={client}
                  dropdownOptions={dropdownOptions}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
