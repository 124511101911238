export const GET_REMARKS_STATED = "GET_REMARKS_STATED";
export const GET_REMARKS = "GET_REMARKS";
export const GET_REMARKS_ENDED = "GET_REMARKS_ENDED";
export const ADD_REMARK_STATED = "ADD_REMARK_STARTED";
export const ADD_REMARK = "ADD_REMARK";
export const ADD_REMARK_ENDED = "ADD_REMARK_ENDED";
export const EDIT_REMARK_STATED = "EDIT_REMARK_STATED";
export const EDIT_REMARK = "EDIT_REMARK";
export const EDIT_REMARK_ENDED = "EDIT_REMARK_ENDED";
export const GET_REMARK = "GET_REMARK";
export const GET_REMARK_STATED = "GET_REMARK_STATED";
export const GET_REMARK_ENDED = "GET_REMARK_ENDED";
export const RESET_REMARK = "RESET_REMARK";
export const ERROR_REMARK = "ERROR_REMARK";
export const GET_ALL_REMARKS_STATED = "GET_ALL_REMARKS_STATED";
export const GET_ALL_REMARKS = "GET_ALL_REMARKS";
export const GET_ALL_REMARKS_ENDED = "GET_ALL_REMARKS_ENDED";
