import React, { useState } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Pagination from "../../components/common/Pagination";
import AddBtn from "../../components/common/AddBtn";
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from "../../shared/enums/bills_enum";
import DataTable from "../../components/common/DataTable";
import {
  useAllBills,
  useGetDropdownOptions,
  useUpdateBill,
} from "../../shared/hooks/UseBill";
import SidebarFilter from "../../components/common/SidebarFilter";
import Spinner from "../../components/layout/Spinner";
import moment from "moment";
import ReactModal from "react-modal";
import SingleView from "../../components/common/SingleView";
import { useEffect } from "react";
import FilterDateComponent from "../../components/common/FilterDateComponent";
import { UseDataForExcel, UseFilter } from "../../shared/hooks/UseExcel";
import ExportComponent from "../../components/common/ExportComponent";
import { Link } from "react-router-dom";



const AllBills = ({ }) => {
  const [data, setPageNumber, deleteBtnClicked, reloadData, setReloadData] =
    useAllBills();
  const [updateData] = useUpdateBill();
  const { bills_loading, bills, total_bills, page, pages } = data;
  const [totalAmount, setTotalAmount] = useState(0);
  const [
    dropdownOptions,
    // setClientSearchField, setClientSearchValue
  ] = useGetDropdownOptions();
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "client") {
    //   await setClientSearchField("name");
    //   await setClientSearchValue(inputValue);
    //   callback(dropdownOptions.client);
    // }
  };

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [editField, setEditField] = useState(null);

  const [newStatus, setNewStatus] = useState(null);
  const [newValues, setNewValues] = useState(null);
  const [loadingActivityStatus, setLoadingActivityStatus] = useState(false);

  const updateStatus = async (id, status) => {
    // console.log("ID", id, value);
    if (id && status) {
      setLoadingActivityStatus(true);
      const newForm = new FormData();
      newForm.append("values", JSON.stringify(status));
      await updateData(id, newForm);
      await setReloadData(true);
      setEditId(null);
      setLoadingActivityStatus(false);
    } else {
      setEditId(null);
    }
  };
  useEffect(() => {
    if (dropdownOptions) {
      const statusArray = ["PAID", "NOT PAID", "CANCELLED"];
      const newStatusArray = statusArray.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      dropdownOptions.status = newStatusArray;
    }
  }, [dropdownOptions]);
  useEffect(() => {
    if (bills) {
      let totalSum = 0;
      const sum = bills.reduce((accumulator, object) => {
        return accumulator + Number(object.amount);
      }, 0);
      // console.log("BILL", sum, bills);
      setTotalAmount(sum);
    }
  }, [bills]);


  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcel();

  useEffect(() => {
    if (bills) {
      convertToReadable(bills, inputFields);
    }
  }, [bills]);

  const handleOnExport = () => {
    exportXLSXData(exportData, "Bills", "bills");
  };

  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink={PAGE_TITLE}
        />
        <ExportComponent handleOnExport={handleOnExport} />
        <FilterDateComponent link="/bills" />

        <div className="container-fluid">
          <div className="row">
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
                col={2}
              />
            )}
            <div className="col-lg-10">
              <div
                className="card"
                style={{ boxShadow: "rgb(227 233 243) 0px 4px 22px" }}
              >
                <div className="card-body">
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  <div style={{ paddingBottom: "10px" }}>
                    <Link className="btn btn-danger" to={`/bills?conditional[billing_date][$lt]=${moment().utc().format("YYYY-MM-DD")}&exact[status]=NOT PAID`} >
                      Overdue Bills
                    </Link>
                  </div>
                  {total_bills} records found
                  <div>
                    <h3 style={{ textAlign: "right" }}>Total: {totalAmount}</h3>
                  </div>
                  <div>
                    <table className="table table-new align-middle table-striped  table-bordered">
                      <thead>
                        <tr className="bg-transparent">
                          <th style={{ width: "50px" }}>#</th>
                          <th> Client </th>
                          <th> Billing Date </th>
                          <th> Collection Date </th>
                          <th> Amount </th>
                          <th> Status </th>
                          <th> Review </th>
                          <th> Package </th>
                          <th> Collected By </th>
                          <th> Bank Account </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!bills_loading ? (
                          bills &&
                          bills.map((single, index) => {
                            if (editId && editId == single._id) {
                              return (
                                <tr>
                                  <td> {index + 1} </td>
                                  <td>
                                    {" "}
                                    {single.client && single.client.name}{" "}

                                    {editField == "client" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  client:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.client &&
                                                dropdownOptions.client.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.client &&
                                                          single.client
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.activity_status &&
                                      single.activity_status.name
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {single.billing_date &&
                                      moment(single.billing_date).utc()
                                        .format("DD-MM-YYYY hh:mm A")}{" "}


                                    {editField == "billing_date" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input className="form-control"
                                              value={newValues && newValues.billing_date}
                                              type="datetime-local"
                                              onChange={(e) =>
                                                setNewValues({
                                                  billing_date:
                                                    e.target.value,

                                                })
                                              } />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.billing_date
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {single.collection_date &&
                                      moment(single.collection_date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}

                                    {editField == "collection_date" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input className="form-control"
                                              value={newValues && newValues.collection_date}
                                              type="datetime-local"
                                              onChange={(e) =>
                                                setNewValues({
                                                  collection_date:
                                                    e.target.value,

                                                })
                                              } />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.collection_date
                                    )}
                                  </td>
                                  <td>{single.amount}

                                    {editField == "amount" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <input className="form-control"
                                              value={newValues && newValues.amount}
                                              onChange={(e) =>
                                                setNewValues({
                                                  amount:
                                                    e.target.value,
                                                })
                                              } />

                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.amount
                                    )}
                                  </td>
                                  <td>
                                    {editField == "status" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  status: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.status &&
                                                dropdownOptions.status.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.status &&
                                                          single.status ==
                                                          item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.status && single.status
                                    )}
                                  </td>
                                  <td>
                                    {editField == "review_status" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  review_status: e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.review_status &&
                                                dropdownOptions.review_status.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.review_status &&
                                                          single.review_status
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.review_status &&
                                      single.review_status.name
                                    )}
                                  </td>

                                  <td>
                                    {single.package && single.package.name}

                                    {editField == "package" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  package:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.package &&
                                                dropdownOptions.package.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.package &&
                                                          single.package
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.package &&
                                      single.package.name
                                    )}
                                  </td>
                                  <td>
                                    {single.collected_by &&
                                      single.collected_by.name}

                                    {editField == "collected_by" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  collected_by:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.collected_by &&
                                                dropdownOptions.collected_by.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.collected_by &&
                                                          single.collected_by
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.collected_by &&
                                      single.collected_by.name
                                    )}
                                  </td>
                                  <td>
                                    {single.bank_account &&
                                      single.bank_account.name}

                                    {editField == "bank_account" ? (
                                      <>
                                        {loadingActivityStatus && reloadData ? (
                                          "SAVING..."
                                        ) : (
                                          <>
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                setNewValues({
                                                  bank_account:
                                                    e.target.value,
                                                })
                                              }
                                            >
                                              {dropdownOptions &&
                                                dropdownOptions.bank_account &&
                                                dropdownOptions.bank_account.map(
                                                  (item) => {
                                                    // console.log(item);
                                                    return (
                                                      <option
                                                        value={item.value}
                                                        selected={
                                                          single.bank_account &&
                                                          single.bank_account
                                                            ._id == item.value
                                                        }
                                                      >
                                                        {item.label}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                            </select>
                                            <a
                                              className="btn btn-sm btn-success  m-1"
                                              onClick={() =>
                                                updateStatus(
                                                  single._id,
                                                  newValues
                                                )
                                              }
                                            >
                                              <i className="fa fa-save"></i>
                                            </a>
                                            <a
                                              className="btn btn-sm btn-light"
                                              onClick={() => setEditId(null)}
                                            >
                                              X
                                            </a>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      single.bank_account &&
                                      single.bank_account.name
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars"></i>{" "}
                                    </button>

                                    <button
                                      class="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  <td> {index + 1} </td>
                                  <td>
                                    {" "}

                                    {single.client && single.client.name}
                                    <a
                                      onClick={() => {
                                        setEditField("client");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>

                                    {single.billing_date &&
                                      moment(single.billing_date)
                                        .utc()
                                        .format("DD-MM-YYYY hh:mm A")}

                                    {single.status && single.status !== "PAID" && single.status !== "CANCELLED" && moment().diff(single.billing_date) > 0 ? (
                                      <span class="badge bg-danger p-1">
                                        Over Due Bill
                                      </span>
                                    ) : ("")}


                                    <a
                                      onClick={() => {
                                        setEditField("billing_date");
                                        setEditId(single._id);
                                        setNewValues({ billing_date: single.billing_date })
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {" "}
                                    {single.collection_date &&
                                      moment(single.collection_date).format(
                                        "DD-MM-YYYY"
                                      )}{" "}

                                    <a
                                      onClick={() => {
                                        setEditField("collection_date");
                                        setEditId(single._id);
                                        setNewValues({ collection_date: single.collection_date })
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>


                                  </td>
                                  <td>{single.amount}

                                    <a
                                      onClick={() => {
                                        setEditField("amount");
                                        setEditId(single._id);
                                        setNewValues({ amount: single.amount })
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.status}
                                    <a
                                      onClick={() => {
                                        setEditField("status");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.review_status &&
                                      single.review_status.name}
                                    <a
                                      onClick={() => {
                                        setEditField("review_status");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.package && single.package.name}

                                    <a
                                      onClick={() => {
                                        setEditField("package");
                                        setEditId(single._id);
                                        setNewValues({ package: single.package })
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.collected_by &&
                                      single.collected_by.name}

                                    <a
                                      onClick={() => {
                                        setEditField("collected_by");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    {single.bank_account &&
                                      single.bank_account.name}

                                    <a
                                      onClick={() => {
                                        setEditField("bank_account");
                                        setEditId(single._id);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-edit"></i>{" "}
                                    </a>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-soft-light"
                                      onClick={() => {
                                        setModal(true);
                                        setModalData(single);
                                      }}
                                    >
                                      {" "}
                                      <i className="fa fa-binoculars"></i>{" "}
                                    </button>

                                    <button
                                      class="btn text-danger"
                                      onClick={() =>
                                        deleteBtnClicked(single._id)
                                      }
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={20} className="text-center">
                              <Spinner />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {/* <DataTable
                      keys={view_all_table}
                      data={bills}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={bills_loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    /> */}

                    <Pagination
                      data={bills}
                      page={page}
                      pages={pages}
                      count={total_bills}
                      setPage={setPageNumber}
                      loading={bills_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modal}
        contentLabel="Modal"
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={() => {
          setModal(false);
        }}
      >
        <div className="quick-view">
          <div className="qv-header">
            <div className="title"> {PAGE_SINGLE_TITLE} </div>
            <div>
              <button
                onClick={() => {
                  setModal(false);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
          {modalData && (
            <div className="qv-body">
              <SingleView
                data={modalData}
                inputFields={inputFields}
                label={PAGE_SINGLE_TITLE}
                link={LINK_URL}
                id={modalData._id}
                hideAllBtn={true}
                col={12}
              />
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AllBills;
