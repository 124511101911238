import {
  GET_PROCESSS_STATED,
  GET_PROCESSS,
  GET_PROCESSS_ENDED,
  ADD_PROCESS_STATED,
  ADD_PROCESS,
  ADD_PROCESS_ENDED,
  EDIT_PROCESS_STATED,
  EDIT_PROCESS,
  EDIT_PROCESS_ENDED,
  GET_PROCESS_STATED,
  GET_PROCESS,
  GET_PROCESS_ENDED,
  GET_ALL_PROCESSS_STATED,
  GET_ALL_PROCESSS,
  GET_ALL_PROCESSS_ENDED
} from "../types/process_type";

const initialState = {
  processs_loading: true,
  processs: null,
  page: null,
  pages: null,
  total_processs: 0,

  process: null,
  process_loading: null,

  loading: true,

  process_message: null,
  all_processs: null,
  all_processs_loading: null,
  add_process_loading: true,
  edit_process_loading: true
};

export const process_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROCESSS_STATED:
      return {
        ...state,
        processs: null,
        pages: null,
        page: null,
        total_processs: 0,
        processs_loading: true
      };
    case GET_PROCESSS:
      return {
        ...state,
        processs: payload.processs,
        pages: payload.pages,
        page: payload.page,
        total_processs: payload.count
      };
    case GET_PROCESSS_ENDED:
      return {
        ...state,
        processs_loading: false
      };
    case GET_ALL_PROCESSS_STATED:
      return {
        ...state,
        all_processs_loading: true,
        all_processs: null
      };
    case GET_ALL_PROCESSS:
      return {
        ...state,
        all_processs: payload
      };
    case GET_ALL_PROCESSS_ENDED:
      return {
        ...state,
        all_processs_loading: false
      };

    case ADD_PROCESS_STATED:
      return {
        ...state,
        process_message: null,
        add_process_loading: true
      };
    case ADD_PROCESS:
      return {
        ...state,
        process_message: payload
      };
    case ADD_PROCESS_ENDED:
      return {
        ...state,
        add_process_loading: false
      };
    case GET_PROCESS_STATED:
      return {
        ...state,
        process: null,
        process_loading: true
      };
    case GET_PROCESS:
      return {
        ...state,
        process: payload
      };
    case GET_PROCESS_ENDED:
      return {
        ...state,
        process_loading: false
      };
    case EDIT_PROCESS_STATED:
      return {
        ...state,
        process_message: null,
        edit_process_loading: true
      };
    case EDIT_PROCESS:
      return {
        ...state,
        process_message: payload
      };
    case EDIT_PROCESS_ENDED:
      return {
        ...state,
        edit_process_loading: false
      };

    default:
      return state;
  }
};
