import React from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import Pagination from '../../components/common/Pagination';
import AddBtn from '../../components/common/AddBtn';
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from '../../shared/enums/facebook_ad_reports_enum';
import DataTable from '../../components/common/DataTable';
import {
  useAllFacebookAdsreports,
  useGetDropdownOptions,
} from '../../shared/hooks/UseAdsreport';
import SidebarFilter from '../../components/common/SidebarFilter';
import { Link } from 'react-router-dom';
import FilterDateComponent from '../../components/common/FilterDateComponent';

const AllAdsreports = ({}) => {
  const [data, setPageNumber, deleteBtnClicked] = useAllFacebookAdsreports();
  const {
    fb_adsreports_loading,
    fb_adsreports,
    fb_total_adsreports,
    fb_page,
    fb_pages,
  } = data;
  const [dropdownOptions, loadOptions] = useGetDropdownOptions();

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle='Dashboard'
          mainLinkUrl='/dashboard'
          activeLink={PAGE_TITLE}
        />
        <FilterDateComponent
          link='/adsreports-facebook'
          // showOwn={true}
          // searchParams={{ term: "assigned_to", value: user._id }}
        />

        <div className='container-fluid'>
          <div className='row'>
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
                col={2}
              />
            )}
            <div className='col-lg-10'>
              <div
                className='card'
                style={{ boxShadow: 'rgb(227 233 243) 0px 4px 22px' }}
              >
                <div className='card-body'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      {/*   <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} /> */}{' '}
                      <h4> Facebook Ads Report</h4>
                    </div>
                    <div>
                      <Link
                        className='btn btn-danger m-1 '
                        to={'/adsreports/upload-google-report'}
                      >
                        {' '}
                        <i className='fa fa-upload'></i>
                        Upload Google Report{' '}
                      </Link>
                      <Link
                        to={'/adsreports/upload-facebook-report'}
                        className='btn btn-default'
                        style={{ backgroundColor: '#4267B2', color: '#fff' }}
                      >
                        <i className='fa fa-upload'></i>
                        Upload Facebook Report{' '}
                      </Link>
                    </div>
                  </div>
                  {fb_total_adsreports} records found
                  <div>
                    <DataTable
                      keys={view_all_table}
                      data={fb_adsreports}
                      field={LINK_URL}
                      page={fb_page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={fb_adsreports_loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                      style={{ width: '100%', tableLayout: 'fixed' }}
                    />

                    <Pagination
                      data={fb_adsreports}
                      page={fb_page}
                      pages={fb_pages}
                      count={fb_total_adsreports}
                      setPage={setPageNumber}
                      loading={fb_adsreports_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAdsreports;
