import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _debounce from 'lodash/debounce';

import {
  addAdsReview,
  getAllReviews,
  updateAdsReview,
} from '../../store/actions/ads_review_action';

export const useAllAdsReviews = (client) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ads_review);
  useEffect(() => {
    dispatch(getAllReviews(client));
  }, []);
  return {
    ads_reviews_data: data,
  };
};

export const useAddAdReview = () => {
  const dispatch = useDispatch();
  const addAdReviewHandler = (formData) => {
    dispatch(addAdsReview(formData));
  };
  return { addAdReviewHandler };
};

export const useUpdateAdReview = () => {
  const dispatch = useDispatch();

  const updateAdsReviewHandler = (review_id, formData) => {
    dispatch(updateAdsReview(review_id, formData));
  };
  return {
    updateAdsReviewHandler,
  };
};
